import { SNACK_SUCCESS, SNACK_ERROR, SNACK_CLOSE } from '../actions/FeedbackActions.jsx';

const initialState = {
  snack: {
    open: false,
    severity: 'error',
    context: '',
  },
  button: {
    status: 'disabled',
    style: 'contained',
    text: 'JIMBOB',
  },
};

export function feedback(state = initialState, action) {
  switch (action.type) {
    case SNACK_SUCCESS:
      return {
        ...state,
        snack: {
          open: true,
          severity: 'success',
          context: action.data,
        },
      };

    case SNACK_ERROR:
      return {
        ...state,
        snack: {
          open: true,
          severity: 'error',
          context: action.data,
        },
      };
    case SNACK_CLOSE:
      return {
        ...state,
        snack: {
          ...state.snack,
          open: false,
        },
      };
    default:
      return state;
  }
}
