import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

const ResetDone = (props) => {
  const history = useHistory();

  return (
    <>
      <h6 style={{ marginTop: '16px' }}>Choose new password</h6>
      <p>Your password has been reset! Please continue to the login page.</p>
      <Button
        variant='contained'
        color='secondary'
        fullWidth
        style={{ margin: '16px auto' }}
        onClick={() => history.push(`/${props.projectId}/login`)}
      >
        Continue
      </Button>
    </>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(ResetDone);
