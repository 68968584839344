import React from 'react';
import SelectComponent from '/components/forms-v2/Select.component';

const StudyProgramSelect = ({ control, studyInfo, watchStudyType }) => {
  return (
    <SelectComponent
      control={control}
      name='studyProgram'
      label='Study Programme'
      rules={{
        required: { value: true, message: 'Enter Study Program' },
        validate: {
          programMatchType: (v) =>
            validateProgramAndType(v, studyInfo, studyInfo.types[watchStudyType - 1]) ? true : 'Enter Study Program',
        },
      }}
      options={
        studyInfo
          ? studyInfo
              .filter((studyProgram) => studyProgram.typeEn === studyInfo.types[watchStudyType - 1])
              .map((studyProgram) => ({
                value: studyProgram.studyProgramId,
                label: `${studyProgram.nameEn} ${
                  studyProgram.abbreviation !== '' ? `(${studyProgram.abbreviation})` : ''
                }`,
              }))
          : []
      }
      disabled={!watchStudyType ? true : false}
    />
  );
};

/**
 * This exists to check when user presses submit that the study info and type match
 */
const validateProgramAndType = (studyProgramId, studyInfo, studyType) => {
  const chosenProgram = studyInfo.find((studyProgram) => studyProgram.studyProgramId === studyProgramId);
  if (chosenProgram.typeEn === studyType) return true;
  return false;
};

export default StudyProgramSelect;
