import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
  },
  title: {
    fontWeight: 400,
  },
  button: {
    padding: '4px',
  }
}));

/**
 * Header for event card. Displays title and the 'more' or 'less' icon button
 * 
 * @param {boolean} expanded state of expanded button
 * @param {string} title event title should go here
 * @param {function} onClick when expand button is clicked
 * @returns {JSX} Header for Event Card.
 */
export const EventHeader = ({expanded = false, title = '', onClick = () => null}) => {

  const classes = useStyles();

  return(
    <div className={classes.headerContainer}>
      <Typography className={classes.title} variant='h6' noWrap>
        {title}
      </Typography>
      <IconButton color='secondary' className={classes.button} onClick={onClick}>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
      </IconButton>
    </div>
  );
}