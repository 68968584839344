import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import PeopleIcon from '@material-ui/icons/People';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PlaceIcon from '@material-ui/icons/Place';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const useStyles = makeStyles((t) => ({
  tableBase: {
    width: '100%',
    tableLayout: 'fixed',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '14px',
    height: '14px',
    marginRight: '4px',
  },
}));

const EventData = ({ icon, text }) => {
  const classes = useStyles();

  const renderIcon = () => {
    switch (icon) {
      case 'PEOPLE':
        return <PeopleIcon className={classes.icon} />;
      case 'PRICE':
        return <CreditCardIcon className={classes.icon} />;
      case 'SCHEDULE':
        return <ScheduleIcon className={classes.icon} />;
      case 'PLACE':
        return <PlaceIcon className={classes.icon} />;
    }
  };

  return (
    <div className={classes.iconContainer}>
      {renderIcon()}
      <Typography noWrap variant='caption' component={'span'}>
        {text}
      </Typography>
    </div>
  );
};


export const EventStatistics = ({
  isMobile = false,
  location = '',
  attendanceLimit = 0,
  startTime = new Date(),
  endTime = new Date(),
  price = 0,
}) => {

  const classes = useStyles();

  const time =
    format(utcToZonedTime(new Date(startTime), 'Europe/Stockholm'), 'HH:mm') +
    ' - ' +
    format(utcToZonedTime(new Date(endTime), 'Europe/Stockholm'), 'HH:mm');

  return(
    <table className={classes.tableBase}>
      <tbody>
        <tr>
          {!isMobile ? (
            <>
              <td>
                <EventData icon='PLACE' text={location} />
              </td>
              <td>
                <EventData icon='PEOPLE' text={attendanceLimit > 0 ? `${attendanceLimit} people` : 'No limit'} />
              </td>
              <td>
                <EventData icon='SCHEDULE' text={time} />
              </td>
              <td>
                <EventData icon='PRICE' text={`${price} SEK`} />
              </td>
            </>
          ):(
            <td style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{flex: 1}}>
                <EventData icon='PLACE' text={location} />
                <EventData icon='PEOPLE' text={attendanceLimit > 0 ? `${attendanceLimit} people` : 'No limit'} />
              </div>
              <div style={{flex: 1}}>
                <EventData icon='SCHEDULE' text={time} />
                <EventData icon='PRICE' text={`${price} SEK`} />
              </div>
            </td>
          )}
        </tr>
      </tbody>
    </table>
  );
}