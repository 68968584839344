import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarItem from './sidebarItem/SidebarItem';
import { List } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

/* 
  Sets background color to white and sticky so it sticks when user scrolls 
*/
const useStyles = makeStyles((t) => ({
  root: {
    backgroundColor: 'white',
    position: 'sticky',
    top: '40px',
  },
}));

//items hold value and label.
const Sidebar = ({ items, handler, mobile, ref, sidebarComponent }) => {
  //is created because we need to keep track which tab is open
  const [activeSideItem, setActiveSideItem] = useState(0);

  //Sets active side item based on the url.
  let location = useLocation();
  useEffect(() => {
    if (items) {
      let availHashes = [];

      for (const [key, value] of Object.entries(items)) {
        availHashes.push('#' + value.split('#')[1]);
      }

      if (location.hash === '') setActiveSideItem(0);
      else setActiveSideItem(availHashes.findIndex((e) => e === location.hash));
    }
  }, [location]);

  const classes = useStyles();

  /*
    mapping the whole object to reduce code
  */
  return (
    <div className={classes.root}>
      {sidebarComponent || null}
      {items ? (
        <List dense className='list' onClick={handler}>
          {Object.keys(items).map((key, i) => (
            <SidebarItem
              key={i}
              name={key}
              id={i}
              href={items[key]}
              active={activeSideItem}
              clickHandler={setActiveSideItem}
            />
          ))}
        </List>
      ) : null}
    </div>
  );
};

export default Sidebar;
