import React from 'react';
import { Redirect } from 'react-router-dom';
import { userActions } from '/redux/actions/AuthActions';
import { connect } from 'react-redux';

const Logout = (props) => {
  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    props.logout();
    setRedirect(true);
  }, []);

  return <>{redirect ? <Redirect to={`/${props.projectId}/`} /> : null}</>;
};

function mapState(state) {
  return {
    loggedIn: state.authentication.loggedIn,
    error: state.authentication.error,
    projectId: state.projectInfo.projectId,
  };
}

const actionCreators = {
  logout: userActions.logout,
};

const connectedLogoutPage = connect(mapState, actionCreators)(Logout);
export { connectedLogoutPage as Logout };
