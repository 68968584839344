import React from 'react';

import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { adminAccessCurrentPageSet } from '/redux/actions/AdminActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Theme from '/MuiTheme';

const TablePagination = ({users, currentPage, setPage, roles, roleFilter, searchValue}) => {

  const totalPages = () => {

    // transform boolean to integer
    let filtered = [];
    if(roleFilter.every((el) => el === false)) // if nothing is checked
      filtered = roles.map(role => role.roleId);
    else {
      roleFilter.forEach((el, index) => {
        if(el) {
          filtered.push(roles[index].roleId);
        }
      });
    }

    if(Number.isInteger(searchValue))
      return 1;

    let filteredUsers = [...users];
    filteredUsers = filteredUsers.filter((user) => filtered.includes(user.roleId));

    return Math.ceil(filteredUsers.length / 10);
  }

  return(
    <div style={{display: 'flex', width: '100%', justifyContent: useMediaQuery(Theme.breakpoints.up('sm')) ? 'flex-end' : 'center'}}>
      <Pagination count={totalPages()} color='secondary' page={currentPage} onChange={(e, num) => setPage(num)} style={{margin: '16px 0'}} size='small'/>
    </div>
  );
}

const mapState = (state) => {
  return {
    users: state.adminAccess.users,
    currentPage: state.adminAccess.currentPage,
    roleFilter: state.adminAccess.roleFilter,
    roles: state.adminAccess.roles,
    searchValue: state.adminAccess.searchValue,
  };
}

const actions = {
  setPage: adminAccessCurrentPageSet,
};

export default connect(mapState, actions)(TablePagination);;