import React from 'react';
import { ChangePasswordContainer } from '/containers/profile/ChangePassword.container';
import { ChangeEmailContainer } from '/containers/profile/ChangeEmail.container';

const Account = (props) => {

  return (
    <>
      <ChangePasswordContainer />
      <div style={{marginTop: '16px'}} />
      <ChangeEmailContainer />
    </>
  );
};

export default Account;
