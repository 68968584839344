import React, { useEffect, useState } from 'react';
import EventFilter from './EventFilter';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { eventActions } from '/redux/actions/EventActions.jsx';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from '@material-ui/core';
import EventFilterHeader from './EventFilterHeader';

const useStyles = makeStyles((t) => ({
  backdrop: {
    zIndex: t.zIndex.drawer + 10,
  },
  modal: {
    height: '100%',
    overflow: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    [t.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
    '@media (max-height: 730px)': {
      alignItems: 'stretch !important',
    },
  },
  overlay: {
    backgroundColor: '#fff',
    [t.breakpoints.up('sm')]: {
      maxWidth: '320px',
    },
  },
}));

/**
 * The main event filter controlling.
 * Render and functionality
 * events, tickets, eventGroups are required
 */
const EventFilterController = ({ events, tickets, eventGroups, setEventFilter, filterOverlay, closeOverlay }) => {
  const classes = useStyles();

  /* 
    Checkbox options and their values
    FIXME: this should probably come from backend 
  */
  const status = [
    { value: 'booked', label: 'Booked' },
    { value: 'bookable', label: 'Bookable' },
    { value: 'full', label: 'Fully booked' },
    { value: 'closed', label: 'Closed for booking' },
  ];

  const { control, watch, reset } = useForm({ mode: 'onSubmit' });
  const [category, setCategory] = useState(null);

  // has all values for every states
  const watchFields = watch();

  /**
   * pushes to change filter
   */
  const changeFilter = () => {
    let categories = [];
    for (const [key, value] of Object.entries(watchFields)) {
      if (!isNaN(key) && value) categories.push(key);
    }

    const startDatetime = new Date(Date.parse(watchFields.startDate));
    startDatetime.setHours(0, 0, 0, 0);

    const endDatetime = new Date(Date.parse(watchFields.endDate));
    endDatetime.setHours(0, 0, 0, 0);

    let filter = {
      startDate: watchFields.startDate ? new Date(startDatetime) : null,
      endDate: watchFields.endDate ? new Date(endDatetime) : null,
      startPrice: watchFields.startPrice || null,
      endPrice: watchFields.endPrice || null,
      booked: watchFields.booked || false,
      bookable: watchFields.bookable || false,
      closed: watchFields.closed || false,
      full: watchFields.full || false,
      categories: categories,
    };
    setEventFilter(filter);
  };

  /**
   * Set events on change
   */
  useEffect(() => {
    changeFilter();
  }, [watchFields]);

  /**
   * Get 'event groups' / categories and transform them into checkboxes
   * Only show value that the user has
   */
  useEffect(() => {
    if (!events || !tickets || tickets.length === 0 || !eventGroups) return;

    let categories = [];
    eventGroups.forEach((value) => {
      if (value.isBroadcasted && events.filter((event) => event.eventGroups.includes(value.id)).length > 0) {
        categories.push({
          value: value.id.toString(),
          current: tickets.data?.filter((ticket) => ticket.eventGroups.includes(value.id)).length,
          ticketLimit: value.ticketLimit,
          label: value.name,
        });
      }
    });
    setCategory(categories);
  }, [tickets, events, eventGroups]);

  /**
   * Reset values
   */
  const resetFilter = () => {
    let filter = {
      startDate: null,
      endDate: null,
      startPrice: null,
      endPrice: null,
    };
    reset(filter);
  };

  /**
   * Close overlay
   */
  const closeOverlayButton = () => {
    closeOverlay();
  };

  return (
    <>
      {useMediaQuery(Theme.breakpoints.up('md')) ? (
        <>
          <EventFilterHeader resetFilter={resetFilter} />
          <EventFilter control={control} status={status} category={category || []} />
        </>
      ) : (
        <>
          <Backdrop open={filterOverlay} className={classes.backdrop}>
            <div className={classes.modal}>
              <Typography component={'span'}>
                <div className={classes.overlay}>
                  <EventFilterHeader resetFilter={resetFilter} closeOverlay={closeOverlayButton} isMobile />
                  <EventFilter
                    control={control}
                    status={status}
                    category={category || []}
                    isMobile
                    closeOverlay={closeOverlayButton}
                  />
                </div>
              </Typography>
            </div>
          </Backdrop>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    filterOverlay: state.eventRetrival.filterOverlay,
    projectId: state.projectInfo.projectId,
  };
};

const actionCreators = {
  setEventFilter: eventActions.setEventFilter,
  closeOverlay: eventActions.closeOverlay,
};

export default connect(mapStateToProps, actionCreators)(EventFilterController);
