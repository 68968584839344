import React, { useEffect, useState } from 'react';
import MenuBar from './MenuBar';
import MenuBurger from './MenuBurger';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  back: {
    width: '100%',
    height: '64px',
    backgroundColor: 'limegreen',
    [t.breakpoints.down('sm')]: {
      height: '56px',
    },
  },
  root: {
    height: '64px',
    maxWidth: t.breakpoints.values.lg,
    margin: '0 auto',
    padding: '0',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0 1px 8px',
    display: 'flex',
    lineHeight: '48px',
    backgroundColor: t.palette.primary.main,
    [t.breakpoints.up('sm')]: {
      padding: `0 ${t.breakpoints.margin.sm}px`,
    },
    [t.breakpoints.up('md')]: {
      padding: `0 ${t.breakpoints.margin.md}px`,
    },
    [t.breakpoints.down('sm')]: {
      height: '56px',
    },
  },
  burger: {
    display: 'none',
    [t.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  bar: {
    display: 'flex',
    [t.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

/*
    Main Menu handler,
    probably set routes for App by reading from tokens
*/

export const Menu = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.back}>
      <div className={`${classes.root} ${classes.burger}`}>
        <MenuBurger openDrawer={props.openDrawer} toggleDrawer={props.toggleDrawer} routes={props.routes} isPhone />
      </div>
      <div className={`${classes.root} ${classes.bar}`}>
        <MenuBar {...props} routes={props.routes} />
      </div>
    </div>
  );
};
