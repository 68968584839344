import React, { useEffect } from 'react';
import SearchBar from '/components/searchBar/SearchBar';
import { connect } from 'react-redux';
import { adminUsersSetSearchValue } from '/redux/reducers/AdminReducers';
import { getUserData } from '/redux/actions/AdminActions';

/**
 * Container for search bar in Users Overview
 */
const SearchBarContainer = (props) => {

  useEffect(() => {
    if(props.searchValue !== '')
      props.getUserData();
  }, [props.searchValue]);

  return (<SearchBar setSelected={props.setSearchValue} projectId={props.projectId} />);
};

const mapState = (state) => {
  return {
    projectId: state.projectInfo.projectId,
    searchValue: state.adminUsers.searchValue,
  };
}

const actionCreators = {
  setSearchValue: adminUsersSetSearchValue,
  getUserData: getUserData,
};

export default connect(mapState, actionCreators)(SearchBarContainer);