import React, { useEffect } from 'react';
import FirstNameInput from '/containers/FirstNameInput/FirstNameInput';
import LastNameInput from '/containers/LastNameInput/LastNameInput';
import DateOfBirthInput from '/containers/DateOfBirthInput/DateOfBirthInput';
import GenderSelect from '/containers/GenderSelect/GenderSelect';
import NationalitySelect from '/containers/NationalitySelect/NationalitySelect';
import PersonalNumberInput from '/containers/PersonalNumberInput/PersonalNumberInput';
import PhoneNumberInput from '/containers/PhoneNumberInput/PhoneNumberInput';
import TermsOfUseCheckbox from '/containers/TermsOfUseCheckbox/TermsOfUseCheckbox';
import CommunicationCheckbox from '/containers/CommunicationCheckbox/CommunicationCheckbox';
/**
 * Renders General Information of Register page
 */
const RegisterStepOne = ({ control, getValues, profileOptions, projectId }) => {
  return (
    <>
      <FirstNameInput control={control} />
      <LastNameInput control={control} />
      <DateOfBirthInput control={control} />
      <GenderSelect control={control} profileOptions={profileOptions} />
      <NationalitySelect control={control} profileOptions={profileOptions} />
      <PersonalNumberInput control={control} getValues={getValues} />
      <PhoneNumberInput control={control} />
      <TermsOfUseCheckbox control={control} projectId={projectId} />
      <CommunicationCheckbox control={control} projectId={projectId} />
    </>
  );
};

export default RegisterStepOne;
