import React from 'react';
import ButtonComponent from '/components/forms-v2/Button.component';
import { useForm } from 'react-hook-form';
import PasswordInput from '/containers/PasswordInput/PasswordInput';
import ConfirmPasswordInput from '/containers/ConfirmPasswordInput/ConfirmPasswordInput';
import { ChangeUserPassword } from '/data/api/ProfileRequest';
import { snackActions } from '/redux/actions/FeedbackActions';
import { useDispatch } from 'react-redux';

export const ChangePasswordContainer = () => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({ mode: 'all' });

  const watchFields = watch(["confirmPassword", "oldPassword", "password"]);

  const buttonPressable = () => {
    let pressable = true;
    watchFields.forEach((field) => {
      if (field === '' || field === undefined) {
        pressable = false;
      }
    });
    return pressable;
  }

  const onSubmit = (data) => {
    const {password, oldPassword} = data;
    ChangeUserPassword(oldPassword, password)
      .then(() => {
        dispatch(snackActions.successFeedback('Password Changed Successfully'));
      })
      .catch((err) => {
        if(err.response.status === 500) {
          dispatch('Internal server error');
        }
        dispatch(snackActions.errorFeedback('' + err.response.data.error.message));
      });
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6>Change Password</h6>
        <PasswordInput 
          name='oldPassword' 
          control={control} 
          label='Old Password' 
          disableHelper
          rules={{
            validate: {
              passwordMatch: (v) => (v !== getValues('password') ? true : 'Old password is the same as new one'),
            },
          }}  
        />
        <PasswordInput name='password' control={control} label='New Password' />
        <ConfirmPasswordInput name='confirmPassword' control={control} label='Confirm Password' getValues={getValues} />
        <ButtonComponent label='Save' fixedWidth disabled={!buttonPressable()} />
      </form>
    </>
  );
};