import React from 'react';

import { TableRow } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CustomTableCell from '/components/adminAccess/customTableCell/CustomTableCell';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const BookingBodyDesktop = ({isActivated, isPaid, isAttended, date, title, price, onClick}) => (
  <TableRow style={{backgroundColor: isActivated ? '#104E7514' : null, color: isActivated ? '#104E75' : null}}>
    <CustomTableCell>
      <Checkbox checked={isActivated} onChange={onClick} style={{padding: 0}} />
    </CustomTableCell>
    <CustomTableCell>
      <div style={{display: 'flex'}}>
        <Typography variant='body1' style={{color: isActivated ? '#104E75' : null}}>
          {date}
        </Typography>
      </div>
    </CustomTableCell>
    <CustomTableCell>
      <Typography variant='body1' style={{color: isActivated ? '#104E75' : null}}>
        {title}
      </Typography> 
    </CustomTableCell>
    <CustomTableCell>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Typography variant='body1' style={{color: isPaid ? '#20B000' : '#B00020'}}>
          {price} SEK
        </Typography>
      </div>
    </CustomTableCell>
    <CustomTableCell>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {
          !isAttended ? 
          <CloseIcon style={{color: '#B00020'}} /> :
          <CheckIcon style={{color: '#20B000'}} />
        }
      </div>
    </CustomTableCell>
  </TableRow>
);

export default BookingBodyDesktop;