import React, { useEffect } from 'react';

import BodyDesktop from '/components/adminAccess/desktop/BodyDesktop';
import BodyMobile from '/components/adminAccess/mobile/BodyMobile';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Theme from '/MuiTheme';

import { 
  getUsers,
  setUserRole, 
  setUserEditing, 
  adminAccessIdToDeleteSet, 
  adminAccessDeleteDialogSet 
} from '../../../redux/actions/AdminActions';

import { connect } from 'react-redux';

const BodyContainer = ({users, currentPage, getUsers, roleFilter, roles, searchValue, setUserEditing, setUserRole, setIdToDelete, setDeleteDialogOpen}) => {

  useEffect(() => {
    if(!users || users.length === 0) {
      getUsers();
    }
  }, []); 

  const filterUsers = () => {
    // map checked array to numbers
    let filtered = [];
    if(roleFilter.every((el) => el === false)) // if nothing is checked
      filtered = roles.map(role => role.roleId);
    else {
      roleFilter.forEach((el, index) => {
        if(el) {
          filtered.push(roles[index].roleId);
        }
      });
    }

    let filteredUsers = [...users];
    filteredUsers = filteredUsers.filter((user) => filtered.includes(user.roleId));

    if(searchValue === '') {
      return filteredUsers.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10);
    }

    return users.filter((user) => user.accountId === searchValue);
  }

  return(
    <>
      {useMediaQuery(Theme.breakpoints.up('sm')) ? (
        filterUsers().map((user, index) => (
          <BodyDesktop
            key={index}
            user={user}
            index={index}
            roles={roles}
            setUserRole={setUserRole}
            setUserEditing={setUserEditing}
            setIdToDelete={setIdToDelete}
            setDeleteDialogOpen={setDeleteDialogOpen}
          />
        ))
      ) : (
        filterUsers().map((user, index) => (
          <BodyMobile
            key={index}
            user={user}
            index={index}
            roles={roles}
            setUserRole={setUserRole}
            setUserEditing={setUserEditing}
            setIdToDelete={setIdToDelete}
            setDeleteDialogOpen={setDeleteDialogOpen}
          />
        ))
      )}
    </>
  );
};

const mapState = (state) => {
  return {
    users: state.adminAccess.users,
    roleFilter: state.adminAccess.roleFilter,
    roles: state.adminAccess.roles,
    currentPage: state.adminAccess.currentPage,
    searchValue: state.adminAccess.searchValue
  };
}

const actions = {
  getUsers: getUsers,
  setUserRole: setUserRole,
  setUserEditing: setUserEditing,
  setIdToDelete: adminAccessIdToDeleteSet,
  setDeleteDialogOpen: adminAccessDeleteDialogSet,
};

export default connect(mapState, actions)(BodyContainer);