import React from 'react';
import Sidebar from './sidebar/Sidebar';
import ContentHeader from './ContentHeader';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// FIXME: Remove the debug colours when finished
const useStyles = makeStyles((t) => ({
  root: {
    alignItems: 'flex-start',
    maxWidth: '1280px',
    backgroundColor: 'white',
    [t.breakpoints.up('xs')]: {
      padding: `0 ${t.breakpoints.margin.xs}px`,
    },
    [t.breakpoints.up('sm')]: {
      padding: `0 ${t.breakpoints.margin.sm}px`,
    },
    [t.breakpoints.up('md')]: {
      padding: `40px ${t.breakpoints.margin.md}px`,
    },
    [t.breakpoints.up('lg')]: {
      padding: `40px ${t.breakpoints.margin.lg}px`,
    },
  },
  rootHeader: {
    alignItems: 'flex-start',
    maxWidth: '1280px',
    direction: 'column',
    alignItems: 'stretch',
    height: '100%',
    backgroundColor: 'white',
    [t.breakpoints.up('xs')]: {
      padding: `0 ${t.breakpoints.margin.xs}px`,
    },
    [t.breakpoints.up('sm')]: {
      padding: `0 ${t.breakpoints.margin.sm}px`,
    },
    [t.breakpoints.up('md')]: {
      padding: `0 ${t.breakpoints.margin.md}px`,
    },
    [t.breakpoints.up('lg')]: {
      padding: `0 ${t.breakpoints.margin.lg}px`,
    },
  },
  small: {
    alignItems: 'flex-start',
    minHeight: '640px',
    height: 'calc(100vh - 300px)',
    width: '320px',
    margin: 'calc(20vh - 50px) auto',
  },
  sidebar: {
    [t.breakpoints.up('md')]: {
      paddingRight: '12px',
    },
  },
  withSidebar: {
    [t.breakpoints.up('md')]: {
      paddingLeft: '12px',
    },
  },
  content: {
    minHeight: '100vh',
  },
}));

const Content = (props) => {
  const classes = useStyles();
  // xs <= screen < md --> sidebar on-top
  // md <= screen --> sidebar on-the side
  if (props.size == 'small') return <SmallContent {...props}></SmallContent>;
  const greyBackground = props.greyBackground ? props.greyBackground : false;

  return (
    <>
      {useMediaQuery(Theme.breakpoints.down('sm')) && props.sidebarComponent}
      <Grid
        className={props.header ? classes.rootHeader : classes.root}
        style={greyBackground && useMediaQuery(Theme.breakpoints.up('md')) ? { backgroundColor: '#F5F5F5' } : null}
        container
        justifyContent='center'
      >
        {props.header ? (
          <Grid item md={12} xs={12}>
            <ContentHeader>{props.header}</ContentHeader>
          </Grid>
        ) : null}
        {useMediaQuery(Theme.breakpoints.up('md')) && (props.sidebar || props.sidebarComponent) ? (
          <>
            <Grid
              className={classes.sidebar}
              item
              md={3}
              xs={12}
              style={(props.style, { height: !props.header ? '100%' : null })}
            >
              <Sidebar items={props.sidebar} handler={props.handler} sidebarComponent={props.sidebarComponent} />
            </Grid>
            <Grid className={`${classes.content} ${classes.withSidebar}`} item md={9} xs={12}>
              {props.children}
            </Grid>
          </>
        ) : (
          <Grid item md={12} xs={12} className={classes.content}>
            {props.children}
          </Grid>
        )}
      </Grid>
    </>
  );
};

const SmallContent = (props) => {
  const classes = useStyles();
  // no sidebar on small contents

  return (
    <>
      <Grid className={classes.small} container justify='center'>
        <Grid item md={9} xs={12} className='inner-content'>
          {props.children}
        </Grid>
      </Grid>
    </>
  );
};
export default Content;
