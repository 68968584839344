import React from 'react';
import ListItem from '@material-ui/core/ListItem';

import { makeStyles } from '@material-ui/core/styles';
/* 
  This Fade from color manipulator seems to combine HEX to RGB and then add opacity to it
  Might really come in handy later
*/
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';

// Styling
const useStyles = makeStyles((t) => ({
  root: {
    width: '100%',
    transition: 'none',
    height: '48px',
    padding: '12px 16px',
    lineHeight: '24px',
    color: `${t.palette.surface.text}${t.palette.other.opacityHigh}`,
    transition: 'background-color 0.1s',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: `${t.palette.primary.main}10`,
    },
  },
  active: {
    color: `${t.palette.primary.main}${t.palette.other.opacityHigh}`,
    backgroundColor: `${t.palette.primary.main}30`,
    pointerEvents: 'none',
  },
}));

/* 
  Combines two classes if active, else only show root class 
*/
const SidebarItem = ({ name, href, id, active, clickHandler }) => {
  const classes = useStyles();

  return (
    <ListItem
      className={active == id ? `${classes.root} ${classes.active}` : `${classes.root}`}
      component='a'
      href={typeof href === 'string' ? href : ''}
      onClick={() => clickHandler(id)}
    >
      <Typography variant='body1'>{name}</Typography>
    </ListItem>
  );
};

export default SidebarItem;
