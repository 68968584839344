import React from 'react';

import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import PaymentIcon from '@material-ui/icons/Payment';
import { makeStyles } from '@material-ui/core/styles';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

const useStyles = makeStyles((t) => ({
  root: {
    backgroundColor: props => props.isActivated ? `${t.palette.secondary.main}${t.palette.overlay.opacity.dragged}` : null, 
    color: props => props.isActivated ? t.palette.secondary.main : null, 
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)', 
    display: 'flex', 
    alignItems: 'center',
  },
  checkbox: {
    padding: 0,
  },
  container: {
    flexGrow: 1, 
    flexDirection: 'column', 
    justifyContent: 'center', 
    marginLeft: '16px', 
    alignItems: 'center', 
    paddingTop: '12px', 
    paddingBottom: '12px',
  },
  date: {
    color: props => props.isActivated ? null : `${t.palette.background.text}${t.palette.other.opacityMedium}`, 
    letterSpacing: '1.5px', 
    lineHeight: '16px', 
    fontSize: '10px',
  },
  iconContainer: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between',
  },
  icon: {
    height: '16px',
  },
  attendedContainer: {
    color: props => props.isAttended ? t.palette.success.main : t.palette.error.main, 
    display: 'flex', 
    alignItems: 'center',
  },
  paidContainer: {
    color: props => props.isPaid ? t.palette.success.main : t.palette.error.main, 
    display: 'flex', 
    alignItems: 'center',
  },
}));

const BookingBodyMobile = ({isActivated, isPaid, isAttended, date, title, price, onClick}) => {

  const classes = useStyles({isActivated, isPaid, isAttended});

  return(
    <div className={classes.root}>
      <Checkbox className={classes.checkbox} checked={isActivated} onChange={onClick} />
      <div className={classes.container}>
        <Typography className={classes.date} variant='body2'>{date}</Typography>
        <Typography variant='h6'>{title}</Typography>
        <table style={{width: '100%', tableLayout: 'fixed'}}>
          <tbody>
            <tr>
              <td style={{whiteSpace: 'nowrap'}}>
                <div className={classes.attendedContainer}>
                  <EmojiPeopleIcon className={classes.icon} />
                  <Typography variant='caption'>{isAttended ? 'Attended' : 'Not attended'}</Typography>
                </div>
              </td>
              <td style={{whiteSpace: 'nowrap'}}>
                <div className={classes.paidContainer}>
                  <PaymentIcon className={classes.icon} />
                  <Typography variant='caption'>{price} SEK</Typography>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BookingBodyMobile;