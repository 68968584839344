import * as Backend from '../api/BackendRequest.jsx';

export const GetUsersList = (projectId) => {
  return Backend.request('get', `admin/${projectId}/roles/list`);
};

export const SetUserRole = (projectId, accountId, roleId) => {
  return Backend.request('patch', `admin/${projectId}/role/${accountId}`, {}, { roleId: roleId });
};

export const DeleteUser = (projectId, idToDelete) => {
  return Backend.request('delete', `admin/${projectId}/user`, { removalId: idToDelete });
};

export const GetUserInfo = (projectId, userId) => {
  return Backend.request('get', `/admin/${projectId}/user/${userId}`);
};

export const GetUserStudy = (projectId, userId) => {
  return Backend.request('get', `/admin/${projectId}/study-info/${userId}`);
};

export const GetUserTickets = (projectId, userId) => {
  return Backend.request('get', `/admin/${projectId}/user/tickets/${userId}`, {
    projectId: projectId,
  });
};

export const GetUserPrefs = (projectId, userId, optionId) => {
  return Backend.request('get', `/admin/${projectId}/food/${userId}/${optionId}`);
};

export const GetUserCheckedIn = (projectId, userId) => {
  return Backend.request('GET', `admin/${projectId}/user/checkin/${userId}`);
};

export const GetAllEvents = (projectId) => {
  return Backend.request('GET', `admin/${projectId}/events`);
}

export const CheckEventToUser = (projectId, eventId, userId) => {
  return Backend.request('GET', `/admin/${projectId}/check-booking/${eventId}/${userId}`);
}

export const AddBookingToUser = (projectId, userId, eventId, isPaid, isAttending) => {
  return Backend.request('POST', `admin/${projectId}/booking/${eventId}/${userId}`, {}, {isPaid, isAttending});
}

export const SetUserCheckedIn = (projectId, userId, value) => {
  return Backend.request('PATCH', `admin/${projectId}/user/checkin/${userId}`, {}, { checkedIn: value });
};

export const AddEventToUser = (projectId, eventId, userId) => {
  return Backend.request('POST', `admin/${projectId}/booking/${eventId}/${userId}`);
};

export const GetUserArrivalDate = (projectId, userId) => {
  return Backend.request('GET', `admin/${projectId}/project/info/${userId}`);
}

export const DeleteBookingToUser = (projectId, bookingId) => {
  return Backend.request('DELETE', `admin/${projectId}/ticket/${bookingId}`);
};

export const SetEventPaid = (projectId, bookingId, isPaid) => {
  return Backend.request('PATCH', `admin/${projectId}/booking/${bookingId}`, {}, { isPaid: isPaid });
};

export const ExportSingleEvent = (projectId, eventId) => {
  return Backend.requestBlob('GET', `admin/${projectId}/export_single/${eventId}`);
};

export const CheckTransfer = (projectId, bookingId, userId) => {
  return Backend.request('GET', `admin/${projectId}/booking/${bookingId}`);
};

export const TransferTicket = (projectId, bookingId, userId, isPaid, isAttending) => {
  return Backend.request('PATCH', `admin/${projectId}/booking/${bookingId}`, {}, { isPaid: isPaid });
};

export const CheckTicket = (projectId, userId, bookingId) => {
  return Backend.request('POST', `admin/${projectId}/ticket/transfer/check/${userId}`, {}, { bookingID: bookingId });
};

export const PostTicket = (projectId, userId, bookingId, isAttending, isPaid) => {
  return Backend.request('POST', `admin/${projectId}/ticket/transfer/confirm/${userId}`, {}, { bookingID: bookingId , isAttending, isPaid});
};