import React from 'react';
import TextComponent from '/components/forms-v2/Text.component';

const PasswordInput = ({ control, disableHelper, name='password', label='Password', rules }) => {

  return (
    <TextComponent
      name={name}
      control={control}
      label={label}
      rules={{
        required: { value: true, message: 'Enter password' },
        minLength: { value: 8, message: 'Password is too short' },
        ...rules,
        // pattern: {
        //   value: /^(?=.*\d)(?=.*(([a-z])|[!@#$%^&*]))/i,
        //   message: 'Your password must have at least: 1 number, 1 character',
        // },
      }}
      type='password'
      helperText={!disableHelper && 'Minimum 8 characters'}
    />
  );
};

export default PasswordInput;
