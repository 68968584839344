import React from 'react';
import TextComponent from '/components/forms-v2/Text.component';

const LastNameInput = ({ control }) => {
  return (
    <TextComponent
      name='lastName'
      control={control}
      label='Last Name'
      rules={{
        required: { value: true, message: 'Enter last name' },
        maxLength: { value: 128, message: 'Too long input' },
      }}
    />
  );
};

export default LastNameInput;
