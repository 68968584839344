import axios from 'axios';

export function request(method, endpoint, params, data = {}) {
  return rawRequest(method, endpoint, params, data, {});
}

export function requestBlob(method, endpoint, params, data = {}) {
  const config = {};
  config.responseType = 'blob'; //For file requests.
  return rawRequest(method, endpoint, params, data, config);
}

export function rawRequest(method, endpoint, params, data = {}, conf) {
  const BASEURL = process.env.API_ADDRESS + ':' + process.env.API_PORT;
  const config = Object.assign({}, conf);
  config.headers = { 'Content-Type': 'application/json' };
  if (sessionStorage['token'] != null) {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage['token'];
  }
  config.baseURL = BASEURL;
  config.method = method;
  config.url = endpoint;
  config.params = params;
  config.data = data;
  return sendRequest(config);
}

function sendRequest(config) {
  return axios(config)
    .then((req) => {
      return req;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function storeResponse(response, responseDataKey, sessionStorageKey) {
  if (response.status == 200) {
    switch (responseDataKey) {
      case '':
        sessionStorage.setItem(sessionStorageKey, response.data['data']); // FIXME: This should maybe be somewhere else?
        break;
      default:
        sessionStorage.setItem(sessionStorageKey, response.data['data'][responseDataKey]); // FIXME: This should maybe be somewhere else?
    }
    return response.data.data;
  } else {
    return Promise.reject('Error' + response.data);
  }
}
