import * as Backend from '../api/BackendRequest.jsx';

export function GetConfirmation(token) {
  return Backend.request('get', '/confirm', { token }, {});
}

export function GetUpdateEmail(token) {
  return Backend.request('get', '/updateEmail', { token }, {});
}

export function RequestProgrammes() {
  return Backend.request('GET', '/programmes', {}, '');
}

export function RequestSignup(data) {
  return Backend.request('post', '/signup', {}, data);
}

export function RequestConfirmEmail(email, pid) {
  return Backend.request('post', `/email/${pid}`, {}, email);
}

export function RequestLogin(payload, projectId) {
  return Backend.request('post', `/login/${projectId}`, {}, payload).then((data) =>
    Backend.storeResponse(data, 'token', 'token')
  );
}

export function Logout() {
  sessionStorage.removeItem('token');
}

export function RequestForgot(payload, pid) {
  return Backend.request('post', `/forgot/${pid}`, {}, payload);
}

export function RequestReset(token, payload) {
  return Backend.request('post', '/reset', { token: token }, payload);
}

export function RequestProject(token) {
  return Backend.request('get', '/user/project', {});
}
