import React from 'react';
import CheckComponent from '/components/forms-v2/Check.component';
import Typography from '@material-ui/core/Typography';

const TermsOfUseCheckbox = ({ control, projectId }) => {
  return (
    <CheckComponent
      name='termsAgree'
      control={control}
      rules={{
        validate: {
          isTrue: (v) => (v === true ? true : 'You must accept'),
        },
      }}
      noMargin
      label={
        <>
          <Typography variant='body2'>
            I agree with the&nbsp;
            <a target='_blank' rel='noopener noreferrer' href={`/${projectId}/about#terms`}>
              terms of use
            </a>
          </Typography>
        </>
      }
    />
  );
};

export default TermsOfUseCheckbox;
