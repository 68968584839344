export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_STUDENT = 'ROLE_STUDENT';
export const ROLE_NO_PROJECT = 'ROLE_NO_PROJECT';
export const ROLE_FAIL = 'ROLE_FAIL';

export const accessActions = {
  setRole,
  resetRole,
};

function setRole(token) {
  return (dispatch) => {
    let roleID = JSON.parse(atob(token.split('.')[1])).projectRole;
    // TODO: I belive that this action will never be called if token is empty. So this wrapping clause might be superfluous.
    if (token) {
      if (roleID < 6) {
        dispatch(roleAdmin());
      } else {
        dispatch(roleStudent());
      }
    } else {
      dispatch(roleEMPTY('token not ok'));
    }
  };
}

function resetRole(reason) {
  return (dispatch) => {
    dispatch(roleEMPTY(reason));
  };
}

function roleAdmin() {
  let routes = ['Home', 'Events', 'Tickets', 'Profile', 'Logout', 'Admin'];
  return { type: ROLE_ADMIN, routes: routes };
}

function roleStudent() {
  let routes = ['Home', 'Events', 'Tickets', 'Profile', 'Logout'];
  return { type: ROLE_STUDENT, routes: routes };
}

export function roleNoProject() {
  const routes = ['Home', 'Logout'];
  return { type: ROLE_NO_PROJECT, routes: routes };
}

function roleEMPTY(err) {
  let routes = ['Login'];
  return { type: ROLE_FAIL, routes: routes, error: err };
}
