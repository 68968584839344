import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Content from '/components/content/Content.jsx';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { GetUpdateEmail } from '/data/api/AuthRequest.jsx';
import { makeStyles } from '@material-ui/core';
import FormLogo from '/components/formLogo/FormLogo.component';
import FormContainer from '/components/formContainer/FormContainer';
import Footer from '/components/footer/Footer';

const useStyles = makeStyles((t) => ({
  logoCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '14px',
    width: '95px',
  },
  textCenter: {
    textAlign: 'center',
    lineHeight: '28px',
  },
  header: {
    marginTop: '16px !important',
  },
  text: {
    marginTop: '5px',
    marginBottom: '16px',
  },
  button: {
    width: '100%',
    marginBottom: '40px',
  },
}));

const PatchEmail = () => {
  const classes = useStyles();
  const history = useHistory();

  const projectId = useSelector((state) => state.projectInfo.projectId);

  const handleClick = () => {
    history.push(`/${projectId}/login`);
  };

  useEffect(() => {
    GetUpdateEmail(new URLSearchParams(window.location.search).get('token')).catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />
          <h6 className={classes.header}>Thank you!</h6>
          <p className={classes.text}>Your e-mail has been successfully updated!</p>
          <Button className={classes.button} onClick={handleClick} variant='contained' color='secondary'>
            Continue
          </Button>
        </FormContainer>
      </Content>
      <Footer name='IR' />
    </>
  );
};

export default PatchEmail;
