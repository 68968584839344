/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AdminEventCard from '/components/adminEventCard/AdminEventCard';
import FilterComponent from '/components/forms/FilterComponent';
import * as Backend from '/data/api/BackendRequest.jsx';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import './eventManagement.scss';
import DisplayBookings from '/components/displayBookings/DisplayBookings';
import { Button, Typography } from '@material-ui/core';
import SearchBar from '/components/searchBar/SearchBar';
import axios from 'axios';
import { ExportSingleEvent } from '/data/api/AdminRequest';

const attr = ['name'];

const EventManagement = ({ event, setCreateData, setCreateSettings, bookingStats, getBookingStats, projectId }) => {
  const [staticFilters, setStaticFilters] = useState({});
  const [user, setUser] = useState();
  const [editBookings, setEditBookings] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [displayBookings, setDisplayBookings] = useState([]);
  const [sortAscending, setSortAscending] = useState(false);

  const addUser = (userId) => {
    Backend.request('POST', `admin/${projectId}/booking/${event.eventId}/${userId}`)
      .then(() => {
        getBookings();
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (name, value) => {
    const newFilters = { ...staticFilters };

    if (value === 'none') {
      delete newFilters[name];
    } else {
      newFilters[name] = value;
    }
    setStaticFilters(newFilters);
  };

  useEffect(() => {
    getBookings();
  }, [event]);

  const getBookings = () => {
    getBookingStats();
    Backend.request(
      'get',
      `admin/${projectId}/event/status/${event.eventId}`
      // `admin/event/status/2`
    ).then((response) => {
      const bookings = sortCards(
        response.data.data.map((item) => ({
          ...item,
          name: (item.firstName + ' ' + item.lastName).normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        }))
      );
      setBookings(bookings);
    });
  };

  useEffect(() => {
    setDisplayBookings(sortCards(bookings));
  }, [bookings, sortAscending]);

  const sortCards = (bookings) => {
    return [
      ...bookings.sort((val1, val2) => {
        const nameA = val1.name.toLowerCase();
        const nameB = val2.name.toLowerCase();
        if (nameA < nameB) return sortAscending ? 1 : -1;
        if (nameA > nameB) return sortAscending ? -1 : 1;
        return 0;
      }),
    ];
  };

  const exportEventData = () => {
    ExportSingleEvent(projectId, event.eventId)
      .then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        const regExpFilename = /filename="(?<filename>.*)"/;
        const filename = regExpFilename.exec(response.headers['content-disposition'])?.groups?.filename;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((e) => {
        console.log('Something went wrong when requesting the export.');
        console.error(e);
      });
  };

  return (
    <div className='containerManage'>
      <AdminEventCard
        inStatus
        event={event}
        setCreateData={setCreateData}
        setCreateSettings={setCreateSettings}
        bookingStats={bookingStats[event.eventId]}
      />

      <div className='myrow' style={{ flexDirection: 'row', marginLeft: '20px' }}>
        <Button onClick={() => exportEventData()} color='primary' variant='contained'>
          Export event data
        </Button>
      </div>

      <div className='myrow' style={{ flexDirection: 'row', marginLeft: '20px', marginTop: '20px' }}>
        <Typography variant='h6'>Bookings</Typography>
      </div>

      <div className='inputRow'>
        <FilterComponent list={bookings} setList={setDisplayBookings} attributes={attr} staticFilters={staticFilters}>
          Filter user
        </FilterComponent>
        <div className='addUser'>
          <SearchBar setSelected={setUser} projectId={projectId} />
          <Button onClick={() => addUser(user)} color='primary' variant='contained'>
            Add user
          </Button>
        </div>
      </div>

      <div className='toolbar'>
        <div
          className='sortComponent'
          onClick={() => {
            setSortAscending(!sortAscending);
          }}
        >
          {sortAscending ? <ArrowDropUpIcon fontSize='large' /> : <ArrowDropDownIcon fontSize='large' />}
        </div>
        <div className='filters'>
          <Select
            name='isAttending'
            style={{ background: 'white', width: '90px' }}
            value={staticFilters.isAttending || 'none'}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
          >
            <MenuItem value='none'>No filter</MenuItem>
            <MenuItem value='true'>Attending</MenuItem>
            <MenuItem value='false'>Not attending</MenuItem>
          </Select>
          <Select
            disabled={event.basePriceSek == 0}
            name='isPaid'
            style={{ background: 'white', width: '90px' }}
            value={staticFilters.isPaid || 'none'}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
          >
            <MenuItem value='none'>No filter</MenuItem>
            <MenuItem value='true'>Paid</MenuItem>
            <MenuItem value='false'>Not Paid</MenuItem>
          </Select>
        </div>
        <div className='edit'>
          {!editBookings && (
            <EditIcon onClick={() => setEditBookings((prev) => !prev)} className='edit' fontSize='small' />
          )}
          {editBookings && (
            <CloseIcon onClick={() => setEditBookings((prev) => !prev)} className='edit' fontSize='small' />
          )}
        </div>
      </div>
      <div className='cardContainer'>
        <DisplayBookings
          event={event}
          getBookings={getBookings}
          bookings={displayBookings}
          editBookings={editBookings}
        />
      </div>
    </div>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(EventManagement);
