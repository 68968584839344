import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Controller } from 'react-hook-form';

/**
 * Renders a full-width Check component
 *
 * @param name the variable name
 * @param control control object from useForm
 * @param options list of options in {value, label} form
 */
const RadioComponent = ({ name, control, options, defaultValue }) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field }) => (
      <RadioGroup {...field} style={{ width: '100%', marginBottom: '16px', marginTop: '0px' }}>
        {options.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio style={{ paddingTop: '2px', paddingBottom: '2px' }} />}
              label={<Typography variant='body2'>{option.label}</Typography>}
            />
          );
        })}
      </RadioGroup>
    )}
  />
);

export default RadioComponent;
