export const IS_LOADING = 'IS_LOADING';
export const EVENTS_RETRIVED = 'EVENTS_RETRIVED';
export const EVENTS_STATS_RETRIVED = 'EVENTS_STATS_RETRIVED';
export const TICKETS_RETRIVED = 'TICKETS_RETRIVED';
export const TICKET_REMOVED = 'TICKET_REMOVED';
export const EVENT_BOOKED = 'EVENT_BOOKED';
export const EVENT_ERROR = 'EVENT_ERROR';
export const EVENT_FILTER = 'EVENT_FILTER';
export const EVENT_OVERLAY = 'EVENT_OVERLAY';
export const EVENT_SEARCH = 'EVENT_SEARCH';
export const EVENT_GROUPS = 'EVENT_GROUPS';

import {
  RequestEventsList,
  RequestEventStats,
  RequestBooking,
  RequestTicketsList,
  RequestTicketRemoval,
} from '../../data/Events/Data.jsx';

// FIXME: Reduce the coupling of FeedbackActions and EventActions.
import { snackActions } from './FeedbackActions';
import * as Backend from '/data/api/BackendRequest';

export const eventActions = {
  getEventList,
  getTicketsList,
  getEventGroups,
  bookTicket,
  removeTicket,
  setEventFilter,
  setEventSearch,
  openOverlay,
  closeOverlay,
};

function getEventList(pid) {
  return (dispatch) => {
    dispatch(isLoading());
    RequestEventsList(pid)
      .then((response) => {
        dispatch(eventsRetrived(response.data));
        RequestEventStats(pid)
          .then((res) => {
            let stats = [];
            res.data.data.forEach(eventStat => stats.push({...eventStat, isBooking: false}));
            dispatch(eventStatsRetrived(stats));
          })
          .catch((err) => {
            dispatch(eventRetrivalFail(err));
          });
      })
      .catch((err) => {
        dispatch(eventRetrivalFail(err));
      });
  };
}

function getTicketsList(pid) {
  return (dispatch) => {
    dispatch(isLoading());
    RequestTicketsList(pid)
      .then((response) => {
        dispatch(ticketsRetrived(response.data));
      })
      .catch((err) => {
        dispatch(eventRetrivalFail(err));
      });
  };
}

function bookTicket(pid, eventId, displayTitle) {
  return (dispatch) => {
    dispatch(isLoading());

    // disables the booking button for the event
    dispatch(setEventPressable(true, eventId));

    RequestBooking(pid, eventId)
      .then((response) => {
        dispatch(eventBooked(response.data));
        dispatch(snackActions.successFeedback(`Success: A ticket for ${displayTitle} has been booked!`));
        dispatch(getTicketsList(pid));

        // enables the button again after successful booking
        dispatch(setEventPressable(false, eventId));
      })
      .catch((err) => {
        dispatch(eventRetrivalFail(err));
        if (err.response.status < 500) dispatch(snackActions.errorFeedback('' + err.response.data.error.message));
        else dispatch(snackActions.errorFeedback('Server error'));

        // enables the button again after unsuccessful booking
        dispatch(setEventPressable(false, eventId));
      });
  };
}

/**
 * 
 * @param setBooking which state to set booking button to
 * @param eventId id of event
 * @returns a dispatch to update the list
 */
const setEventPressable = (setBooking, eventId) => {
  return (dispatch, getState) => {
    const oldStatsList = getState().eventRetrival.stats;
    if(!oldStatsList) { // to make sure the page does not crash if the server had returned null
      console.error('Event Stats is null');
    } else {
      let newStatsList = oldStatsList.map(stat => ({...stat}));
      newStatsList[newStatsList.findIndex(stat => stat.eventId === eventId)].isBooking = setBooking;
      dispatch(eventStatsRetrived(newStatsList));
    }
  }
}

function removeTicket(bid, pid) {
  return (dispatch) => {
    dispatch(isLoading());
    RequestTicketRemoval(bid)
      .then((bookingId) => {
        dispatch(ticketRemoved(bid));
        dispatch(snackActions.successFeedback(`Success: Ticket removed!`));
        dispatch(getTicketsList(pid));
      })
      .catch((err) => {
        dispatch(eventRetrivalFail(err));
        if (err.response.status < 500) dispatch(snackActions.errorFeedback('' + err.response.data.error.message));
        else dispatch(snackActions.errorFeedback('Server error'));
      });
  };
}

function getEventGroups(pid) {
  return async (dispatch) => {
    Backend.request('GET', `user/${pid}/event-group`)
      .then((res) => {
        let eventgroups = [];
        for (const [key, value] of Object.entries(res.data.data)) {
          eventgroups.push(value);
        }
        dispatch(setEventGroups(eventgroups));
      })
      .catch((err) => console.log(err));
  };
}

function setEventFilter(filterPayload) {
  return { type: EVENT_FILTER, filter: filterPayload };
}

function isLoading() {
  return { type: IS_LOADING };
}

function eventsRetrived(data) {
  return { type: EVENTS_RETRIVED, data: data };
}

function eventStatsRetrived(data) {
  return { type: EVENTS_STATS_RETRIVED, data: data };
}

function ticketsRetrived(data) {
  return { type: TICKETS_RETRIVED, data: data };
}

function ticketRemoved() {
  return { type: TICKET_REMOVED };
}

function eventBooked(data) {
  return { type: EVENT_BOOKED, data: data };
}

function setEventSearch(data) {
  return { type: EVENT_SEARCH, filterSearch: data };
}

function eventRetrivalFail(err) {
  return { type: EVENT_ERROR, error: err };
}

function openOverlay() {
  return { type: EVENT_OVERLAY, overlay: true };
}

function closeOverlay() {
  return { type: EVENT_OVERLAY, overlay: false };
}

function setEventGroups(data) {
  return { type: EVENT_GROUPS, data: data };
}
