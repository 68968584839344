import React from 'react';
import TextComponent from '/components/forms-v2/Text.component';

const ConfirmPasswordInput = ({ control, getValues }) => {
  return (
    <TextComponent
      name='confirmPassword'
      control={control}
      label='Confirm Password'
      rules={{
        required: { value: true, message: 'Enter confirm password' },
        validate: {
          passwordMatch: (v) => (v === getValues('password') ? true : 'Passwords does not match'),
        },
      }}
      type='password'
    />
  );
};

export default ConfirmPasswordInput;
