import {
  eventTicketTransferOpen,
  eventTicketTransferBookingId
} from '../reducers/EventReducers';

export const openTransferModal = (bookingId) => {
  return (dispatch) => {
    dispatch(eventTicketTransferOpen(true));
    dispatch(eventTicketTransferBookingId(bookingId));
  };
}