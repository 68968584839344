import { ROLE_ADMIN, ROLE_STUDENT, ROLE_NO_PROJECT, ROLE_FAIL } from '../actions/AccessActions';

const accessState = {
  routes: ['Login'],
  noProject: false,
  error: null,
};

export function roles(state = accessState, action) {
  switch (action.type) {
    case ROLE_ADMIN:
      return {
        routes: action.routes,
        noProject: false,
        error: null,
      };
    case ROLE_STUDENT:
      return {
        routes: action.routes,
        noProject: false,
        error: null,
      };
    case ROLE_NO_PROJECT:
      return {
        routes: action.routes,
        noProject: true,
        error: null,
      };
    case ROLE_FAIL:
      return {
        routes: action.routes,
        error: action.error,
        noProject: false,
      };
    default:
      return state;
  }
}
