/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Button, Snackbar } from '@material-ui/core/';
import MuiAlert from '@material-ui/lab/Alert';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import './createEvent.scss';

import * as Backend from '/data/api/BackendRequest.jsx';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const CreateEvent = ({ data, setData, settings, setSettings, getEvents, projectId }) => {
  const [error, setError] = useState({});
  const [open, setOpen] = useState(false);

  const [bookingStart, setBookingStart] = useState(null);

  useEffect(() => {
    Backend.request('get', `/user/project-info/${projectId}`, {}).then((response) =>
      setBookingStart(response.data.data.bookingStartDatetime)
    );
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSettings = (event) => {
    setSettings((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleData = (name, value) => {
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const dateChange = (date, dayChange) => {
    return new Date(date.getTime() + dayChange * (24 * 60 * 60 * 1000));
  };

  const setDefaultTimes = (date) => {
    const defaults = {
      endDatetime: data.endDatetime || dateChange(date, 0.5),
      bookingStartDatetime: data.bookingStartDatetime || bookingStart || dateChange(date, -14),
      bookingEndDatetime: data.bookingEndDatetime || dateChange(date, -1),
      unbookingEndDatetime: data.unbookingEndDatetime || dateChange(date, -1),
      swapEndDatetime: data.swapEndDatetime || dateChange(date, -1),
    };

    setData((prevState) => ({ ...prevState, ...defaults }));
  };

  const validate = () => {
    setError({});
    let noError = true;

    if (!data.displayTitle || !data.displayTitle.trim()) {
      setError((prevState) => ({
        ...prevState,
        displayTitle: 'Title cannot be empty',
      }));
      noError = false;
    }
    if (!data.description || !data.description.trim() || data.description.length < 8) {
      setError((prevState) => ({
        ...prevState,
        description: 'Description has to be longer than 8 characters',
      }));
      noError = false;
    }
    if (!data.startDatetime || data.startDatetime == 'Invalid Date') {
      setError((prevState) => ({
        ...prevState,
        startDatetime: 'Time is empty or invalid',
      }));
      noError = false;
    }
    if (!data.endDatetime || data.endDatetime == 'Invalid Date') {
      setError((prevState) => ({
        ...prevState,
        endDatetime: 'Time is empty or invalid',
      }));
      noError = false;
    }
    if (!data.attendanceLimit && !settings.attendanceLimit) {
      setError((prevState) => ({
        ...prevState,
        attendanceLimit: 'Cannot be empty, no limit?',
      }));
      noError = false;
    }
    if (data.attendanceLimit < 0 && !settings.basePriceSek) {
      setError((prevState) => ({
        ...prevState,
        attendanceLimit: 'Limit cannot be negative',
      }));
      noError = false;
    }
    if (!data.basePriceSek && !settings.basePriceSek) {
      setError((prevState) => ({
        ...prevState,
        basePriceSek: 'Cannot be empty, free?',
      }));
      noError = false;
    }
    if (data.basePriceSek < 0 && !settings.basePriceSek) {
      setError((prevState) => ({
        ...prevState,
        basePriceSek: 'Price cannot be negative',
      }));
      noError = false;
    }
    if (!data.location && !settings.location) {
      setError((prevState) => ({
        ...prevState,
        location: 'Location cannot be empty',
      }));
      noError = false;
    }

    if (!data.bookingStartDatetime || data.bookingStartDatetime == 'Invalid Date') {
      setError((prevState) => ({
        ...prevState,
        bookingStartDatetime: 'Time is empty or invalid',
      }));
      noError = false;
    }
    if (!data.bookingEndDatetime || data.bookingEndDatetime == 'Invalid Date') {
      setError((prevState) => ({
        ...prevState,
        bookingEndDatetime: 'Time is empty or invalid',
      }));
      noError = false;
    }
    if (!data.unbookingEndDatetime || data.unbookingEndDatetime == 'Invalid Date') {
      setError((prevState) => ({
        ...prevState,
        unbookingEndDatetime: 'Time is empty or invalid',
      }));
      noError = false;
    }
    if (!data.swapEndDatetime || data.swapEndDatetime == 'Invalid Date') {
      setError((prevState) => ({
        ...prevState,
        swapEndDatetime: 'Time is empty or invalid',
      }));
      noError = false;
    }

    return noError;
  };

  const Submit = () => {
    if (!validate()) {
      console.log('Error in validation');
      return;
    }

    if (settings.editing) {
      const sendData = {
        eventId: data.eventId,
        change: {
          ...data,
          attendanceLimit: settings.attendanceLimit ? 0 : parseInt(data.attendanceLimit, 10),
          basePriceSek: settings.basePriceSek ? 0 : parseInt(data.basePriceSek, 10),
        },
      };
      Backend.request('patch', `/event/${projectId}`, {}, sendData)
        .then((res) => {
          getEvents();
          setOpen('Success');
        })
        .catch(() => {
          setOpen('Error');
        });
    } else {
      const sendData = {
        event: {
          ...data,
          attendanceLimit: settings.attendanceLimit ? 0 : parseInt(data.attendanceLimit, 10),
          basePriceSek: settings.basePriceSek ? 0 : parseInt(data.basePriceSek, 10),
          projectId: projectId,
          eventGroups: eventGroup.map((v) => v.id),
        },
        eventFilters: eventFilter.map((v) => v.id),
      };
      Backend.request('post', `/event/${projectId}/create`, {}, sendData)
        .then((resp) => {
          getEvents();
          setOpen('Success');
        })
        .catch(() => {
          setOpen('Error');
        });
    }
  };

  useEffect(() => {
    if (settings.editing) {
      return;
    }

    Backend.request('get', `admin/${projectId}/event-filter`).then((response) => {
      const data = response.data.data;
      setEventFilters(data);
    });
  }, [settings.editing]);

  const [eventFilters, setEventFilters] = useState([]);
  const [eventGroups, setEventGroups] = useState([]);
  const [eventGroup, setEventGroup] = useState([]);
  const [eventFilter, setEventFilter] = useState([]);

  useEffect(() => {
    Backend.request('GET', `admin/${projectId}/event-group`)
      .then((res) => {
        let eventgroups = [];
        for (const [key, value] of Object.entries(res.data.data)) {
          eventgroups.push(value);
        }
        setEventGroups(eventgroups);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <h1>{settings.editing ? 'Edit event' : 'Create event'}</h1>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className='borderstyle'>
          <ExpansionPanel
            defaultExpanded
            style={{
              border: '#d52941',
              borderStyle: 'solid none none none',
              boxShadow: '0px 4px 6px 0px hsla(0, 0, 0, 0.2)',
            }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>General settings</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='container'>
                <p>
                  These settings are mandatory in order to create an event. You can use markdown tags to style the
                  description. The settings can be changed later as well.
                </p>
                {useMemo(
                  () => (
                    <div className='row'>
                      <TextField
                        value={data.displayTitle || ''}
                        name='displayTitle'
                        error={error.displayTitle != null}
                        helperText={error.displayTitle}
                        fullWidth
                        label='Title'
                        variant='outlined'
                        onChange={(event) => handleData(event.target.name, event.target.value)}
                      />
                    </div>
                  ),
                  [data.displayTitle, error.displayTitle]
                )}
                {useMemo(
                  () => (
                    <div className='row'>
                      <TextField
                        name='description'
                        onChange={(event) => handleData(event.target.name, event.target.value)}
                        error={error.description != null}
                        helperText={error.description}
                        rows={5}
                        multiline
                        fullWidth
                        label='Description'
                        variant='outlined'
                        value={data.description || ''}
                      />
                    </div>
                  ),
                  [data.description, error.description]
                )}
                {useMemo(
                  () => (
                    <div className='row'>
                      <KeyboardDateTimePicker
                        error={error.startDatetime != null}
                        helperText={error.startDatetime}
                        clearable
                        fullWidth
                        ampm={false}
                        format='yyyy/MM/dd HH:mm'
                        label='Start time'
                        inputVariant='outlined'
                        value={data['startDatetime'] || null}
                        onChange={(date) => {
                          handleData('startDatetime', date);
                        }}
                        onAccept={(date) => setDefaultTimes(date)}
                      />
                      <KeyboardDateTimePicker
                        error={error.endDatetime != null}
                        helperText={error.endDatetime}
                        fullWidth
                        clearable
                        ampm={false}
                        format='yyyy/MM/dd HH:mm'
                        label='End time'
                        inputVariant='outlined'
                        value={data['endDatetime'] || null}
                        onChange={(date) => handleData('endDatetime', date)}
                      />
                    </div>
                  ),
                  [error.endDatetime, data.endDatetime, error.startDatetime, data.startDatetime]
                )}
                {useMemo(
                  () => (
                    <div className='row'>
                      <TextField
                        error={error.attendanceLimit != null}
                        helperText={error.attendanceLimit}
                        fullWidth
                        name='attendanceLimit'
                        onChange={(event) => handleData(event.target.name, event.target.value)}
                        disabled={settings.attendanceLimit}
                        label='Attendance limit'
                        variant='outlined'
                        value={settings.attendanceLimit ? '' : data.attendanceLimit || ''}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={settings.attendanceLimit}
                            onChange={handleSettings}
                            name='attendanceLimit'
                            color='primary'
                          />
                        }
                        label='No limit'
                      />
                      <TextField
                        error={error.basePriceSek != null}
                        helperText={error.basePriceSek}
                        fullWidth
                        name='basePriceSek'
                        onChange={(event) => handleData(event.target.name, event.target.value)}
                        disabled={settings.basePriceSek}
                        label='Price'
                        variant='outlined'
                        value={data.basePriceSek || ''}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={settings.basePriceSek}
                            onChange={handleSettings}
                            name='basePriceSek'
                            color='primary'
                          />
                        }
                        label='Free'
                      />
                    </div>
                  ),
                  [
                    settings.basePriceSek,
                    data.basePriceSek,
                    error.basePriceSek,
                    settings.attendanceLimit,
                    data.attendanceLimit,
                    error.attendanceLimit,
                  ]
                )}
                {useMemo(
                  () => (
                    <div className='row'>
                      <TextField
                        error={error.location != null}
                        helperText={error.location}
                        name='location'
                        onChange={(event) => handleData(event.target.name, event.target.value)}
                        fullWidth
                        label='Location'
                        variant='outlined'
                        value={data.location || ''}
                      />
                    </div>
                  ),
                  [error.location, data.location]
                )}
                {useMemo(
                  () => (
                    <>
                      {!settings.editing && (
                        <>
                          <div className='row'>
                            <FormControl>
                              <InputLabel id='demo-mutiple-chip-label'>Event filters</InputLabel>
                              <Select
                                style={{
                                  minWidth: '300px',
                                  minHeight: '60px',
                                }}
                                labelId='demo-mutiple-chip-label'
                                id='demo-mutiple-chip'
                                multiple
                                value={eventFilter}
                                onChange={(event) => setEventFilter(event.target.value)}
                                input={<Input id='select-multiple-chip' />}
                                renderValue={(selected) => (
                                  <div>
                                    {selected.map((value) => (
                                      <Chip
                                        style={{
                                          margin: '5px',
                                        }}
                                        key={value}
                                        label={value.name}
                                      />
                                    ))}
                                  </div>
                                )}
                              >
                                {eventFilters.map((filter) => (
                                  <MenuItem key={filter.id} value={filter}>
                                    {filter.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className='row'>
                            <FormControl>
                              <InputLabel id='demo-mutiple-chip-label'>Event groups</InputLabel>
                              <Select
                                style={{
                                  minWidth: '300px',
                                  minHeight: '60px',
                                }}
                                labelId='demo-mutiple-chip-label'
                                id='demo-mutiple-chip'
                                multiple
                                value={eventGroup}
                                onChange={(event) => setEventGroup(event.target.value)}
                                input={<Input id='select-multiple-chip' />}
                                renderValue={(selected) => (
                                  <div>
                                    {selected.map((value) => (
                                      <Chip
                                        style={{
                                          margin: '5px',
                                        }}
                                        key={value}
                                        label={value.name}
                                      />
                                    ))}
                                  </div>
                                )}
                              >
                                {eventGroups.map((group, index) => (
                                  <MenuItem key={index} value={group}>
                                    {group.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </>
                      )}
                    </>
                  ),
                  [eventFilter, eventFilters, settings.editing, eventGroups, eventGroup]
                )}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            style={{
              border: '#d52941',
              borderStyle: 'solid none none none',
            }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>Booking settings</h3>
            </ExpansionPanelSummary>
            {useMemo(
              () => (
                <ExpansionPanelDetails>
                  <div className='container'>
                    <div className='row'>
                      <p>Booking settings for participants</p>
                    </div>
                    <div className='row'>
                      <KeyboardDateTimePicker
                        error={error.bookingStartDatetime != null}
                        helperText={error.bookingStartDatetime}
                        clearable
                        fullWidth
                        ampm={false}
                        format='yyyy/MM/dd HH:mm'
                        label='Booking start time'
                        inputVariant='outlined'
                        value={data['bookingStartDatetime'] || null}
                        onChange={(date) => handleData('bookingStartDatetime', date)}
                      />
                      <KeyboardDateTimePicker
                        error={error.bookingEndDatetime != null}
                        helperText={error.bookingEndDatetime}
                        fullWidth
                        clearable
                        ampm={false}
                        format='yyyy/MM/dd HH:mm'
                        label='Booking end time'
                        inputVariant='outlined'
                        value={data['bookingEndDatetime'] || null}
                        onChange={(date) => handleData('bookingEndDatetime', date)}
                      />
                    </div>
                    <div className='row'>
                      <KeyboardDateTimePicker
                        error={error.unbookingEndDatetime != null}
                        helperText={error.unbookingEndDatetime}
                        clearable
                        fullWidth
                        ampm={false}
                        format='yyyy/MM/dd HH:mm'
                        label='End time for unbooking'
                        inputVariant='outlined'
                        value={data['unbookingEndDatetime'] || null}
                        onChange={(date) => handleData('unbookingEndDatetime', date)}
                      />
                    </div>
                    <div className='row'>
                      <KeyboardDateTimePicker
                        error={error.swapEndDatetime != null}
                        helperText={error.swapEndDatetime}
                        fullWidth
                        clearable
                        ampm={false}
                        format='yyyy/MM/dd HH:mm'
                        label='End time for ticket swapping'
                        inputVariant='outlined'
                        value={data['swapEndDatetime'] || null}
                        onChange={(date) => handleData('swapEndDatetime', date)}
                      />
                    </div>
                  </div>
                </ExpansionPanelDetails>
              ),
              [
                data.categoryId,
                error.swapEndDatetime,
                error.unbookingEndDatetime,
                error.bookingEndDatetime,
                error.bookingStartDatetime,
                data.swapEndDatetime,
                data.unbookingEndDatetime,
                data.bookingEndDatetime,
                data.bookingStartDatetime,
              ]
            )}
          </ExpansionPanel>

          <ExpansionPanel disabled>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>Staff settings</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                blandit leo lobortis eget.
              </p>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel disabled>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>Event options</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                blandit leo lobortis eget.
              </p>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </MuiPickersUtilsProvider>

      <div className='button'>
        <Button
          color='primary'
          onClick={() => {
            setData({});
            setSettings({
              attendanceLimit: false,
              basePriceSek: false,
            });
          }}
        >
          {settings.editing ? 'Cancel edit' : 'Cancel'}
        </Button>
        <Button onClick={Submit} color='primary' variant='contained'>
          {settings.editing ? 'Save changes' : 'Create event'}
        </Button>
      </div>

      <Snackbar
        open={open == 'Success'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity='success'>
          Event successfully {settings.editing ? 'edited' : 'created'}!
        </Alert>
      </Snackbar>

      <Snackbar
        open={open == 'Error'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity='error'>
          Error {settings.editing ? 'editing' : 'creating new'} event!
        </Alert>
      </Snackbar>
    </div>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(CreateEvent);
