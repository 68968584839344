import React from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Theme from '/MuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  rootMobile: {
    margin: '0 16px',
    borderBottom: '1px solid #0000001E',
  },
  root: {
    height: '64px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowBackIcon: {
    width: '24px',
    height: '24px',
    color: t.palette.secondary.main,
  },
  button: {
    color: t.palette.secondary.main,
  },
  icon: {
    marginRight: '8px',
    padding: 0,
  },
}));

/**
 * Header for the event filter
 *
 * @param resetFilter function to reset the filters
 * @param closeOverlay closes the overlay
 * @param isMobile renders certain components based on if the boolean is true
 */
const EventFilterHeader = ({ resetFilter, closeOverlay, isMobile }) => {
  const classes = useStyles();

  return (
    <div className={isMobile ? `${classes.root} ${classes.rootMobile}` : classes.root}>
      <div className={classes.title}>
        {isMobile ? (
          <IconButton className={classes.icon} onClick={closeOverlay}>
            <ArrowBackIcon className={classes.arrowBackIcon} />
          </IconButton>
        ) : null}
        <h5>Filters</h5>
      </div>
      <div>
        <Button variant='text' color='secondary' onClick={resetFilter} className={classes.button}>
          reset
        </Button>
      </div>
    </div>
  );
};

export default EventFilterHeader;
