import React from 'react';
import Content from '/components/content/Content.jsx';
import Footer from '/components/footer/Footer.jsx';
import Typography from '@material-ui/core/Typography';

import FormLogo from '/components/formLogo/FormLogo.component';
import FormContainer from '/components/formContainer/FormContainer';
import { connect } from 'react-redux';

const SentForgot = (props) => {
  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />
          <h6 style={{ marginTop: '16px' }}>Thank you!</h6>

          <Typography variant='body2' style={{ height: '180px' }}>
            We have sent a link to reset your password to{' '}
            <span style={{ color: '#497aa4' }}>{props.forgottenEmail}</span>
          </Typography>
        </FormContainer>
      </Content>
      <Footer name='IR' />
    </>
  );
};

function mapState(state) {
  return {
    forgottenEmail: state.amnesia.forgottenEmail,
  };
}
export default connect(mapState, null)(SentForgot);
