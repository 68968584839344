import './sendEmail.scss';
import React, { useEffect } from 'react';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';

import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { createActions } from '/redux/actions/AuthActions.jsx';

const SendEmail = (props) => {
  const email = useSelector((state) => state.emailRetrival);

  useEffect(() => {
    props.sendEmail(email, props.projectId);
  }, []);
  // TODO: use snackbar or other feedback instead?
  return (
    <div id='box'>
      <Content size='small' className='createAccount-box'>
        {props.error ? (
          <div className='login-header'>
            <h1>ERROR!</h1>
            <h2>Unable to send confirmation email to {email}!</h2>
            <h3>Please contact THS International for information!</h3>
          </div>
        ) : (
          <div className='login-header'>
            <h1>OK!</h1>
            <h2>A confirmation email was sent to {email}</h2>
            <h3>Please check your inbox</h3>
          </div>
        )}
      </Content>
      <Footer small />
    </div>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

const actionCreators = {
  sendEmail: createActions.sendEmail,
};

const connectedSendEmailPage = connect(mapState, actionCreators)(SendEmail);
export { connectedSendEmailPage as SendEmail };
