import React from 'react';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  dividerText: {
    color: `${t.palette.surface.text}${t.palette.other.opacityMedium}`,
  },
  divider: {
    paddingBottom: '8px',
    [t.breakpoints.up('md')]: {
      paddingBottom: '16px',
    },
  },
}));

/**
 * Displays the divider between days of events
 * 
 * @param {Date} date the date to display
 * @return {JSX.Element} an event divider
 */
export const StartEventDivider = ({date}) => {
  const classes = useStyles();

  return (
    <div className={classes.divider}>
      <Typography className={classes.dividerText} style={{ textAlign: 'center' }}>
        {format(utcToZonedTime(new Date(date), 'Europe/Stockholm'), 'iiii, MMM do')}
      </Typography>
      <Divider />
    </div>
  );
}