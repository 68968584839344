import React from 'react';
import StudyTypeSelect from '/containers/StudyTypeSelect/StudyTypeSelect';
import StudyProgramSelect from '/containers/StudyProgramSelect/StudyProgramSelect';
/**
 * Renders the last page of register page
 */
const RegisterStepThree = ({ control, getValues, watch, studyInfo }) => {
  const watchStudyType = watch('studyType');

  return (
    <>
      <StudyTypeSelect control={control} studyInfo={studyInfo} />
      <StudyProgramSelect control={control} studyInfo={studyInfo} watchStudyType={watchStudyType} />
    </>
  );
};

export default RegisterStepThree;
