import React from 'react';
import SelectComponent from '/components/forms-v2/Select.component';

const NationalitySelect = ({ control, profileOptions }) => {
  return (
    <SelectComponent
      control={control}
      name='nationality'
      label='Nationality'
      rules={{
        required: { value: true, message: 'Choose a nationality' },
      }}
      options={profileOptions?.nationalities ? profileOptions.nationalities : []}
    />
  );
};

export default NationalitySelect;
