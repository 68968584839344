import React from 'react';
import Theme from '/MuiTheme';
import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const BookingsOptions = ({onClickPay, onClickTransfer, onClickDelete, selectAll, onClickSelectAll, isMobile, isSelectedActive, clickTransferEnabled}) => (
  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
    {isMobile && 
      <IconButton 
        label={'Select All'} 
        icon={
          <Checkbox 
            checked={selectAll} 
            onChange={onClickSelectAll} 
            style={{padding: '0px'}}

          />
        }
      />
    }
    <IconButton label={'Pay'} icon={<CreditCardIcon />} onClick={onClickPay} disabled={!isSelectedActive} />
    <IconButton label={'Transfer'} icon={<SwapHorizIcon />} onClick={onClickTransfer} disabled={!clickTransferEnabled} />
    <IconButton label={'Delete'} icon={<DeleteOutlineIcon />} onClick={onClickDelete} isLast={isMobile} disabled={!isSelectedActive} />
  </div>
);

const IconButton = ({icon, label, onClick, disabled, isLast}) => (
  <div 
    style={{
      display: 'flex',
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center', 
      marginRight: !isLast && '32px', 
      color: disabled ? `#000000${Theme.palette.other.opacityDisabled}` : Theme.palette.secondary.main,
      cursor: disabled ? null : 'pointer',
    }} 
    onClick={disabled ? () => null : onClick}
  >
    {icon}
    <Typography variant='caption'>{label}</Typography>
  </div>
);

export default BookingsOptions;