import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Content from '/components/content/Content';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TabBar from '/components/tabs/Tabs';
import Footer from '/components/footer/Footer';

import AboutUs from './aboutus/AboutUs';
import Terms from './terms/Terms';
import Privacy from './privacy/Privacy';
import Additional from './additional/Additional';

/**
 * About Us Page.
 * contains:
 *  - About Us - IT Team
 *  - Terms and conditions
 *  - Privacy policy
 *  - Additional communication
 */
const About = (props) => {
  const location = new useLocation();
  const [category, setCategory] = useState('#aboutus');

  // categories in about page.
  const categories = {
    'About Us': `/${props.projectId}/about#aboutus`,
    'Terms and Conditions': `/${props.projectId}/about#terms`,
    'Privacy Policy': `/${props.projectId}/about#privacy`,
    'Additional Communication': `/${props.projectId}/about#additional`,
  };

  // Get values from hash
  useEffect(() => {
    if (location.hash !== '') {
      setCategory(location.hash);
    } else {
      setCategory('#aboutus');
    }
  }, [location.hash]);

  /**
   * Render page based on category
   */
  const showCategory = () => {
    switch (category) {
      case '#aboutus':
        console.log('Render about us page');
        return <AboutUs />;
      case '#terms':
        console.log('Render terms and conditions page');
        return <Terms />;
      case '#privacy':
        console.log('Render privacy policy page');
        return <Privacy />;
      case '#additional':
        console.log('Render Additional communication page');
        return <Additional />;
    }
  };

  /* converting from object to a list of objects */
  const listItems = [];
  for (var key in categories) {
    if (categories.hasOwnProperty(key)) {
      listItems.push({
        label: key,
        link: categories[key],
      });
    }
  }

  return (
    <>
      <TabBar tablist={listItems} />

      <Content sidebar={useMediaQuery(Theme.breakpoints.up('md')) ? categories : null}>{showCategory()}</Content>

      <Footer name='IR' />
    </>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(About);
