import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((t, props) => ({
  backdrop: {
    zIndex: t.zIndex.drawer + 10,
  },
  modal: {
    overflow: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    [t.breakpoints.up('md')]: {
      maxHeight: '90%',
      alignItems: 'stretch',
      borderRadius: '5px',
    },
    [t.breakpoints.down('sm')]: {
      height: '100%',
      backgroundColor: '#ffffff',
    },
    scrollbarWidth: 'none',
  },
  overlay: {
    backgroundColor: '#fff',
    minWidth: '320px',
    maxWidth: '600px',
  },
  rootMobile: {
    margin: '0 16px',
    borderBottom: '1px solid #0000001E',
  },
  root: {
    height: '64px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  headerThick: {
    margin: '0px', 
    padding: '0px 16px', 
    backgroundColor: '#FAFAFA', 
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowBackIcon: {
    width: '24px',
    height: '24px',
    color: t.palette.secondary.main,
  },
  button: {
    color: t.palette.secondary.main,
  },
  icon: {
    marginRight: '8px',
    padding: 0,
  },
}));

// Header for filter modal
const ModalHeader = ({ resetFilter, closeOverlay, title, hideReset }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${classes.rootMobile}`}>
      <div className={classes.title}>
        <IconButton className={classes.icon} onClick={closeOverlay}>
          <ArrowBackIcon className={classes.arrowBackIcon} />
        </IconButton>
        <h5>{title}</h5>
      </div>
      {
        !hideReset && 
        <div>
          <Button variant='text' color='secondary' onClick={resetFilter} className={classes.button}>
            reset
          </Button>
        </div>
      }
    </div>
  );
};

// Thicker Modal Header
const ModalTwoHeader = ({ closeOverlay, title }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${classes.rootMobile} ${classes.headerThick}`} style={{height: '56px'}}>
      <div className={classes.title}>
        <IconButton className={classes.icon} onClick={closeOverlay} style={{marginRight: '32px'}}>
          <ArrowBackIcon className={classes.arrowBackIcon} />
        </IconButton>
        <Typography variant='h6' color='secondary'>{title}</Typography>
      </div>
    </div>
  );
};

// Subpage modal
const SubpageModal = ({
  title,
  hideHeader,
  hideReset,
  children,
  isOpen,
  reset,
  close,
  maxWidth,
  variant
}) => {

  const classes = useStyles();

  // Make modal lock the body
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'visible';
    return () => (document.body.style.overflow = 'visible');
  }, [isOpen]);

  // Which header to display
  const renderHeaderVariant = () => {
    switch(variant) {
      case 'thick':
        return <ModalTwoHeader closeOverlay={close} title={title} />;
      default:
        return <ModalHeader resetFilter={reset} closeOverlay={close} title={title} hideReset={hideReset} />;
    }
  }

  return(
    <>
      <Backdrop open={isOpen} className={classes.backdrop} onClick={close}>
        <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
          <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
            <Typography component={'span'}>
              <div className={classes.overlay} style={{maxWidth: maxWidth && `${maxWidth}px`}}>
                {!hideHeader && renderHeaderVariant()}
                <div style={{padding: '8px 16px'}}>
                  <div style={{width: '90vw'}} /> {/* This is to fix a bug in the modal */}
                  {children}
                </div>
              </div>
            </Typography>
          </div>
        </div>
      </Backdrop>
    </>
  );
}

export default SubpageModal;