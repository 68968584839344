import React from 'react';
import TextComponent from '/components/forms-v2/Text.component';

const SchoolSelect = ({ control }) => {
  return (
    <TextComponent name='school' control={control} label='School' disabled />
  );
};

export default SchoolSelect;
