import React, { useState, useEffect } from 'react';
import Content from '/components/content/Content.jsx';
import Footer from '/components/footer/Footer.jsx';
import * as Backend from '/data/api/BackendRequest.jsx';
import { useForm } from 'react-hook-form';
import PageOne from './pageOne/PageOne';
import PageTwo from './pageTwo/PageTwo';

import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';
import { projectActions } from '/redux/actions/ProjectActions.jsx';
import { accessActions } from '/redux/actions/AccessActions.jsx';
import { userActions } from '/redux/actions/AuthActions.jsx';

const WriteProjectInfo = (props) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [drinkData, setDrinkData] = useState([]);
  const [foodPrefs, setFoodPrefs] = useState([]);
  const [foodPrefsOther, setFoodPrefsOther] = useState([]);

  const { handleSubmit, control, setValue, getValues, watch } = useForm({ mode: 'all' });

  /**
   * get data from database
   */
  useEffect(() => {
    let isMounted = true;
    const proms = [];
    const projectId = props.projectId;
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/1`));
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/2`));
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/3`));

    Promise.all(proms)
      .then((res) => {
        if (isMounted) {
          // get drink, food, other data
          setDrinkData(res[0].data.data);
          // FIXME: remove TEMPORARY WORKAROUND FOR NON-PARTIAL ORDERING OF FOOD PREF.
          let option_two = res[1].data.data;
          option_two.unshift(option_two.pop())
          setFoodPrefs(option_two);
          setFoodPrefsOther(res[2].data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = (data) => {
    if (!currentPage) setCurrentPage(1);
    else {
      // arrival date
      let sendDataDate = {};
      sendDataDate = { ...sendDataDate, arrivalDate: data.arrivalDate };

      // drink Pref
      let drinkPref = [''];
      drinkPref[0] = data.drink;

      if (drinkPref[0] === '') {
        props.openFailure('Please select a drink preference');
        return;
      }

      // food pref
      let foodPref = [];
      const foodPrefInput = getValues(foodPrefs);
      foodPrefInput.forEach((pref, index) => {
        if (pref === true) foodPref.push(foodPrefs[index]);
      });

      if (!foodPref.length || !foodPref.some((fpref) => foodPrefs.slice(0, 6).includes(fpref))) {
        props.openFailure('Please select a food preference');
        return;
      }

      // save to backend
      const proms = [];
      const projectId = props.projectId;
      proms.push(
        Backend.request('post', `/user/${projectId}/food/1`, null, {
          foodPref: drinkPref,
        })
      );
      proms.push(
        Backend.request('post', `/user/${projectId}/food/2`, null, {
          foodPref: foodPref,
        })
      );
      proms.push(
        Backend.request('post', `/user/${projectId}/food/3`, null, {
          foodPref: data.otherInput === '' || !data.other ? [] : [data.otherInput],
        })
      );
      proms.push(
        Backend.request('post', `/user/assign/${projectId}`, null, {
          arrivalDate: data.arrivalDate,
          isTosAgreed: data.isTosAgreed,
        })
      );
      Promise.all(proms)
        .then(() => {
          const token = sessionStorage['token'];
          props.setRole(token);
          props.projects(token);
          props.redirect(`/${props.projectId}/events`); // change to projectId/events later on.
          history.push(`/${props.projectId}/events`);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status < 500) props.openFailure('' + err.response.data.error.message);
          else props.openFailure('Server error');
        });
    }
  };

  const whichPage = () => {
    switch (currentPage) {
      case 0:
        return <PageOne control={control} onSubmit={handleSubmit(onSubmit)} />;
      case 1:
        return (
          <PageTwo
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            watch={watch}
            setValue={setValue}
            drinkData={drinkData}
            foodPrefs={foodPrefs}
            foodPrefsOther={foodPrefsOther}
          />
        );
    }
  };

  return (
    <>
      <Content>{whichPage()}</Content>
      <Footer />
    </>
  );
};
function mapStateToProps(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

const mapDispatchToProps = {
  openSuccess: snackActions.successFeedback,
  openFailure: snackActions.errorFeedback,
  redirect: userActions.redirect,
  setRole: accessActions.setRole,
  projects: projectActions.projects,
};

export default connect(mapStateToProps, mapDispatchToProps)(WriteProjectInfo);
