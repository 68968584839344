import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex', 
    marginTop: '20px', 
    marginBottom: '22px',
  },
  container: {
    flex: 1,
  },
});

export const SubpageTitleText = ({
  isMobile = false,
  title = '',
  description = '',
  displayClose = false,
  closeModal = () => null,
}) => {
  const classes = useStyles();

  const headerStyle = () => {
    return isMobile ? 'h6' : 'h5';
  }

  const renderCloseButton = () => {
    if(!isMobile && displayClose) {
      return (
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      );
    }

    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant={headerStyle()}>{title}</Typography>
        <Typography variant='body2' color='textSecondary'>{description}</Typography>
      </div>
      {renderCloseButton()}
    </div>
  );
}