import Twemoji from 'twemoji';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  root: {
    '& img.emoji': {
      height: '1.25em',
      width: '1.25em',
      margin: '0 .05em 0 .1em',
      verticalAlign: '-0.1em',
    },
  },
}));

const Emojify = (props) => {
  const classes = useStyles();
  const [ref, _] = React.useState(React.createRef());
  React.useEffect(() => {
    Twemoji.parse(ref.current);
  }, [props.children]);
  return (
    <div ref={ref} className={classes.root} {...props}>
      {props.children}
    </div>
  );
};

export default Emojify;
