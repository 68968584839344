import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import './themeScheme.scss';

const ThemeScheme = ({ primaryColor, secondaryColor, setPrimaryColor, setSecondaryColor }) => {
  useEffect(() => {}, [primaryColor, secondaryColor]);

  return (
    <div>
      <div className='projectSettingsContainer'>
        <div className='themeContainer'>
          <TextField
            onChange={(event) => setPrimaryColor(event.target.value)}
            value={primaryColor}
            variant='outlined'
            label='Primary Color'
            disabled
          ></TextField>

          <span style={{ background: `${primaryColor}` }}></span>

          <TextField
            onChange={(event) => setSecondaryColor(event.target.value)}
            value={secondaryColor}
            variant='outlined'
            label='Secondary Color'
            disabled
          ></TextField>

          <span style={{ background: `${secondaryColor}` }}></span>
        </div>
      </div>
    </div>
  );
};

export default ThemeScheme;
