import React from 'react';
import { connect } from 'react-redux';

const AboutUs = ({ projectId }) => (
  <>
    <h4>
      About Us{' '}
      <a style={{ textDecoration: 'none', fontSize: '14px' }} href={`/${projectId}/pony`}>
        🦄
      </a>
    </h4>
    <p>Hej!&nbsp;</p>
    <p>
      If you&rsquo;re reading this you&rsquo;re currently using the event signup system developed by the IT team of THS
      International. THS (Tekniska H&ouml;gskolans Studentk&aring;r) is the student union of KTH and THS International
      is the part dedicated to promoting internationalization at the university. As a part of this, the THS
      International Reception welcomes new international and master students twice per year to Stockholm and KTH.
    </p>
    <p>
      Handling almost 3,000 students per year cannot be done with pen and paper. This is where this signup system comes
      into play. We have been developing it from scratch to really suit our needs. Since minute one we have been working
      on all tasks by ourselves, meaning everything from defining the scope of the system, designing the User Interface
      and building the servers was included in our work.&nbsp;
    </p>
    <p>
      This system has been used by the THS International Reception since 2020, but our plans go further than that.
      During the concept phase we realised that many organisations across KTH face the same problems as we do.
      Commercially available ticketing software is too expensive and too limited in features. Therefore, our vision is
      to make the system available for other organizations within THS as well. Our whole development process is based
      around this idea and we are counting the days until we can turn our vision into reality.&nbsp;
    </p>
    <p>
      Building such a state of the art system requires dedicated people too: Our team consists of students from various
      study fields with completely different backgrounds and we&rsquo;re working all year round on the system to make
      the event planning and sign up easier for both students and the organization team.&nbsp;
    </p>
    <p>
      You like our system and want to become a part of the team? Drop us an email at{' '}
      <a href='mailto:it@thsint.se'>it@thsint.se</a> and we will get back to you!
    </p>
  </>
);

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(AboutUs);
