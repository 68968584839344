import React, { useState } from 'react';
import { Card, CardContent, Collapse, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EventHeader } from './EventHeader';

const useStyles = makeStyles((t) => ({
  cardBase: {
    padding: (props) => props.isMobile ? '8px' : '8px 16px',
    marginBottom: (props) => props.isMobile ? '8px' : '16px',
  },
  disabled: {
    color: `${t.palette.surface.text}${t.palette.other.opacityDisabled}`,
  },
  active: {
    backgroundColor: `${t.palette.secondary.main}${t.palette.overlay.opacity.selected}`,
    color: `${t.palette.secondary.main}${t.palette.other.opacityHigh}`,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

/**
 * Displays an event card with customizable content
 * @param {boolean} isMobile if the card should be rendered in mobile mode
 * @param {boolean} disabled displays disabled card
 * @param {boolean} active displays an active card (secondary color) color scheme
 * @param {string} title the title of the event
 * @param {React.ReactNode} statistics the statistics of the event
 * @param {React.ReactNode} children the content of the event
 * @param {React.ReactNode} button non collapsable buttons
 * @returns {JSX} an event card
 */
export const EventCard = ({
  title = '',
  isMobile = false, 
  disabled = false, 
  active = true,
  statistics = <></>,
  children = <></>,
  button = <></>,
}) => {
  const classes = useStyles({isMobile});
  const [expanded, setExpanded] = useState(false);
  const handleExpanded = () => setExpanded(!expanded);

  const baseStyling = () => {
    if(disabled) return `${classes.cardBase} ${classes.disabled}`;
    if(active) return `${classes.cardBase} ${classes.active}`;
    return classes.cardBase;
  }

  return (
    <Card elevation={1} className={baseStyling()}>
      <CardContent className={classes.cardContent}>
        <EventHeader
          title={title}
          expanded={expanded}
          onClick={handleExpanded}
        />
        {statistics}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider style={{marginTop: '8px'}} />
          {children}
        </Collapse>
        {button}
      </CardContent>
    </Card>
  );
}