import React from 'react';

import RoleFilter from '/components/adminAccess/roleFilter/RoleFilter';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import CustomTableCell from '/components/adminAccess/customTableCell/CustomTableCell';
import { Typography } from '@material-ui/core';

const HeaderDesktop = ({roleSortToggle, roleSortUp}) => (
  <>
    <CustomTableCell><Typography variant='body1'>First Name</Typography></CustomTableCell>
    <CustomTableCell><Typography variant='body1'>Last Name</Typography></CustomTableCell>
    <CustomTableCell><Typography variant='body1'>E-mail</Typography></CustomTableCell>
    <CustomTableCell>
      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <RoleFilter />
        <span style={{marginTop: 2, marginLeft: '4px', marginRight: '4px'}}><Typography variant='body1'>Role</Typography></span>
        {!roleSortUp ? (
          <ArrowDownward 
            style={{width: 20, height: 20, opacity: 0.6, cursor: 'pointer'}} 
            onClick={roleSortToggle}
          />
        ) : (
          <ArrowUpward 
            style={{width: 20, height: 20, opacity: 0.6, cursor: 'pointer'}} 
            onClick={roleSortToggle}  
          />
        )}
      </div>
    </CustomTableCell>
    <CustomTableCell>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <Typography variant='body1'>Actions</Typography>
      </div>
    </CustomTableCell>
  </>
);

export default HeaderDesktop;