import React from 'react';
import SelectComponent from '/components/forms-v2/Select.component';

const GenderSelect = ({ control, profileOptions }) => {
  return (
    <SelectComponent
      control={control}
      name='sex'
      label='Gender'
      rules={{
        required: { value: true, message: 'Choose a gender' },
      }}
      options={profileOptions?.gender ? profileOptions.gender : []}
    />
  );
};

export default GenderSelect;
