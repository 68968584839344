import React from 'react';

import { Edit, DeleteOutline, Close } from '@material-ui/icons';
import { TableRow } from '@material-ui/core';
import Theme from '/MuiTheme';
import CustomTableCell from '/components/adminAccess/customTableCell/CustomTableCell';
import UserRoleMenu from '/components/adminAccess/userRoleMenu/UserRoleMenu';

const BodyDesktop = ({user, index, roles, setUserRole, setUserEditing, setIdToDelete, setDeleteDialogOpen}) => (
  <TableRow>
    <CustomTableCell>
      {user.firstName}
    </CustomTableCell>
    <CustomTableCell>{user.lastName}</CustomTableCell>
    <CustomTableCell>{user.email}</CustomTableCell>
    <CustomTableCell>
      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {roles.find((role) => role.roleId === user.roleId).title}
        {user.editing ? (
          <UserRoleMenu options={roles.map(role => ({
            label: role.title,
            onclick: () => {
              setUserRole(user.accountId, role.roleId);
            },
          }))} />
        ) : null}
      </div>
    </CustomTableCell>
    <CustomTableCell>
      {
        !user.editing ? (
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Edit 
              onClick={() => setUserEditing(user.accountId, true)}
              style={{width: 20, height: 20, color: Theme.palette.secondary.main, cursor: 'pointer'}}
            />
          </div>
        ) : (
          <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
            <DeleteOutline 
              // FIXME: Reverse this when delete is implemented
              // onClick={() => {
              //   setIdToDelete(user.accountId);
              //   setDeleteDialogOpen(true);
              // }}
              // FIXME: Change this to Theme.palette.secondary.main when delete is implemented
              style={{width: 20, height: 20, color: `${Theme.palette.surface.text}${Theme.palette.other.opacityDisabled}`}}
            />
            <Close 
              onClick={() => setUserEditing(user.accountId, false)}
              style={{width: 20, height: 20, color: Theme.palette.primary.main, cursor: 'pointer'}}
            />
          </div>
        )
      }
    </CustomTableCell>
  </TableRow>
);

export default BodyDesktop;