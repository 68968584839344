import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Controller } from 'react-hook-form';

/**
 * Renders a full-width Select component with options
 *
 * @param name the variable name
 * @param control control object from useForm
 * @param label label of the input
 * @param rules object of rules for RHF
 * @param options list of {value, label} that are available
 * @param disabled component in disabled mode or not
 */
const SelectComponent = ({ control, label, name, rules, options, disabled = false }) => (
  <Controller
    name={name}
    control={control}
    rules={rules ? rules : null}
    render={({ field: { value, ...rest }, fieldState: { error } }) => (
      <>
        <FormControl
          error={error ? true : false}
          fullWidth
          variant='outlined'
          style={{ marginTop: '16px', marginBottom: '16px' }}
          disabled={disabled}
          color='secondary'
        >
          <InputLabel>{label}</InputLabel>
          <Select label={label} value={value ? value : ''} color='secondary' {...rest}>
            {options.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
          {error ? <FormHelperText>{error.message}</FormHelperText> : null}
        </FormControl>
      </>
    )}
  />
);

export default SelectComponent;
