import { useDispatch, useSelector } from 'react-redux';
import { eventTicketTransferOpen } from '/redux/reducers/EventReducers';
import { RequestTicketTransfer } from '/data/Events/Data';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';
import { eventActions } from '/redux/actions/EventActions.jsx';

export const useTransferTicket = () => {
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.projectInfo.projectId);

  return (bookingId, lastName, email) => {
    RequestTicketTransfer(bookingId, lastName, email)
      .then(() => {
        dispatch(snackActions.successFeedback('Ticket successfully transferred'));
        dispatch(eventActions.getTicketsList(projectId));
        dispatch(eventTicketTransferOpen(false));
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback(err.response.data.error.message));
      });
  }
}