import React, { useState, useEffect } from 'react';

/**
 * Shows for the user in mobile where the user is.
 */
const MenuLocation = ({ routes }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    setCurrentlocation();
  }, [window.location.pathname, routes]);

  useEffect(() => {
    setCurrentlocation();
  }, []);

  const setCurrentlocation = () => {
    const currentLocation = window.location.pathname.split('/')[2]; // get current location
    let set = false;
    routes.forEach((route) => {
      if (route.name.toLowerCase() === currentLocation.toLowerCase()) {
        // passed routes
        set = true;
        setDisplayText(route.name);
      }

      switch (
        currentLocation.toLowerCase() // custom switch cases
      ) {
        case 'createaccount':
          setDisplayText('Register');
          set = true;
          break;
        case 'about':
          setDisplayText('About');
          set = true;
          break;
        case 'forgot':
        case 'reset':
        case 'sentforgot':
          setDisplayText('Forgot password');
          set = true;
          break;
        case 'projectinfo':
          setDisplayText('Project rules');
          set = true;
          break;
        case '':
          setDisplayText('Home');
          set = true;
          break;
      }
    });

    if (!set)
      // if nothing is found
      setDisplayText('');
  };

  return <h6 style={{ height: '94%', color: 'white', marginLeft: '32px', fontWeight: 400 }}>{displayText}</h6>;
};

export default MenuLocation;
