import React from 'react';
import Content from '/components/content/Content.jsx';
import Footer from '/components/footer/Footer';

/**
 * You're a clever coder aren't ya!
 * this is our precious...
 */
const Pony = () => (
  <>
    <Content>
      🦄 Neigh 🦄
      <iframe
        style={{ border: 'none', marginTop: '16px' }}
        src='https://www.youtube.com/embed/4uOKGPd3scY'
        width='100%'
        height='768px'
        allow='autoplay'
      />
    </Content>
    <Footer name='IR' />
  </>
);

export default Pony;
