import React from 'react';

import { Table, TableBody, TableHead, TableRow, TableContainer } from '@material-ui/core';
import HeaderContainer from '/containers/adminAccess/header/HeaderContainer';
import BodyContainer from '/containers/adminAccess/body/BodyContainer';

const AdminAccessTable = () => (
  <TableContainer style={{marginTop: '20px'}}>
    <Table size='small'>
      <TableHead>
        <TableRow>
          <HeaderContainer />
        </TableRow>
      </TableHead>
      <TableBody>
        <BodyContainer />
      </TableBody>
    </Table>
  </TableContainer>
);

export default AdminAccessTable;