import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import TextComponent from '/components/forms-v2/Text.component';
import Button from '@material-ui/core/Button';
import Content from '/components/content/Content.jsx';
import Footer from '/components/footer/Footer.jsx';
import FormContainer from '/components/formContainer/FormContainer';
import FormLogo from '/components/formLogo/FormLogo.component';

import { connect } from 'react-redux';
import { userActions } from '/redux/actions/AuthActions.jsx';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  loginButton: {
    marginBottom: '16px',
    marginTop: '16px',
    width: '100%',
  },
}));

/**
 * Renders Login Page
 *
 * From Redux:
 *  - loggedIn: check if user is logged in
 *  - error: error from logging in
 *  - projectInfo: project info
 *  - login: action to log in user (email, password)
 *  - redirect: redirects user,
 *  - logout: logout user,
 *  - openSuccess: open a success snackbar,
 *  - openFailure: opens failure snackbar,
 */
const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { handleSubmit, control, setError } = useForm({ mode: 'all' });

  /**
   * Logout user if the user comes to this page
   */
  useEffect(() => {
    props.logout();
  }, []);

  /**
   * Redirect user if student is already logged in
   * if user has signed project info, send to /events, or /projectInfo
   */
  useEffect(() => {
    let landingPage = `/`;
    if (props.loggedIn) {
      if (props.projectInfo?.signed) {
        landingPage = `/${props.projectId}/events`;
      } else {
        landingPage = `/${props.projectId}/projectInfo`;
      }

      props.redirect(landingPage);
      history.push(landingPage);
    }
  }, [props.projectInfo]);

  /**
   * check if any error has occured
   * set error for respective fields.
   * if there is a server error, just open a snackbar
   */
  useEffect(() => {
    if (props.error) {
      switch (props.error.response.data.error.message) {
        case 'There is no account with this email':
          setError('email', {
            type: 'authentication',
            message: props.error.response.data.error.message,
          });
          break;
        case 'The provided email is not confirmed':
          setError('email', {
            type: 'authentication',
            message: props.error.response.data.error.message,
          });
          break;
        case 'Invalid password':
          setError('password', {
            type: 'authentication',
            message: props.error.response.data.error.message,
          });
          break;
        default:
          props.openFailure(props.error.response.data.error.message);
          break;
      }
    }
  }, [props.error]);

  /**
   * Tries to log in the user with data provided
   * @param data data that gets passed from React Hook Form
   */
  const onSubmit = (data) => {
    props.login(data.email, data.password);
  };

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormLogo />

            <TextComponent
              name='email'
              control={control}
              label='E-mail'
              rules={{
                required: { value: true, message: 'Enter e-mail' },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid E-mail address',
                },
              }}
            />

            <TextComponent
              name='password'
              control={control}
              label='Password'
              type='password'
              rules={{
                required: { value: true, message: 'Enter password' },
              }}
            />

            <Button
              className={classes.loginButton}
              onClick={handleSubmit(onSubmit)}
              variant='contained'
              color='secondary'
              type='submit'
            >
              Login
            </Button>

            <Link to={`/${props.projectId}/forgot`} style={{ textDecoration: 'none' }}>
              <Typography variant='body1' color='secondary' style={{ marginBottom: 8 }}>
                Forgot Password?
              </Typography>
            </Link>
            <Link to={`/${props.projectId}/CreateAccount`} style={{ textDecoration: 'none' }}>
              <Typography variant='body1' color='secondary'>
                Don’t have an account? Register here
              </Typography>
            </Link>
          </form>
        </FormContainer>
      </Content>
      <Footer name='IR' />
    </>
  );
};

function mapState(state) {
  return {
    loggedIn: state.authentication.loggedIn,
    error: state.authentication.error,
    projectInfo: state.projectInfo,
    projectId: state.projectInfo.projectId,
  };
}

const actionCreators = {
  login: userActions.login,
  redirect: userActions.redirect,
  logout: userActions.logout,
  openSuccess: snackActions.successFeedback,
  openFailure: snackActions.errorFeedback,
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export { connectedLoginPage as Login };
