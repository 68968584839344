import {
  RequestProgrammes,
  RequestSignup,
  RequestConfirmEmail,
  RequestLogin,
  Logout,
  RequestForgot,
  RequestReset,
} from '../../data/api/AuthRequest';

import { store } from '../storeConfig';
import { accessActions } from './AccessActions.jsx';
import { projectActions } from './ProjectActions.jsx';
import { snackActions } from './FeedbackActions.jsx';

export const SIGNUP_INIT = 'SIGNUP_INIT';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_OK = 'SIGNUP_OK';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const GET_EMAIL = 'GET_EMAIL';
export const CONFIRM_SENT = 'CONFIRM_SENT';
export const CONFIRM_FAIL = 'CONFIRM_FAIL';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_OK = 'LOGIN_OK';
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT';
export const LOGOUT = 'LOGOUT';

export const SENDING_FORGOT = 'SENDING_FORGOT';
export const FORGOT_OK = 'FORGOT_OK';
export const FORGOT_FAIL = 'FORGOT_FAIL';
export const SENDING_RESET = 'SENDING_RESET';
export const RESET_OK = 'RESET_OK';
export const RESET_FAIL = 'RESET_FAIL';

export const createActions = {
  signupDetails,
  createAccount,
  sendEmail,
};

export const userActions = {
  login,
  redirect,
  loginRequest,
  loginOK,
  loginFAIL,
  logout,
  forgot,
  reset,
};

function signupDetails() {
  return (dispatch) => {
    RequestProgrammes()
      .then((resp) => {
        resp.data.data.sort((a, b) => a.nameEn.localeCompare(b.nameEn));
        const prog = resp.data.data;
        const types = resp.data.data
          .map((d) => d.typeEn)
          .filter((v, i, s) => s.indexOf(v) === i)
          .sort((a, b) => a.localeCompare(b));
        dispatch(signupINIT({ programmes: prog, types: types }));
      })
      .catch((err) => dispatch(signupFAIL(err)));
  };
}

function createAccount(user) {
  return (dispatch) => {
    dispatch(signupREQUEST(user));
    RequestSignup(user)
      .then((resp) => {
        dispatch(snackActions.successFeedback('Successfully created an account!'));
        dispatch(signupOK());
      })
      .catch((err) => {
        if (err.response.status < 500) dispatch(snackActions.errorFeedback('' + err.response.data.error.message));
        else dispatch(snackActions.errorFeedback('Server error'));
        dispatch(signupFAIL(err));
      });
  };
}

function sendEmail(email, pid) {
  return (dispatch) => {
    dispatch(getEmail(email));
    RequestConfirmEmail({ email: email }, pid)
      .then((resp) => dispatch(confirmSENT(email)))
      .catch((err) => dispatch(confirmFAIL(err)));
  };
}

function signupINIT(studyInfo) {
  return { type: SIGNUP_INIT, studyInfo: studyInfo };
}
function signupREQUEST() {
  return { type: SIGNUP_REQUEST };
}
function signupOK() {
  return { type: SIGNUP_OK };
}
function signupFAIL(err) {
  return { type: SIGNUP_FAIL, error: err };
}

function getEmail(email) {
  return { type: GET_EMAIL, email: email };
}

function confirmSENT(email) {
  return { type: CONFIRM_SENT, email: email };
}

function confirmFAIL(err) {
  return { type: CONFIRM_FAIL, error: err };
}

function login(email, password) {
  const appState = store.getState();

  return (dispatch) => {
    dispatch(loginRequest());
    RequestLogin(
      {
        email: email.toLowerCase(),
        password: password,
      },
      appState.projectInfo.projectId
    )
      .then((data) => {
        dispatch(projectActions.projects(data.token));
        dispatch(loginOK(data.token));
        dispatch(accessActions.setRole(data.token));
      })
      .catch((error) => {
        console.log(error.response.status);
        dispatch(loginFAIL(error));
      });
  };
}

function redirect(path) {
  return (dispatch) => {
    dispatch(loginREDIRECT(path));
  };
}

function logout() {
  return (dispatch) => {
    Logout();
    dispatch(logoutOK());
    dispatch(accessActions.resetRole('logging out'));
  };
}

function loginRequest() {
  return { type: LOGIN_REQUEST };
}
function loginOK(token) {
  return { type: LOGIN_OK, token: token };
}

function loginREDIRECT(path) {
  return { type: LOGIN_REDIRECT, data: path };
}

function loginFAIL(err) {
  return { type: LOGIN_FAIL, error: err };
}

function logoutOK() {
  return { type: LOGOUT };
}

function forgot(email, pid) {
  return (dispatch) => {
    dispatch(sendForgot());
    RequestForgot({ email: email }, pid)
      .then((token) => {
        dispatch(forgotOK(email));
      }) // I feel that we do not really care for the reset token.
      .catch((error) => {
        dispatch(forgotFAIL(error));
      });
  };
}

function reset(token, pwd) {
  return (dispatch) => {
    dispatch(sendReset());
    RequestReset(token, { password: pwd })
      .then((r) => {
        dispatch(resetOK());
      })
      .catch((error) => {
        dispatch(resetFAIL(error));
      });
  };
}

function sendForgot() {
  return { type: SENDING_FORGOT };
}

function forgotOK(email) {
  return { type: FORGOT_OK, email: email };
}

function forgotFAIL(err) {
  return { type: FORGOT_FAIL, error: err };
}

function sendReset() {
  return { type: SENDING_RESET };
}

function resetOK() {
  return { type: RESET_OK };
}

function resetFAIL(err) {
  return { type: RESET_FAIL, error: err };
}
