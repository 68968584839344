import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Content from '/components/content/Content';
import FormContainer from '/components/formContainer/FormContainer';
import Footer from '/components/footer/Footer';
import { createActions } from '/redux/actions/AuthActions.jsx';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { RequestSignup, RequestConfirmEmail } from '/data/api/AuthRequest';
import { getGeneralOptions, getStudyOptions } from '/redux/actions/OptionsActions';
import * as Backend from '/data/api/BackendRequest.jsx';
import FormLogo from '/components/formLogo/FormLogo.component';

import StepperComponent from '/components/forms-v2/Stepper.component';

import RegisterStepOne from './stepOne/StepOne';
import RegisterStepTwo from './stepTwo/StepTwo';
import RegisterStepThree from './stepThree/StepThree';
import StepCreated from './stepCreated/StepCreated';

const useStyles = makeStyles((t) => ({
  logoCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '14px',
    width: '95px',
  },
  textCenter: {
    textAlign: 'center',
    lineHeight: '28px',
  },
  generalInfo: {
    marginTop: '16px !important',
    marginBottom: '16px !important',
  },
}));

/**
 * Default values for first page
 */
const defaultValues = {
  firstName: '',
  lastName: '',
  sex: '',
  nationality: '',
  personNumber: '',
  phoneNumber: '',
  isMarketingAllowed: false,
  termsAgree: false,
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
  studyType: null,
  studyProgram: null,
};

/**
 * Title on register page depending on which step it is on!
 */
const pageTitle = (currentStep) => {
  switch (currentStep) {
    case 0:
      return 'General Information';
    case 1:
      return 'Account Information';
    case 2:
      return 'Study Information';
  }
};

const CreateAccount = (props) => {
  const {
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    watch,
    clearErrors,
    unregister,
  } = useForm({ mode: 'all', defaultValues: defaultValues });
  const [currentStep, setCurrentStep] = useState(0);
  const classes = useStyles();

  /**
   * Get study types and programs
   */
  useEffect(() => {
    if(!props.studyInfo) props.getStudyOptions();
    if(!props.profileOptions) props.getGeneralOptions();
  }, []);

  const onBack = (event) => {
    setCurrentStep(currentStep - 1);
    switch (currentStep) {
      case 1:
        clearErrors(['email', 'confirmEmail', 'confirmPassword', 'password']);
        unregister(['email', 'confirmEmail', 'confirmPassword', 'password']);
        break;
      case 2:
        clearErrors(['studyType', 'studyProgram']);
        unregister(['studyType', 'studyProgram']);
    }
  };

  /**
   * When NEXT button is clicked
   */
  const onSubmit = (data) => {
    if (currentStep < 2) setCurrentStep(currentStep + 1);
    else {
      // data to send to backend
      const creationData = {
        password: data.password,
        accountInfo: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          birthdayDate: new Date(
            data.birthdayDate.getTime() + Math.abs(data.birthdayDate.getTimezoneOffset() * 60000)
          ).toISOString(),
          sex: data.sex,
          nationality: data.nationality,
          personNumber: data.personNumber,
          phoneNumber: data.phoneNumber,
          arrivalDate: '2020-08-18T19:11:54.000Z',
          isMarketingAllowed: data.isMarketingAllowed,
          isTosAgreed: true,
        },
        studyInfo: {
          studyProgramId: data.studyProgram,
          firstSemester: '2020-09-01T00:00:00.000Z', // FIXME: we send placeholder semester info for now
          lastSemester: '2021-01-01T00:00:00.000Z', // FIXME: we send placeholder semester info for now
        },
      };

      // request signup -> send confirmation email -> goto success page
      RequestSignup(creationData)
        .then(() => {
          RequestConfirmEmail({ email: data.email }, props.projectId)
            .then(() => setCurrentStep(3))
            .catch((err) => {
              if (err.response.status < 500) props.openFailure('' + err.response.data.error.message);
              else props.openFailure('Server error');
            });
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status < 500) props.openFailure('' + err.response.data.error.message);
          else props.openFailure('Server error');
        });
    }
  };

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />
          {currentStep !== 3 ? (
            <>
              <h6 className={classes.generalInfo}>{pageTitle(currentStep)}</h6>
              {currentStep === 0 ? (
                <RegisterStepOne
                  control={control}
                  getValues={getValues}
                  profileOptions={props.profileOptions}
                  projectId={props.projectId}
                />
              ) : null}
              {currentStep === 1 ? <RegisterStepTwo control={control} getValues={getValues} /> : null}
              {currentStep === 2 ? (
                <RegisterStepThree control={control} getValues={getValues} watch={watch} studyInfo={props.studyInfo} />
              ) : null}

              <StepperComponent
                active={currentStep}
                stepAmount={3}
                handleNext={handleSubmit(onSubmit)}
                handleBack={onBack}
              />
            </>
          ) : (
            <StepCreated email={getValues('email')} />
          )}
        </FormContainer>
      </Content>
      <Footer name='IR' />
    </>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
    profileOptions: state.options.general,
    studyInfo: state.options.study,
  };
}

const actionCreators = {
  createAccount: createActions.createAccount,
  signupDetails: createActions.signupDetails,
  openSuccess: snackActions.successFeedback,
  openFailure: snackActions.errorFeedback,
  getGeneralOptions: getGeneralOptions,
  getStudyOptions: getStudyOptions,
};

const connectedCreateAccountPage = connect(mapState, actionCreators)(CreateAccount);
export { connectedCreateAccountPage as CreateAccount };
