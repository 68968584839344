import { createSlice } from '@reduxjs/toolkit';

import {
  IS_LOADING,
  EVENTS_RETRIVED,
  EVENTS_STATS_RETRIVED,
  TICKETS_RETRIVED,
  TICKET_REMOVED,
  EVENT_BOOKED,
  EVENT_ERROR,
  EVENT_FILTER,
  EVENT_OVERLAY,
  EVENT_SEARCH,
  EVENT_GROUPS,
} from '../actions/EventActions.jsx';

const initialState = {
  isLoading: true,
  data: [],
  error: null,
  tickets: [],
  snack: {},
  filter: {
    startDate: null,
    endDate: null,
    startPrice: null,
    endPrice: null,
    booked: false,
    bookable: false,
    closed: false,
    full: false,
    categories: [],
  },
  filterOverlay: false,
  filterSearch: '',
  eventGroups: null,
};

export function eventRetrival(state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true,
        bookedEvent: undefined,
        error: undefined,
        snack: {},
      };
    case EVENTS_RETRIVED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined,
        snack: {},
      };
    case EVENTS_STATS_RETRIVED:
      return {
        ...state,
        isLoading: false,
        stats: action.data,
        error: undefined,
        snack: {},
      };
    case TICKETS_RETRIVED:
      return {
        ...state,
        isLoading: false,
        tickets: action.data,
        error: undefined,
        snack: {},
      };
    case TICKET_REMOVED:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        snack: {},
      };
    case EVENT_BOOKED:
      return {
        ...state,
        isLoading: false,
        bookedEvent: action.data.data.booked.bookingId,
        error: undefined,
        snack: {
          open: true,
          context: (x) => `Success: A ticket for ${x} has been booked!`,
        },
      };
    case EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        snack: {
          open: true,
          context: (x) => `Error: ${x}`,
        },
      };
    case EVENT_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case EVENT_SEARCH:
      return {
        ...state,
        filterSearch: action.filterSearch,
      };
    case EVENT_OVERLAY:
      return {
        ...state,
        filterOverlay: action.overlay,
      };
    case EVENT_GROUPS:
      return {
        ...state,
        eventGroups: action.data,
      };
    default:
      return state;
  }
}

const eventTicketTransferSlice = createSlice({
  name: 'options',
  initialState: {
    isOpen: false,
    bookingId: null,
  },
  reducers: {
    eventTicketTransferOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    eventTicketTransferBookingId: (state, action) => {
      state.bookingId = action.payload;
    },
  },
});

export const {
  eventTicketTransferOpen,
  eventTicketTransferBookingId,
} = eventTicketTransferSlice.actions;
export const eventTicketTransfer = eventTicketTransferSlice.reducer;