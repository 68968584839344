import React, {useState} from 'react';
import SubpageModal from '/components/subpageModal/SubpageModal.component';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { eventTicketTransferOpen } from '/redux/reducers/EventReducers';
import { Divider } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import EmailInput from '/containers/EmailInput/EmailInput';
import LastNameInput from '/containers//LastNameInput/LastNameInput';
import ButtonComponent from '/components/forms-v2/Button.component';

import { SubpageTitleText } from '/components/subpageModal/SubpageTitleText';

import { Dialog } from '/components/dialog/Dialog.component';
import { useTransferTicket } from './useTransferTicket';

export const SendTicketContainer = () => {

  const dispatch = useDispatch();
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));
  const isOpen = useSelector(state => state.eventTicketTransfer.isOpen);
  const bookingId = useSelector(state => state.eventTicketTransfer.bookingId);
  const [dialogOpen, setDialogOpen] = useState(false);
  const transferTicket = useTransferTicket();
  const { handleSubmit, control, reset } = useForm({ mode: 'all' });

  const onSubmitCheck = (data) => {
    setDialogOpen(true);
  }

  const onSubmit = (data) => {
    const { lastName, email } = data;
    setDialogOpen(false);
    transferTicket(bookingId, lastName, email);
  }

  const closeModal = () => {
    reset();
    dispatch(eventTicketTransferOpen(false));
  }

  return(
    <SubpageModal
      hideHeader={!isMobile}
      title={'Ticket Transfer'}
      maxWidth={918}
      isOpen={isOpen}
      reset={() => null}
      close={closeModal}
      hideReset={true}
      stretchHeight={1000}
      variant='thick'
    >
      <SubpageTitleText 
        isMobile={isMobile}
        title={'Ticket Transfer'}
        description={'Transfer your ticket to another user and remove you as the ticket holder. This action cannot be undone.'}
        closeModal={closeModal}
        displayClose
      />

      {!isMobile && <Divider />}

      <SubpageTitleText 
        isMobile={isMobile}
        title={'Recipient'}
        description={`
          Please enter the information of the user you want to transfer the ticket to. 
          Note that the e-mail has to be the registered e-mail adress the user signed up with.
        `}
      />

      <LastNameInput control={control} />
      <EmailInput control={control} />
      <ButtonComponent click={handleSubmit(onSubmitCheck)} label={'Confirm'} fullWidth />
      <Dialog
        title={'Transfer Ticket?'}
        question={'I confirm the receiver has agreed on the transfer and I understand that this action cannot be undone.'}
        buttonLabel={'Confirm'}
        confirmOnClick={handleSubmit(onSubmit)}
        cancelOnClick={() => setDialogOpen(false)}
        isOpen={dialogOpen}
      />
    </SubpageModal>
  );
};