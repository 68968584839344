import React, { useState, useEffect, useRef } from 'react';
import DateComponent from '/components/forms-v2/Date.component';
import TextComponent from '/components/forms-v2/Text.component';
import CheckComponent from '/components/forms-v2/Check.component';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((t) => ({
  root: {
    padding: '8px 0px',
    backgroundColor: 'white',
    [t.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
  },
  interval: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  intervalWrap: {
    flexWrap: 'wrap',
  },
  leftInterval: {
    marginRight: '18px',
    width: '100%',
  },
  full: {
    width: '100%',
  },
  rightInterval: {
    marginLeft: '18px',
    width: '100%',
  },
}));

/**
 * EventFilter
 *
 * @param control control from useForm
 * @param status status checkboxes
 * @param category checkboxes
 * @param isMobile will render certain components if it's mobile
 * @param closeOverlay function to close the overlay
 */
const EventFilter = ({ control, status, category, isMobile, closeOverlay }) => {
  const classes = useStyles();
  const targetRef = useRef();
  const [width, setWidth] = useState(320);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(targetRef.current.offsetWidth || 320);
    };
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <>
      <div className={classes.root} ref={targetRef}>
        <h6>Date</h6>
        <div className={width >= 280 ? classes.interval : `${classes.interval} ${classes.intervalWrap}`}>
          <div className={width >= 280 ? classes.leftInterval : classes.full}>
            <DateComponent control={control} name='startDate' label='from' />
          </div>
          {width >= 280 ? <div>-</div> : null}
          <div className={width >= 280 ? classes.rightInterval : classes.full}>
            <DateComponent control={control} name='endDate' label='to' />
          </div>
        </div>

        <h6>Price</h6>
        <div className={width >= 280 ? classes.interval : `${classes.interval} ${classes.intervalWrap}`}>
          <div className={width >= 280 ? classes.leftInterval : classes.full}>
            <TextComponent control={control} name='startPrice' label='from' postfix={'SEK'} />
          </div>
          {width >= 280 ? <div>-</div> : null}
          <div className={width >= 280 ? classes.rightInterval : classes.full}>
            <TextComponent control={control} name='endPrice' label='to' postfix={'SEK'} />
          </div>
        </div>

        <div>
          <Typography variant='h6'>Status</Typography>
          {status.map((stat, index) => (
            <CheckComponent
              key={index}
              name={stat.value}
              control={control}
              label={
                <Typography variant='body2'>{stat.label.charAt(0).toUpperCase() + stat.label.slice(1)}</Typography>
              }
              noMargin
            />
          ))}
        </div>

        <div style={{ marginTop: '12px' }}>
          <Typography variant='h6'>
            Category{' '}
            <Typography variant='body2' component='span'>
              (booked/limit)
            </Typography>
          </Typography>
          {category.map((stat, index) => (
            <CheckComponent
              key={index}
              name={stat.value}
              control={control}
              label={
                <Typography variant='body2'>
                  {stat.label}{' '}
                  {stat.ticketLimit !== 0 ? (
                    <span style={{ opacity: 0.6 }}>
                      ({stat.current}/{stat.ticketLimit})
                    </span>
                  ) : null}
                </Typography>
              }
              noMargin
            />
          ))}
        </div>
        {isMobile ? (
          <Button
            color='secondary'
            variant='contained'
            fullWidth
            style={{ marginTop: '40px', marginBottom: '40px' }}
            onClick={closeOverlay}
          >
            Show events
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default EventFilter;
