import React, { useState } from 'react';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Divider, Typography } from '@material-ui/core';
import TabChip from '/components/tabChip/TabChip.component';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import InfoGeneral from '/components/adminUsers/InfoGeneral.component';

import { toggleUserCheckedIn } from '/redux/actions/AdminActions';

import { useSelector, useDispatch } from 'react-redux';

import FileCopyIcon from '@material-ui/icons/FileCopy';

import { snackActions } from '/redux/actions/FeedbackActions';

const useStyles = makeStyles((t) => ({
  chipContainer: {
    maxWidth: '90vw',
    display: 'inline-block',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    position: 'relative', 
    overflowX: 'scroll',
  },
  divider: {
    height: '8px', 
    marginTop: '16px', 
    marginBottom: '16px',
  },
  mainName: {
    marginBottom: '16px !important', 
    fontWeight: 400,
  },
  tabs: {
    minWidth: '370px',
    overflow: 'hidden',
  },

}));

// list of chips
const tabs = [
  {label: 'General', disabled: false},
  {label: 'Studies', disabled: false},
  {label: 'Preferences', disabled: false},
  {label: 'Notes', disabled: true},
];

/**
 * General Settings used in Users Overview
 */
const GeneralSettings = ({paramGeneralInfo, paramStudy, paramPreferences}) => {

  const classes = useStyles();
  const isMobile = useMediaQuery(Theme.breakpoints.down('xs'));
  const [category, setCategory] = useState(0);

  const dispatch = useDispatch();
  const generalInfo = paramGeneralInfo ? paramGeneralInfo : useSelector((state) => state.adminUsers.general);
  const checkedIn = useSelector((state) => state.adminUsers.checkedIn);
  const study = paramStudy ? paramStudy : useSelector((state) => state.adminUsers.study);
  const preferences = paramPreferences ? paramPreferences : useSelector((state) => state.adminUsers.preferences);

  /**
   * Copy to clipboard
   * @param {*} text text to copy
   */
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(snackActions.successFeedback('Copied to clipboard'));
  };

  /**
   * formats data that is passed in
   * 
   * @returns array of objects that formats data
   */
  const displayData = () => {

    if(!generalInfo || !study) // make sure there is data to display
      return [];

    const formattedGeneralInfo = {
      email: generalInfo.email || '',
      birthdayDate: generalInfo.birthdayDate.slice(0, 10) || '',
      age: Math.floor((new Date() - new Date(generalInfo.birthdayDate).getTime()) / 3.15576e+10),
      nationality: generalInfo.nationality || '',
      phoneNumber: generalInfo.phoneNumber ? `+${generalInfo.phoneNumber}` : '',
      personNumber: generalInfo.personNumber || '',
      sex: generalInfo.sex || '',
    };

    switch(category) {
      case 0: // general
        if(isMobile)
          return [
            {
              label: (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div>{formattedGeneralInfo.email}&nbsp;</div>
                  <FileCopyIcon style={{width: '16px', color: Theme.palette.secondary.main, cursor: 'pointer'}} onClick={() => copyToClipboard(formattedGeneralInfo.email)} />
                </div>
              ),
              size: 2,
            },
            {
              label: formattedGeneralInfo.birthdayDate,
              size: 1,
            },
            {
              label: `Age: ${formattedGeneralInfo.age}`,
              size: 1,
            },
            {
              label: formattedGeneralInfo.nationality,
              size: 1,
            },
            {
              label: formattedGeneralInfo.phoneNumber !== '' ? formattedGeneralInfo.phoneNumber : 'No phone number',
              size: 1,
            },
            {
              label: formattedGeneralInfo.personNumber !== '' ? formattedGeneralInfo.personNumber : 'No personal number',
              size: 1,
            },
            {
              label: formattedGeneralInfo.sex,
              size: 1,
            },
          ];
        return [
          {
            label: (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div>{formattedGeneralInfo.email}&nbsp;</div>
                <FileCopyIcon style={{width: '16px', color: Theme.palette.secondary.main, cursor: 'pointer'}} onClick={() => copyToClipboard(formattedGeneralInfo.email)} />
              </div>
            ) || '',
            size: isMobile ? 2 : 1,
          },
          {
            label: `${formattedGeneralInfo.birthdayDate} (Age: ${formattedGeneralInfo.age})`,
            size: 1,
          },
          {
            label: formattedGeneralInfo.nationality,
            size: 1,
          },
          {
            label: formattedGeneralInfo.phoneNumber !== '' ? formattedGeneralInfo.phoneNumber : 'No phone number',
            size: 1,
          },
          {
            label: formattedGeneralInfo.personNumber !== '' ? formattedGeneralInfo.personNumber : 'No personal number',
            size: 1,
          },
          {
            label: formattedGeneralInfo.sex,
            size: 1,
          },
        ];
      case 1: // studies
        return [
          {label: study.nameEn, size: 2},
          {label: study.abbreviation, size: 1},
          {label: study.typeEn, size: 1},
          {label: study.school, size: 1},
          {label: study.chapter, size: 1},
        ];
      case 2: // preferences
        const small = isMobile ? 2 : 1;
        const big = isMobile ? 2 : 3;
        return [
          {label: `Arrival Date: ${generalInfo.arrivalDate.split('T')[0]}`, size: small},
          {label: `Alcohol: ${preferences.drinking ? 'Yes' : 'No'}`, size: small},
          {label: `Food: ${preferences.foodPrefs.join(', ')}`, size: small},
          {label: `Allergies: ${preferences.allergies.concat(preferences.other).join(', ')}`, size: big},
        ];
      default:
        return [];
    }
  }

  if(!generalInfo || !study || !preferences) { // don't render anything if it's null!
    return <></>;
  }

  return(
    <>
      <Divider className={classes.divider} />

      <Typography variant='h6' className={classes.mainName}>{`${generalInfo.firstName} ${generalInfo.lastName}`}</Typography>

      <div className={classes.chipContainer}>
        <div className={classes.tabs}>
          {tabs.map((tab, i) => <TabChip key={i} label={tab.label} selected={i === category} style={{marginRight: '8px'}} onClick={() => setCategory(i)} disabled={tab.disabled} />)}
        </div>
      </div>
      
      <Divider style={{margin: isMobile ? '8px auto' : '12px auto'}} />

      <InfoGeneral
        entries={displayData()}
        rowSize={isMobile ? 2 : 3}
        isMobile={isMobile}
      />

      {isMobile ? <Divider style={{marginBottom: '16px'}} /> : <div style={{marginBottom: `${20 - 9}px`}} />}

      {
        !paramGeneralInfo && (
          <Button 
            variant='contained' 
            color='secondary' 
            style={{backgroundColor: checkedIn &&'#20B000', width: '130px'}}
            onClick={() => dispatch(toggleUserCheckedIn())}
          >
            {checkedIn ? 'Checked In' : 'Check In'}
          </Button>
        )
      }
    </>
  );
}

export default GeneralSettings;