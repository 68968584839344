import React from 'react';
import { TextField } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import './settings.scss';

const Settings = ({ settings, setSettings }) => {
  return (
    <div className='projectSettings'>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TextField
          variant='outlined'
          label='Name'
          value={settings.name || ''}
          onChange={(event) => {
            event.persist();
            setSettings((prev) => ({
              ...prev,
              name: event.target.value,
            }));
          }}
        />
        <TextField
          variant='outlined'
          rows={5}
          multiline
          label='Description'
          value={settings.description || ''}
          onChange={(event) => {
            event.persist();
            setSettings((prev) => ({
              ...prev,
              description: event.target.value,
            }));
          }}
        />

        <div className='eventSettings'>
          <KeyboardDateTimePicker
            clearable
            ampm={false}
            format='yyyy/MM/dd HH:mm'
            label='Events visible start time'
            inputVariant='outlined'
            value={settings.eventsVisibleStartDatetime || null}
            onChange={(date) => {
              setSettings((prev) => ({
                ...prev,
                eventsVisibleStartDatetime: date,
              }));
            }}
          />
          <KeyboardDateTimePicker
            clearable
            ampm={false}
            format='yyyy/MM/dd HH:mm'
            label='Booking start time'
            inputVariant='outlined'
            value={settings.bookingStartDatetime || null}
            onChange={(date) => {
              setSettings((prev) => ({
                ...prev,
                bookingStartDatetime: date,
              }));
            }}
          />
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default Settings;
