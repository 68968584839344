import React from 'react';
import { Chip } from "@material-ui/core";
import Theme from '/MuiTheme';

/**
 * displays a material ui style chip in secondary colors
 * 
 * @param label:string, text to be displayed
 * @param selected:boolean, if the chip is activated
 * @param style:object, custom styling for chip
 * @param onClick:function, function if chip gets clicked
 * @param disabled:boolean
 */
const TabChip = ({label, selected, style, onClick, disabled}) => {

  return(
    <Chip
      label={label}
      style={{
        color: Theme.palette.secondary.main,
        backgroundColor: `${Theme.palette.secondary.main}${selected ? Theme.palette.overlay.opacity.deactivated : Theme.palette.overlay.opacity.activated}`,
        border: selected ? `1px solid ${Theme.palette.secondary.main}` : '',
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

export default TabChip;