import React from 'react';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#750505',
      light: '#aa3d2e',
      dark: '#460000',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#104e75',
      light: '#497aa4',
      dark: '#002649',
      contrastText: '#ffffff',
    },
    error: {
      main: '#B00020',
      light: '#B00020',
      dark: '#B00020',
      contrastText: '#ffffff',
    },
    success: {
      main: '#20B000',
      light: '#20B000',
      dark: '#20B000',
      contrastText: '#000000',
    },
    warning: {
      main: '#F16F3A',
      light: '#F16F3A',
      dark: '#F16F3A',
      contrastText: '#000000',
    },
    info: {
      main: '#a4a4a4',
      light: '#a4a4a4',
      dark: '#a4a4a4',
      contrastText: '#000000',
    },
    neutral: {
      main: '#616161',
      light: '#8e8e8e',
      dark: '#373737',
      contrastText: '#ffffff',
    },
    background: {
      main: '#FFFFFF',
      text: '#000000',
    },
    surface: {
      main: '#FFFFFF',
      text: '#000000',
    },
    other: {
      opacityHigh: 'CC',
      opacityMedium: '99',
      opacityDisabled: '61',
    },
    overlay: {
      opacity: {
        hover: '0A',
        selected: '14',
        activated: '1E',
        deactivated: '4C',
        pressed: '1E',
        dragged: '14',
        enabled: '00',
      },
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        '& h1, & h2, & h3, & h4, & h5, & h6': {
          fontFamily: 'Open Sans',
          marginTop: 0,
          marginBottom: 0,
        },
        '& h1': {
          fontWeight: '300',
          fontSize: 95,
          letterSpacing: '-1.5px',
        },
        '& h2': {
          fontWeight: '300',
          fontSize: 59,
          letterSpacing: '-0.5px',
        },
        '& h3': {
          fontWeight: '400',
          fontSize: 48,
          letterSpacing: '0px',
        },
        '& h4': {
          fontWeight: '400',
          fontSize: 34,
          letterSpacing: '0.25px',
        },
        '& h5': {
          fontWeight: '400',
          fontSize: 24,
          letterSpacing: '0px',
        },
        '& h6': {
          fontWeight: '500',
          fontSize: 20,
          letterSpacing: '0.15px',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: 16,
    letterSpacing: '0.5px',
    subtitle1: {
      fontFamily: 'Open Sans',
      fontWeight: '400',
      fontSize: 16,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: 14,
      letterSpacing: '0.1px',
    },
    body1: {
      fontSize: 16,
      letterSpacing: '0.5px',
    },
    body2: {
      fontSize: 14,
      letterSpacing: '0.25px',
    },
    caption: {
      fontSize: 12,
      letterSpacing: '0.4px',
    },
    overline: {
      fontSize: 10,
      letterSpacing: '1.5px',
    },
    button: {
      fontWeight: '600',
      fontSize: 14,
      letterSpacing: '1.25px',
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 600,
      md: 960,
      lg: 1280,
    },
    margin: {
      xs: 16,
      sm: 16,
      md: 24,
      lg: 24,
    },
    gutter: {
      xs: 16,
      sm: 16,
      md: 24,
      lg: 24,
    },
  },
  spacing: 4,
});

export default theme;
