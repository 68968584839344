import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Content from '/components/content/Content';
import TabBar from '/components/tabs/Tabs';
import Footer from '/components/footer/Footer';
import FormContainer from '/components/formContainer/FormContainer';
import { connect } from 'react-redux';

import General from './general/General';
import Studies from './studies/Studies';
import Account from './account/Account';
import Preferences from './preferences/Preferences';

/**
 * Profile Page
 * contains:
 *  - General
 *  - Account
 *  - Studies
 *  - Preferences
 */
const Profile = (props) => {
  const location = new useLocation();
  const [category, setCategory] = useState('#general');

  // categories in about page.
  const categories = {
    General: `/${props.projectId}/profile#general`,
    Account: `/${props.projectId}/profile#account`,
    Studies: `/${props.projectId}/profile#studies`,
    Preferences: `/${props.projectId}/profile#prefs`,
  };

  // Get values from hash
  useEffect(() => {
    if (location.hash !== '') {
      setCategory(location.hash);
    } else {
      setCategory('#general');
    }
  }, [location.hash]);

  /**
   * Render page based on category
   */
  const showCategory = () => {
    switch (category) {
      case '#general':
        return <General />;
      case '#account':
        return <Account />;
      case '#studies':
        return <Studies />;
      case '#prefs':
        return <Preferences />;
    }
  };

  /* converting from object to a list of objects */
  const listItems = [];
  for (var key in categories) {
    if (categories.hasOwnProperty(key)) {
      listItems.push({
        label: key,
        link: categories[key],
      });
    }
  }

  return (
    <>
      <TabBar tablist={listItems} centeredUntil='xs' />
      <Content sidebar={categories}>
        <FormContainer>{showCategory()}</FormContainer>
      </Content>
      <Footer name='IR' />
    </>
  );
};

// Redux map/actions
function mapStateToProps(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}
export default connect(mapStateToProps)(Profile);
