import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Controller } from 'react-hook-form';

/**
 * Renders a full-width Check component
 *
 * @param name the variable name
 * @param control control object from useForm
 * @param label label of the input
 */
const CheckComponent = ({ name, control, label, rules, noMargin = false }) => (
  <Controller
    name={name}
    control={control}
    rules={rules ? rules : null}
    render={({ field, fieldState: { error } }) => (
      <FormControl style={{ display: 'block' }} error={error ? true : false}>
        <FormControlLabel
          control={
            <Checkbox
              style={noMargin ? { paddingTop: '2px', paddingBottom: '2px' } : null}
              checked={field.value ? field.value : false}
              onChange={(e) => field.onChange(e.target.checked)}
              inputProps={{ 'aria-label': label }}
            />
          }
          label={label}
          style={noMargin ? { width: '100%' } : { width: '100%', marginBottom: '16px', marginTop: '16px' }}
        />
        {rules ? (
          <FormHelperText style={error ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            {error ? error.message : 'none'}
          </FormHelperText>
        ) : null}
      </FormControl>
    )}
  />
);

export default CheckComponent;
