import './app.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Theme from '/MuiTheme';
import Home from '/pages/home/Home';
import { Events } from '/pages/events/Events';
import { Tickets } from '/pages/tickets/Tickets';
import { Login } from '/pages/auth/login/Login';
import { CreateAccount } from '/pages/auth/createAccount/CreateAccount';
import { SendEmail } from '/pages/auth/sendEmail/SendEmail';
import SentForgot from '/pages/auth/sentForgot/SentForgot';
import ConfirmEmail from '/pages/auth/confirmEmail/ConfirmEmail';
import LoginForgot from '/pages/auth/loginForgot/LoginForgot';
import { LoginReset } from '/pages/auth/loginReset/LoginReset';
import { Logout } from '/pages/auth/logout/Logout';
import WriteProjectInfo from '/pages/writeProjectInfo/WriteProjectInfo';
import Profile from '/pages/profile/Profile';
import About from '/pages/about/About';
import SnackbarComponent from '/components/snackBar/Snackbar.component.jsx';
import Admin from '/pages/admin/Admin';
import { Grid } from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import EventIcon from '@material-ui/icons/Event';
import Pony from '/pages/pony/Pony';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Menu } from '/components/navbar/Menu';
import { projectActions } from '/redux/actions/ProjectActions.jsx';
import { accessActions } from '/redux/actions/AccessActions.jsx';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import theme from '/MuiTheme';
import PatchEmail from '/pages/auth/patchEmail/PatchEmail';

const useStyles = makeStyles((t) => ({
  root: {
    margin: '0 auto',
    maxWidth: t.breakpoints.values.lg,
  },
}));

const routes = [
  {
    name: 'Home',
    route: <Home />,
    icon: <HomeIcon />,
    location: 'middle',
  },
  {
    name: 'Events',
    route: <Events />,
    icon: <EventIcon />,
    location: 'middle',
  },
  {
    name: 'Tickets',
    route: <Tickets />,
    icon: <ConfirmationNumberIcon />,
    location: 'middle',
  },
  {
    name: 'Admin',
    route: <Admin />,
    icon: <SettingsIcon />,
    location: 'right',
  },
  {
    name: 'Profile',
    route: <Profile />,
    icon: <AccountCircleIcon />,
    location: 'right',
  },
  {
    name: 'Login',
    route: <Login />,
    icon: <ExitToAppIcon />,
    location: 'right',
  },
  {
    name: 'Logout',
    route: <Logout />,
    icon: <ExitToAppIcon />,
    location: 'right',
  },
];

const App = (props) => {
  const classes = useStyles(theme);
  const [openDrawer, setDrawer] = useState(false);
  const [access, setAccess] = useState([]);

  const updateAccess = () => {
    setAccess(routes.filter((x) => props?.routes?.includes(x.name)));
  };

  useEffect(() => {
    updateAccess();
  }, [props.landingPage]);

  useEffect(() => {
    // get projectId
    props.projectSet(parseInt(window.location.pathname.split('/')[1]));

    const token = sessionStorage['token'];
    if (token) {
      props.setRole(token);
      props.projects(token);
    }
  }, []);

  useEffect(() => {
    if (props.landingPage === '') updateAccess();
  }, [props.routes]);

  const toggleDrawer = () => {
    setDrawer(!openDrawer);
  };

  return (
    <ThemeProvider theme={Theme}>
      <Typography component={'span'}>
        <SnackbarComponent />
        <Grid className={classes.root} container justifyContent='center'>
          <Router>
            <Menu openDrawer={openDrawer} toggleDrawer={toggleDrawer} routes={access} />
            <Switch>
              {access.map((r, i) => (
                <Route
                  key={i}
                  path={`/:projectId/${r.name.toLowerCase()}`}
                  children={<ProjectRender renderComponent={r.route} />}
                />
              ))}
              <Route path='/:projectId/forgot' children={<ProjectRender renderComponent={<LoginForgot />} />} />
              <Route path='/:projectId/reset' children={<ProjectRender renderComponent={<LoginReset />} />} />
              <Route
                path='/:projectId/createAccount'
                children={<ProjectRender renderComponent={<CreateAccount />} />}
              />
              <Route path='/:projectId/sendEmail' children={<ProjectRender renderComponent={<SendEmail />} />} />
              <Route path='/:projectId/sentForgot' children={<ProjectRender renderComponent={<SentForgot />} />} />
              <Route path='/:projectId/confirm' children={<ProjectRender renderComponent={<ConfirmEmail />} />} />
              <Route path='/:projectId/patchEmail' children={<ProjectRender renderComponent={<PatchEmail />} />} />
              <Route path='/:projectId/pony' children={<ProjectRender renderComponent={<Pony />} />} />
              <Route
                path='/:projectId/projectInfo'
                children={<ProjectRender renderComponent={<WriteProjectInfo />} />}
              />
              <Route path='/:projectId/about' children={<ProjectRender renderComponent={<About />} />} />
              <Route
                path='/:projectId/'
                children={
                  <ProjectRender
                    renderComponent={props.noProject ? <Redirect to={`/${props.projectId}/projectInfo`} /> : <Home />}
                  />
                }
              />
              <Route path='/'>
                <Redirect to='/4/' />
              </Route>
            </Switch>
          </Router>
        </Grid>
      </Typography>
    </ThemeProvider>
  );
};

/**
 * Renders page depending on if the project exists or not
 * To prevent wrong URLs.
 */
const ProjectRender = ({ renderComponent }) => {
  const history = useHistory();
  let { projectId } = useParams();
  let somethingWrong = false;

  /**
   * FIXME: get info from the backend later on
   */
  if (projectId !== '1' && projectId !== '2' && projectId !== '3' && projectId !== '4') {
    somethingWrong = true;
    history.push('/4/');
  }

  return <>{somethingWrong ? null : renderComponent}</>;
};

function mapState(state) {
  return {
    landingPage: state.authentication.landingPage,
    routes: state.roles.routes,
    projectId: state.projectInfo.projectId,
    noProject: state.roles.noProject,
  };
}

const actionCreators = {
  setRole: accessActions.setRole,
  projects: projectActions.projects,
  projectSet: projectActions.projectSET,
};

export default connect(mapState, actionCreators)(App);
