import React from 'react';
import CheckList from '/components/adminUsers/CheckList.component';
import { useSelector } from 'react-redux';

const TicketChecklistContainer = ({isMobile}) => {

  const responseData = useSelector(state => state.adminUsers.transferTicket.checks);

  const buildResponse = () => {

    if(responseData) {
      return [
        {
          success: responseData.isBeforeTransferTime.isOk,
          title: 'Transfer Ending Time',
          description: responseData.isBeforeTransferTime.isOk ? 'User can transfer their ticket' : 'User cannot transfer their ticket anymore',
        },
        {
          success: responseData.isOverlapping.isOk,
          title: 'Overlap',
          description: responseData.isOverlapping.isOk ? 'Event does not overlap with other booked events' : `Event overlaps with booked ${responseData.isOverlapping.details[0]} by the user`,
        },
        {
          success: responseData.isFiltered.isOk,
          title: 'Event Filter',
          description: responseData.isFiltered.isOk ? 'Event visible on booking page' : `${responseData.isFiltered.details[0]} prevents visibility for user`,
        },
        {
          success: responseData.isMaxGroups.isOk,
          title: 'Event Groups',
          description: responseData.isMaxGroups.isOk 
            ? 'No event group ticket limit for this event reached' : 
            `User has reached ticket limit for ${responseData.isMaxGroups.details[0]}`,
        },
      ];
    }

    return [];
  }

  return(
    <CheckList
      isMobile={isMobile}
      items={buildResponse()}
    />
  );
}

export default TicketChecklistContainer;