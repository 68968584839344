import React from 'react';
import DateComponent from '/components/forms-v2/Date.component';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  interval: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  intervalWrap: {
    flexWrap: 'wrap',
  },
  leftInterval: {
    marginRight: '18px',
    width: '100%',
  },
  full: {
    width: '100%',
  },
  rightInterval: {
    marginLeft: '18px',
    width: '100%',
  },
}));

const DateInterval = ({control}) => {
  const classes = useStyles();

  return(
    <>
      <div className={classes.interval}>
        <div className={classes.leftInterval}>
          <DateComponent control={control} name='startDate' label='from' />
        </div>
        <div>-</div>
        <div className={classes.rightInterval}>
          <DateComponent control={control} name='endDate' label='to' />
        </div>
      </div>
    </>
  );
}

export default DateInterval;