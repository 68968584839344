import React from 'react';

import { connect } from 'react-redux';

import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';

import { adminAccessDeleteDialogSet, deleteUser } from '/redux/actions/AdminActions';

/**
 * User delete modal
 */
const UserDeleteModal = ({open, setOpen, deleteUser}) => {

  const handleClose = () => {
    setOpen(false);
  }

  return(
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{width: '280px'}}>
          <DialogContentText>Delete this user?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>Cancel</Button>
          <Button color='secondary' onClick={() => {
            deleteUser();
            handleClose();
          }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapState = (state) => {
  return {
    open: state.adminAccess.deleteDialogOpen,
  };
}

const actions = {
  setOpen: adminAccessDeleteDialogSet,
  deleteUser: deleteUser,
};

export default connect(mapState, actions)(UserDeleteModal);