import './index.scss';
import React from 'react';
import { render } from 'react-dom';

import App from './app/App';

// Redux store
import { Provider } from 'react-redux';
import { store } from './redux/storeConfig.jsx';

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#root')
);
