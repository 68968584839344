import React from 'react';
import Content from '/components/content/Content.jsx';
import Footer from '/components/footer/Footer';
import Jumbotron from '/components/jumbotron/Jumbotron';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Home = () => {
  return (
    <>
      <Jumbotron />
      <Content>
        <h5 style={useMediaQuery(Theme.breakpoints.down('sm')) ? { marginTop: '16px' } : null}>Hej and välkommen!</h5>
        <p>
          We at the THS International Reception want to give you the warmest welcome at KTH, help you meet other
          students and find new friends. We, the THS International Reception, are a <b>student volunteer team</b> under
          Tekniska Högskolans Studentkår (THS, the Student Union at KTH) and together, we want to make Stockholm your
          home away from home!
        </p>
        <ul>
          <li>
            {' '}
            <b>Step 1.</b> Create an account by clicking the “Register” button at the top of this page.{' '}
          </li>
          <li>
            {' '}
            <b>Step 2.</b> Fill in your information on this aforementioned page.{' '}
          </li>
          <li>
            {' '}
            <b>Step 3.</b> Once the booking period opens, book for your events.{' '}
          </li>
          <li>
            {' '}
            <b>Step 4.</b> Attend a Check-In Desk on KTH main campus after arriving in Stockholm and collect your THS International Reception Wristband. You will not be able to attend any event if you do not have a wristband.{' '}
          </li>
          <li>
            {' '}
            <b>Step 5.</b> Receive more info from the Event Manager 1-2 days before the event via email. 
            Remember to check your spam folder!{' '}
          </li>
          <li>
            {' '}
            <b>Step 6.</b> Attend the event! Please note that you must be registered for the event and have collected the wristband to attend!{' '}
          </li>
        </ul>
        <p>
        Besides attending the activities, through registering to our Signup System, you will receive a Buddy, a student volunteer who will lead smaller group meetings with other students. They will be there to answer any questions. Please note that{' '}
          <b>o receive a Buddy from the THS International Reception, you must be registered to our Signup System</b>.
          Your Buddy will contact you closer to your arrival date in August.
        </p>
        <p>
        You don’t have to book any events to receive a Buddy, you simply need to register to the system. However, we recommend you to check out our wonderful events the whole Project Team has organized for you in the last couple of months.
        </p>
        <p>
          This Signup System is celebrating its 2-year anniversary! Therefore, there might still be some issues. If
          you run into problems, please contact us by email describing the exact issue that you have (and add a screenshot if
          possible).
        </p>
        <p>
          If you have any questions, please visit our{' '}
          <a target='_blank' rel='noopener noreferrer' href='https://www.thsint.se/howReceptionWorks '>
            webpage
          </a>{' '}
          or to contact us by <a href='mailto:info@thsint.se'>e-mail</a>.
        </p>
        <p>See you soon in Stockholm!</p>
        <p>The Project Team of the THS International Reception</p>
      </Content>

      <Footer name='IR' />
    </>
  );
};

export default Home;
