import React, { useState } from 'react';
import { TextField } from '@material-ui/core/';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Controller } from 'react-hook-form';

/**
 * returns prefix, suffix to input props for text component.
 *
 * @param data the current value
 * @param prefix the string value of prefix
 * @param postfix the string value of suffix
 * @param focused if the component is focused
 */
const buildPrefixSuffix = (data, prefix, postfix, focused) => {
  let prefixPostfix = {};

  if (prefix !== undefined && (focused || data)) {
    prefixPostfix = { ...prefixPostfix, startAdornment: <InputAdornment position='start'>{prefix}</InputAdornment> };
  }
  if (postfix !== undefined) {
    prefixPostfix = {
      ...prefixPostfix,
      endAdornment: <InputAdornment position='end'>{postfix}</InputAdornment>,
    };
  }

  return prefixPostfix;
};

/**
 * Renders a full-width text component
 *
 * required props:
 *  - name : variable name
 *  - control : control object from useForm
 *  - label : label of the input
 *
 * options:
 *  - rules : object of rules for RHF
 *  - prefix : renders a string before the input if it exists
 *  - postfix : renders a helper mark with the string provided when hovering
 *  - disabled : disabled or not
 *  - style : styling of component
 *  - helperText : a text under the input to be displayed permanent or not
 *  - small : if the text input should be small
 */
const TextComponent = ({
  name,
  control,
  rules,
  type,
  placeholder,
  style,
  variant,
  label,
  helperText,
  prefix,
  postfix,
  disabled,
  small,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules || null}
      render={({ field: { value, ...rest }, fieldState: { error } }) => (
        <TextField
          fullWidth
          type={type || 'text'}
          placeholder={placeholder || null}
          variant={variant || 'outlined'}
          value={value || ''}
          label={label}
          error={error ? true : false}
          helperText={error ? error.message : helperText || ''}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          style={style || { marginBottom: '16px', marginTop: '16px' }}
          color='secondary'
          InputProps={buildPrefixSuffix(value, prefix, postfix, focused)}
          disabled={disabled || false}
          inputProps={small ? { style: { fontSize: '14px' }, 'aria-label': label } : { 'aria-label': label }}
          {...rest}
        />
      )}
    />
  );
};

export default TextComponent;
