import React from 'react';
import { Typography } from '@material-ui/core';

/**
 * Displays an Icon and text pair
 * Props:
 *  - style : object, optional styling to object
 *  - spacing : number, spacing between text and icon in pixels
 *  - label : string,
 *  - icon : jsx
 */
const IconText = ({style, spacing, label, icon, isMobile}) => (
  <div style={{display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row', ...style}}>
    {icon}
    <Typography variant='body2' style={{marginLeft: !isMobile && `${spacing}px`}}>{label}</Typography>
  </div>
);

export default IconText;