import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  booked: {
    backgroundColor: t.palette.error.main,
    '&:hover': {
      backgroundColor: t.palette.error.dark,
    },
  },
}));

export const EventButton = ({
  booked = false,
  label = '',
  ...props
}) => {

  const classes = useStyles();

  return (
    <Button 
      color='secondary'
      className={booked ? classes.booked : null}
      variant='contained'
      {...props}
    >
      {label}
    </Button>
  );
}