import React from 'react';
import SelectComponent from '/components/forms-v2/Select.component';

const StudyTypeSelect = ({ control, studyInfo }) => {
  return (
    <SelectComponent
      control={control}
      name='studyType'
      label='Type of Study'
      rules={{
        required: { value: true, message: 'Enter Study Type' },
      }}
      options={studyInfo ? studyInfo.types.map((studyType, index) => ({ value: index + 1, label: studyType })) : []}
    />
  );
};

export default StudyTypeSelect;
