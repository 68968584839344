import React from 'react';
import EmailInput from '/containers/EmailInput/EmailInput';
import ConfirmEmailInput from '/containers/ConfirmEmailInput/ConfirmEmailInput';
import PasswordInput from '/containers/PasswordInput/PasswordInput';
import ConfirmPasswordInput from '/containers/ConfirmPasswordInput/ConfirmPasswordInput';

/**
 * Render Account Information for register page
 */
const RegisterStepTwo = ({ control, getValues }) => {
  return (
    <>
      <EmailInput control={control} />
      <ConfirmEmailInput control={control} getValues={getValues} />
      <PasswordInput control={control} />
      <ConfirmPasswordInput control={control} getValues={getValues} />
    </>
  );
};

export default RegisterStepTwo;
