import React, { useEffect } from 'react';
import { classifyEventList, filterEventList, getEventIdList } from '/utils/utils.jsx';
import { connect } from 'react-redux';
import { eventActions } from '/redux/actions/EventActions.jsx';
import Content from '/components/content/Content.jsx';
import Footer from '/components/footer/Footer.jsx';
import EventSearch from '/components/event/EventSearch';
import EventFilterController from '/components/eventFilter/EventFilterController';
import PaymentBox from '/components/paymentBox/PaymentBox.component';
import { TYPE, UserEventCard } from '/components/eventCard/UserEventCard';
import { StartEventDivider } from '/components/eventCard';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SendTicketContainer } from '/containers/tickets/SendTicket.container';
import { openTransferModal } from '/redux/actions/TransferActions';

/**
 * Renders the My Tickets page
 */
const Tickets = ({
  ticketRemove,
  tickets,
  getTicketsList,
  projectId,
  filter,
  filterSearch,
  eventState,
  eventGroups,
  getEventList,
  getEventGroups,
  openTransferModal,
}) => {
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));
  /**
   * Get all events and booked tickets
   */
  useEffect(() => {
    if (!eventState.data) getEventList(projectId);
    if (!eventGroups) getEventGroups(projectId);
    getTicketsList(projectId);
  }, []);

  /**
   * filtered events
   */
  const classified = classifyEventList(
    filterEventList(tickets.data, filter, getEventIdList(tickets.data), null, eventGroups || [], filterSearch || '')
  );

  /**
   * If user deletes a ticket
   */
  const handleDelete = (bookingId, e) => {
    e.stopPropagation();
    ticketRemove(bookingId, projectId);
  };

  // returns a list of categories that can go into the event
  const listOfCategories = (eventId) => {
    if (!eventGroups) return [];

    let categoryNames = [];
    eventGroups.forEach((eventGroup) => {
      if (eventGroup.eventIDs.includes(eventId)) categoryNames.push(eventGroup.name);
    });

    return categoryNames;
  };

  // calcInvoice calculates the total cost of paid/unpaid tickets.
  const calcInvoice = () => {
    var paidAmount = 0;
    var unpaidAmount = 0;
    if (tickets && tickets.data) {
      tickets.data.map((t) =>
        t.isPaid ? (paidAmount = paidAmount + t.basePriceSek) : (unpaidAmount = unpaidAmount + t.basePriceSek)
      );
    }
    return [paidAmount, unpaidAmount];
  };

  const invoice = calcInvoice();

  return (
    <>
      <Content
        header={tickets.data && tickets.data?.length > 0 ? <PaymentBox unpaidAmount={invoice[1]} /> : null}
        sidebarComponent={
          <EventFilterController events={eventState.data.data} tickets={eventState.tickets} eventGroups={eventGroups} />
        }
      >
        <EventSearch />
        <div>
          {classified.map((classifiedItem, i) => {
            const item = classifiedItem.data;
            return (
              <div key={i}>
              {classifiedItem.first && <StartEventDivider date={item.startDatetime} />}
                <UserEventCard
                  title={item.displayTitle}
                  isMobile={isMobile}
                  state={TYPE.TICKET}
                  location={item.location}
                  attendance={item.attendanceLimit}
                  price={item.basePriceSek}
                  startTime={new Date(item.startDatetime)}
                  endTime={new Date(item.endDatetime)}
                  transferEndTime={new Date(item.swapEndDatetime)}
                  description={item.description}
                  categories={listOfCategories(item.eventId)}
                  booking={item.booking}
                  handleButton={(e) => handleDelete(item.bookingId, e)}
                  handleTransfer={() => openTransferModal(item.bookingId)}
                  buttonDisabled={new Date() > new Date(Date.parse(item.unbookingEndDatetime))}
                />
              </div>
            );
          })}
        </div>
      </Content>
      <SendTicketContainer />
      <Footer name='IR' />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    eventState: state.eventRetrival,
    tickets: state.eventRetrival.tickets,
    projectId: state.projectInfo.projectId,
    filter: state.eventRetrival.filter,
    filterSearch: state.eventRetrival.filterSearch,
    eventGroups: state.eventRetrival.eventGroups,
  };
};

const actionCreators = {
  getEventList: eventActions.getEventList,
  getTicketsList: eventActions.getTicketsList,
  getEventGroups: eventActions.getEventGroups,
  ticketRemove: eventActions.removeTicket,
  openTransferModal: openTransferModal,
};

const connectedTicketsPage = connect(mapStateToProps, actionCreators)(Tickets);
export { connectedTicketsPage as Tickets };
