import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import SearchBar from '/components/searchBar/SearchBar';
import { adminAccessSearchValueSet } from '/redux/actions/AdminActions';

const SearchBarContainer = ({setValue, projectId}) => {

  useEffect(() => {
    setValue('');
  }, []);

  return(
    <>
      <SearchBar 
        setSelected={setValue}
        projectId={projectId}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    projectId: state.projectInfo.projectId,
  };
}

const actions = {
  setValue: adminAccessSearchValueSet,
};

export default connect(mapState, actions)(SearchBarContainer);