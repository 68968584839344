import React from 'react';
import TextComponent from '/components/forms-v2/Text.component';

const EmailInput = ({ control, name='email', label='E-mail' }) => {
  return (
    <TextComponent
      name={name}
      control={control}
      label={label}
      rules={{
        required: { value: true, message: 'Enter e-mail' },
        pattern: {
          // taken from https://emailregex.com/
          value:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
          message: 'Invalid E-mail address',
        },
        maxLength: { value: 128, message: 'Too long input' },
      }}
    />
  );
};

export default EmailInput;
