import React, { useEffect } from 'react';
import { Button, Typography, Divider, Collapse, Checkbox, IconButton, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SubpageModal from '/components/subpageModal/SubpageModal.component';
import GeneralSettings from './generalSettings/GeneralSettingsContainer';
import CheckOverview from '/components/adminUsers/CheckOverview.component';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChecklistContainer from './Checklist.container';

import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';

import { useSelector, useDispatch } from 'react-redux';

import { 
  addBooking,
  clearAddBooking,
  fetchEventList,
  fetchCheckEvent,
} from '/redux/actions/AdminActions';

import {
  adminUsersAddBookingDialogOpenSet as setIsOpen,
  adminUsersAddBookingDialogExpandedSet as setIsExpanded,
} from '/redux/reducers/AdminReducers';

const AddBookingContainer = () => {

  const dispatch = useDispatch();
  const userId = useSelector(state => state.adminUsers.searchValue);
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));

  const [isAttending, setIsAttending] = React.useState(false);
  const [isPaid, setIsPaid] = React.useState(false);

  const bookedEvents = useSelector(state => state.adminUsers.events);
  const bigState = useSelector(state => state.adminUsers.addBooking);

  useEffect(() => {
    dispatch(clearAddBooking());
  }, [userId]);

  useEffect(() => {
    dispatch(fetchEventList());
  }, []);

  const handleEventChange = (value) => {
    if(value) {
      dispatch(fetchCheckEvent(value));
    }
  };

  const handleSubmit = () => {
    dispatch(addBooking(bigState.selectedEvent.eventId, isPaid, isAttending));
  }

  const returnSuccessfullCount = () => {
    const responseData = bigState.responseData;
    if(!responseData)
      return 0;
    return responseData.isFull.isOk + responseData.isOverlapping.isOk + responseData.isFiltered.isOk + responseData.isMaxGroups.isOk;
  }

  const checkUserAlreadyBooked = () => {
    if(!bigState.selectedEvent.eventId) return true;
    return bookedEvents.some(event => event.eventId === bigState.selectedEvent.eventId);
  }

  const closeModal = () => {
    dispatch(setIsOpen(false));
    dispatch(clearAddBooking());
  }

  if(!userId) return <></>;

  return(
    <>
      <SubpageModal
        hideHeader={!isMobile}
        title={'Add Event to User'}
        maxWidth={918}
        isOpen={bigState.isOpen}
        reset={() => null} 
        close={closeModal}
        hideReset={true}
        stretchHeight={bigState.selectedEvent ? 1000 : 510}
        variant='thick'
      >
        <div style={{display: 'flex', marginTop: '20px', marginBottom: '22px'}}>
          <div style={{flex: 1}}>
            <Typography variant={isMobile ? 'h6' : 'h5'}>Add an event to user</Typography>
            <Typography variant='body2' color='textSecondary'>Search for an event and add it to an user.</Typography>
          </div>
          {!isMobile && <IconButton onClick={closeModal}><CloseIcon /></IconButton>}
        </div>
        <GeneralSettings />
        <Divider style={{marginTop: '16px', marginBottom: '8px'}} />
        <Typography variant='h6' style={{marginBottom: '8px'}}>Choose event</Typography>
        <Autocomplete
          id="combo-box-demo"
          value={bigState.selectedEvent || {displayTitle: ''}}
          options={bigState.eventList}
          getOptionLabel={(event) => event.displayTitle}
          onChange={(event, newInputValue) => {
            handleEventChange(newInputValue);
          }}
          renderInput={(params) => (
            <TextField 
              {...params} 
              label="Search event" 
              fullWidth 
              variant="outlined"
              color='secondary'
              />
            )
          }
        />
        <Divider style={{marginTop: '16px', marginBottom: '8px'}} />
        {
          bigState.selectedEvent && bigState.responseData &&
          <>
            <Typography variant='h6'>Event information</Typography>
            <Typography variant='body2' color='textSecondary'>Coming soon (TM)</Typography>
            <Divider style={{marginTop: '16px', marginBottom: '8px'}} />
            <CheckOverview 
              isMobile={isMobile}
              isLoading={false}
              success={returnSuccessfullCount() == 4}
              setExpanded={() => dispatch(setIsExpanded(!bigState.isExpanded))}
              isExpanded={bigState.isExpanded}
              description={`${returnSuccessfullCount()}/4 successful`}
            />
            <Collapse in={isMobile ? bigState.isExpanded : true} timeout="auto">
              {isMobile && <Divider style={{marginTop: '16px', marginBottom: '8px'}} />}
              <ChecklistContainer />
            </Collapse>
            <Divider style={{marginTop: '16px', marginBottom: '8px'}} />
            <Typography variant='h6'>Status</Typography>
            <FormControlLabel
              style={{display: 'block'}}
              control={<Checkbox checked={isPaid} name="checkedA" style={{padding: '4px 8px'}} onClick={() => setIsPaid(!isPaid)} />}
              label={<Typography variant='body2' style={{display: 'inline'}}>Mark as paid</Typography>}
            />
            <FormControlLabel
              style={{display: 'block'}}
              control={<Checkbox checked={isAttending} name="checkedA" style={{padding: '4px 8px'}} onClick={() => setIsAttending(!isAttending)} />}
              label={<Typography variant='body2' style={{display: 'inline'}}>Mark as attending</Typography>}
            />
            <Button
              fullWidth
              onClick={handleSubmit}
              color='secondary' 
              variant='contained'
              disabled={checkUserAlreadyBooked()}
              style={{marginTop: '16px', marginBottom: '25px'}}
            >
              {checkUserAlreadyBooked() ? 'User already booked' : 'Confirm'}
            </Button>
          </>
        }
      </SubpageModal>
    </>
  );
}

export default AddBookingContainer;