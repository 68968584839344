import React from 'react';
import CheckComponent from '/components/forms-v2/Check.component';
import Typography from '@material-ui/core/Typography';

const CommunicationCheckbox = ({ control, projectId }) => {
  return (
    <CheckComponent
      name='isMarketingAllowed'
      control={control}
      noMargin
      label={
        <>
          <Typography variant='body2'>
            I want to receive&nbsp;
            <a target='_blank' rel='noopener noreferrer' href={`/${projectId}/about#additional`}>
              additional communication
            </a>
          </Typography>
        </>
      }
    />
  );
};

export default CommunicationCheckbox;
