import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import './eventGroup.scss';
import {
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Switch,
} from '@material-ui/core';
import * as Backend from '/data/api/BackendRequest.jsx';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { MenuItem } from '@material-ui/core/';

const EventGroup = ({ events, projectId }) => {
  const [displayEvents, setDisplayEvents] = useState([]);

  useEffect(() => {
    const eventsClone = [...events];
    setDisplayEvents(eventsClone);
  }, [events]);

  const postGroup = (name, eventIDs, ticketLimit, isBroadcasted) => {
    Backend.request(
      'post',
      `admin/${projectId}/event-group`,
      {},
      { name, eventIDs, ticketLimit: parseInt(noLimit ? '0' : ticketLimit, 10), isBroadcasted }
    ).then(() => {
      Backend.request('get', `admin/${projectId}/event-group`).then((response) => {
        const data = response.data.data;
        setGroups(Object.values(data));
        if (data.length > 0) {
          updateCurrent(data[0]);
        }
      });
    });
  };

  const patchGroup = (name, eventIDs, ticketLimit, isBroadcasted) => {
    Backend.request(
      'patch',
      `admin/${projectId}/event-group/${group}`,
      {},
      { name, eventIDs, ticketLimit: parseInt(noLimit ? '0' : ticketLimit, 10), isBroadcasted }
    ).then((response) => {});
  };

  const cancelChanges = () => {
    let changeBackToId;
    if (groups.length > 0) {
      if (group !== -1) {
        changeBackToId = group;
      }
    }

    Backend.request('get', `admin/${projectId}/event-group`).then((response) => {
      const data = response.data.data;
      setGroups(data);
      if (changeBackToId != null) {
        Object.values(data).forEach((f) => {
          if (f.id === changeBackToId) {
            updateCurrent(f);
          }
        });
      } else if (data.length > 0) {
        updateCurrent(data[0]);
      }
    });
  };

  const deleteGroup = (id) => {
    Backend.request('delete', `admin/${projectId}/event-group/${id}`, {}, {}).then(() => {});
  };

  const newGroup = () => {
    setGroup(-1);
    setGroupEvents([]);
    setTicketLimit('');
    setGroupName('');
    setIsBroadcasted(false);
    setNoLimit(false);
  };

  const updateCurrent = (groupUpdate) => {
    setGroup(groupUpdate.id);
    setGroupEvents(groupUpdate.eventIDs);
    setGroupName(groupUpdate.name);
    setTicketLimit(groupUpdate.ticketLimit);
    setIsBroadcasted(groupUpdate.isBroadcasted);
    groupUpdate.ticketLimit === 0 ? setNoLimit(true) : setNoLimit(false);
  };

  const [noLimit, setNoLimit] = useState(false);
  const [ticketLimit, setTicketLimit] = useState([]); //Stores a numerical limit
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(null);
  const [groupEvents, setGroupEvents] = useState([]);
  const [isBroadcasted, setIsBroadcasted] = React.useState(false);
  const [groupName, setGroupName] = useState('');
  const [error, setError] = useState({});

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    Backend.request('get', `admin/${projectId}/event-group`).then((response) => {
      const data = response.data.data;

      setGroups(Object.values(data));
      if (data.length > 0) {
        updateCurrent(data[0]);
      }
    });
  }, []);

  const handleSetNoLimit = (event) => {
    setNoLimit(event.target.checked);
  };

  const validate = () => {
    setError({});

    if (!groupName || !groupName.trim()) {
      setError((prevState) => ({
        ...prevState,
        groupName: 'Name cannot be empty',
      }));
      return false;
    }
    if (!ticketLimit && !noLimit) {
      setError((prevState) => ({
        ...prevState,
        ticketLimit: 'Ticket limit cannot be empty, no limit?',
      }));
      return false;
    }
    if (ticketLimit < 0) {
      setError((prevState) => ({
        ...prevState,
        ticketLimit: 'Ticket limit cannot be negative',
      }));
      return false;
    }

    return true;
  };

  const Submit = () => {
    if (!validate()) {
      return;
    }
    if (group === -1) {
      postGroup(groupName, groupEvents, ticketLimit, isBroadcasted);
    } else {
      patchGroup(groupName, groupEvents, ticketLimit, isBroadcasted);
    }
  };

  return (
    <div className='grouppageContainer'>
      <h2>Event Groups</h2>

      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <FormControl fullWidth variant='outlined' id='sex'>
          <InputLabel ref={inputLabel} id='select-sex'>
            Group
          </InputLabel>
          <Select
            style={{ width: '60%' }}
            labelId='groups'
            id='groups'
            value={group || ''}
            onChange={(event) => {
              event.persist();
              setGroup(event.target.value);
              Object.values(groups).forEach((f) => {
                if (f.id === event.target.value) {
                  updateCurrent(f);
                }
              });
            }}
            labelWidth={labelWidth}
          >
            {Object.values(groups).map((f) => (
              <MenuItem key={f.id} value={f.id}>
                {f.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          disabled={group === -1}
          onClick={() => deleteGroup(group)}
          style={{
            width: '200px',
            height: '50px',
            marginRight: '30px',
          }}
          color='primary'
          variant='outlined'
        >
          Delete group
        </Button>
        <Button
          disabled={group === -1}
          onClick={() => newGroup()}
          style={{ width: '200px', height: '50px' }}
          color='primary'
          variant='contained'
        >
          Create new
        </Button>
      </div>

      {useMemo(
        () => (
          <TextField
            size='small'
            style={{ width: '200px' }}
            label='Name'
            variant='outlined'
            value={groupName || ''}
            name='groupName'
            error={error.groupName != null}
            helperText={error.groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        ),
        [groupName, error.groupName]
      )}

      <div
        style={{
          position: 'relative',
          height: '350px',
          margin: '60px 0px',
        }}
      >
        <div className='subtitle'>Applied events</div>
        <Paper
          style={{
            maxHeight: '100%',
            overflow: 'auto',
            borderRadius: '6px',
          }}
        >
          <div style={{ padding: '10px' }}></div>
          <div className='alternatives'>
            {useMemo(() => {
              displayEvents?.sort((a, b) => {
                if (groupEvents.includes(b.eventId) && !groupEvents.includes(a.eventId)) {
                  return 1;
                }
                if (!groupEvents.includes(b.eventId) && groupEvents.includes(a.eventId)) {
                  return -1;
                }

                if (a.displayTitle < b.displayTitle) {
                  return -1;
                }

                if (a.displayTitle > b.displayTitle) {
                  return 1;
                }

                return 0;
              });

              return displayEvents?.map((e) => {
                return (
                  <EventBox
                    key={e.eventId}
                    id={e.eventId}
                    groupEvents={groupEvents}
                    setGroupEvents={setGroupEvents}
                    title={e.displayTitle}
                  />
                );
              });
            }, [groupEvents, displayEvents])}
          </div>
        </Paper>
      </div>

      <div>
        <div className='subtitle'>Ticket Limit</div>
        {useMemo(
          () => (
            <>
              <div className='row'>
                <TextField
                  error={error.ticketLimit != null}
                  helperText={error.ticketLimit}
                  name='ticketLimit'
                  onChange={(e) => setTicketLimit(e.target.value)}
                  disabled={noLimit}
                  label='Ticket Limit'
                  variant='outlined'
                  value={noLimit ? '' : ticketLimit || ''}
                />
                {
                  <FormControlLabel
                    control={
                      <Switch
                        defaultValue={false}
                        checked={noLimit}
                        onChange={handleSetNoLimit}
                        name='ticketLimit'
                        color='primary'
                      />
                    }
                    label='No limit'
                  />
                }
              </div>

              <div className='subtitle'>Show Group to Users</div>
              <div className='row'>
                <FormControlLabel
                  control={
                    <Switch
                      defaultValue={false}
                      checked={isBroadcasted}
                      onChange={(e) => setIsBroadcasted(e.target.checked)}
                      name='isBroadcasted'
                      color='primary'
                    />
                  }
                  label='Yes'
                />
              </div>
            </>
          ),
          [ticketLimit, noLimit, error.ticketLimit, isBroadcasted]
        )}
      </div>

      <div className='buttonGroup'>
        <Button onClick={() => cancelChanges()} color='primary'>
          Cancel
        </Button>
        <Button onClick={Submit} variant='contained' color='primary'>
          Save changes
        </Button>
      </div>
    </div>
  );
};

const EventBox = ({ id, setGroupEvents, groupEvents, title }) => {
  return (
    <div>
      <FormControlLabel
        checked={groupEvents.includes(id)}
        control={
          <Checkbox
            onClick={() => {
              if (groupEvents.includes(id)) {
                setGroupEvents((prev) => prev.filter((e) => e !== id));
              } else {
                setGroupEvents((prev) => [...prev, id]);
              }
            }}
            style={{ padding: '0 5px', margin: 0 }}
            size='small'
          />
        }
        label={title}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(EventGroup);
