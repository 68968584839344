import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Backend from '/data/api/BackendRequest.jsx';
import ButtonComponent from '/components/forms-v2/Button.component';
import { connect } from 'react-redux';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';
import { getStudyOptions } from '/redux/actions/OptionsActions';
import StudyTypeSelect from '/containers/StudyTypeSelect/StudyTypeSelect';
import StudyProgramSelect from '/containers/StudyProgramSelect/StudyProgramSelect';
import ProgramCodeSelect from '/containers/ProgramCodeSelect/ProgramCodeSelect';
import SchoolSelect from '/containers/SchoolSelect/SchoolSelect';
import ChapterSelect from '/containers/ChapterSelect/ChapterSelect';

const Studies = (props) => {
  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    watch,
    control,
    reset,
  } = useForm({ mode: 'all' });

  const watchStudyType = watch('studyType');
  const watchStudyProgram = watch('studyProgram');

  useEffect(() => {
    setValue('programCode', '');
    setValue('school', '');
    setValue('chapter', '');
  }, [watchStudyType]);

  useEffect(() => {
    if (props.studyInfo) {
      const chosenProgram = props.studyInfo.find(
        (studyProgram) => studyProgram.studyProgramId === getValues('studyProgram')
      );
      if (chosenProgram) {
        if (chosenProgram.abbreviation !== '') setValue('programCode', chosenProgram.abbreviation);
        else setValue('programCode', 'N/A');
        setValue('school', chosenProgram.school);
        setValue('chapter', chosenProgram.chapter);
      }
    }
  }, [watchStudyProgram]);

  useEffect(() => {
    if (!props.studyInfo) props.getStudyOptions();
  }, []);

  useEffect(() => {
    if (!props.studyInfo) return;

    // get users study info
    Backend.request('GET', '/user/study')
      .then((response) => {
        const sID = response.data.data.studyProgramId || 0;
        const t = props.studyInfo.find((d) => d.studyProgramId === sID).typeEn;
        const studyTypeId = props.studyInfo.types.findIndex((entry) => entry === t) || 0;
        setValue('studyType', studyTypeId + 1);
        setValue('studyProgram', sID);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.studyInfo]);

  const onSubmit = (data) => {
    Backend.request('patch', '/user/study', {}, { studyProgramId: data.studyProgram })
      .then(() => {
        props.openSuccess('Your changes have been saved!');
      })
      .catch((err) => {
        if (err.response.status < 500) props.openFailure('' + err.response.data.error.message);
        else props.openFailure('Server error');
        console.log(err);
      });
  };

  return (
    <>
      {/* <SwitchComponent name='locale' control={control} labelOff='EN' labelOn='SV' disabled /> FIXME: enable when this feature is being worked on */}
      <StudyTypeSelect control={control} studyInfo={props.studyInfo} />
      <StudyProgramSelect control={control} studyInfo={props.studyInfo} watchStudyType={watchStudyType} />
      <ProgramCodeSelect control={control} />
      <SchoolSelect control={control} />
      <ChapterSelect control={control} />
      <ButtonComponent click={handleSubmit(onSubmit)} label='Save' fixedWidth />
    </>
  );
};

function mapState(state) {
  return {
    studyInfo: state.options.study,
  };
}

const mapDispatchToProps = {
  openSuccess: snackActions.successFeedback,
  openFailure: snackActions.errorFeedback,
  getStudyOptions: getStudyOptions,
};

export default connect(mapState, mapDispatchToProps)(Studies);
