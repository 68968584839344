import * as Backend from '../api/BackendRequest.jsx';

export function RequestEventsList(pid) {
  return Backend.request('get', `/event/${pid}`, {});
}

export function RequestEventStats(pid) {
  return Backend.request('get', `/event/${pid}/stats`, {}, {});
}
export function RequestTicketsList(pid) {
  return Backend.request('get', `/user/${pid}/tickets`);
}

export function RequestBooking(pid, eventId) {
  return Backend.request('post', '/user/booking/' + eventId);
}

export function RequestTicketRemoval(bid) {
  return Backend.request('delete', '/user/ticket/' + bid);
}

export const RequestTicketTransfer = (bookingId, lastName, email) => {
  return Backend.request('POST', `/user/booking/${bookingId}/transfer`, {}, {lastName, email});
}
