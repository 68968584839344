import React from 'react';
import { connect } from 'react-redux';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const SnackbarComponent = ({ snack, closeSnackbar }) => (
  <Snackbar open={snack.open} autoHideDuration={5000} onClose={() => closeSnackbar()}>
    <MuiAlert elevation={6} variant='filled' severity={snack.severity} onClose={() => closeSnackbar()}>
      {snack.context}
    </MuiAlert>
  </Snackbar>
);

const mapStateToProps = (state) => {
  return {
    snack: state.feedback.snack,
  };
};

const actionCreators = {
  closeSnackbar: snackActions.closeSnackbar,
};

export default connect(mapStateToProps, actionCreators)(SnackbarComponent);
