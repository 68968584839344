import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Backend from '/data/api/BackendRequest.jsx';
import ButtonComponent from '/components/forms-v2/Button.component';
import { connect } from 'react-redux';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';
import { getGeneralOptions } from '/redux/actions/OptionsActions';
import FirstNameInput from '/containers/FirstNameInput/FirstNameInput';
import LastNameInput from '/containers//LastNameInput/LastNameInput';
import DateOfBirthInput from '/containers/DateOfBirthInput/DateOfBirthInput';
import GenderSelect from '/containers/GenderSelect/GenderSelect';
import NationalitySelect from '/containers/NationalitySelect/NationalitySelect';
import PersonalNumberInput from '/containers/PersonalNumberInput/PersonalNumberInput';
import PhoneNumberInput from '/containers/PhoneNumberInput/PhoneNumberInput';
import CommunicationCheckbox from '/containers/CommunicationCheckbox/CommunicationCheckbox';

const General = ({ options, projectId, getGeneralOptions, openSuccess, openFailure }) => {
  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm({ mode: 'all' });

  useEffect(() => {
    if (!options) getGeneralOptions();
  }, []);

  useEffect(() => {
    if (!options) return;

    Backend.request('get', '/user/general')
      .then((response) => {
        setValue('firstName', response.data.data.firstName);
        setValue('lastName', response.data.data.lastName);
        setValue('birthdayDate', new Date(response.data.data.birthdayDate));
        setValue('sex', response.data.data.sex[0].toUpperCase()); // it dev account had something wrong
        setValue('nationality', response.data.data.nationality);
        setValue('personNumber', response.data.data.personNumber);
        setValue('phoneNumber', response.data.data.phoneNumber);
        setValue('isMarketingAllowed', response.data.data.isMarketingAllowed);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [options]);

  const onSubmit = (data) => {
    data.birthdayDate = new Date(
      data.birthdayDate.getTime() + Math.abs(data.birthdayDate.getTimezoneOffset() * 60000)
    ).toISOString();

    Backend.request('patch', '/user/general', {}, data)
      .then(() => {
        openSuccess('Your changes have been saved!');
      })
      .catch((err) => {
        if (err.response.status < 500) openFailure('' + err.response.data.error.message);
        else openFailure('Server error');
      });
  };

  return (
    <>
      <FirstNameInput control={control} />
      <LastNameInput control={control} />
      <DateOfBirthInput control={control} />
      <GenderSelect control={control} profileOptions={options} />
      <NationalitySelect control={control} profileOptions={options} />
      <PersonalNumberInput control={control} getValues={getValues} />

      {/* Notice that the Personal Number input below doesn't have style marginTop, as
 does the 'containerised' input on the registration page. Check layout. */}
      {/*       <TextComponent
        name='personNumber'
        control={control}
        label='Personal Number (optional)'
        helperText='YYMMDD-XXXX'
        rules={{
          validate: {
            birthdayMatch: (v) =>
              personNumberBirthdayMatch(v, getValues('birthdayDate'))
                ? true
                : 'Birthday and Personal Number do not match',
            validLength: (v) => (personNumberCorrectFormat(v) ? true : 'Wrong format YYMMDD-XXXX'),
          },
          maxLength: { value: 11, message: 'Too long input' },
        }}
      /> */}

      <PhoneNumberInput control={control} />
      <CommunicationCheckbox control={control} projectId={projectId} />

      <ButtonComponent click={handleSubmit(onSubmit)} label='Save' fixedWidth />
    </>
  );
};

function mapState(state) {
  return {
    options: state.options.general,
    projectId: state.projectInfo.projectId,
  };
}

const mapDispatchToProps = {
  getGeneralOptions: getGeneralOptions,
  openSuccess: snackActions.successFeedback,
  openFailure: snackActions.errorFeedback,
};

export default connect(mapState, mapDispatchToProps)(General);
