/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import useDebounce from '/utils/useDebounce';
import useKeyPress from '/utils/useKeyPress';
import ClearIcon from '@material-ui/icons/Clear';

const FilterComponent = ({ list, setList, children, attributes, staticFilters }) => {
  const [qurey, setQurey] = useState('');

  const filterRef = useRef(null);
  const key = useKeyPress('§');
  const filter = useKeyPress('f');
  const enter = useKeyPress('Enter');

  useEffect(() => {
    if (key && filter) {
      filterRef.current.focus();
    }
  }, [key, filter]);

  useEffect(() => {
    if (enter) {
      if (filterRef.current != null) {
        filterRef.current.blur();
      }
    }
  }, [enter]);

  const value = useDebounce(qurey, 300);

  useEffect(() => {
    const regex = new RegExp(value, 'gi');
    if (!list || list.length === 0) return;
    if (!staticFilters) staticFilters = [];

    const firstFilter = list.filter((event) => {
      return Object.entries(staticFilters).every(
        (filter) => event[filter[0]] === filter[1] || event[filter[0]] === ('true' === filter[1])
      );
    });

    const filtered = firstFilter.filter((event) =>
      attributes.some((attribute) => event[attribute] != undefined && event[attribute].match(regex))
    );

    setList(filtered);
  }, [value, list, setList, attributes, staticFilters]);

  return (
    <div style={{ display: 'flex' }}>
      <TextField
        inputRef={filterRef}
        label={children}
        style={{ width: '350px' }}
        variant='outlined'
        value={qurey}
        onChange={(event) => setQurey(event.target.value)}
        InputProps={
          qurey && {
            endAdornment: (
              <InputAdornment position='start'>
                <ClearIcon onClick={() => setQurey('')} className='clearIcon' />
              </InputAdornment>
            ),
          }
        }
      />
    </div>
  );
};

export default FilterComponent;
