import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';

import SubpageModal from '/components/subpageModal/SubpageModal.component';
import DateInterval from '/components/adminUsers/DateInterval.component';
import PriceInterval from '/components/adminUsers/PriceInterval.component';
import Attendance from '/components/adminUsers/Attendance.component';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useDispatch } from 'react-redux';
import { adminUsersSetEventFilters } from '/redux/reducers/AdminReducers';

const BookingsFilterContainer = () => {

  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const { control, watch, reset } = useForm({ mode: 'onSubmit' });
  const watchFields = watch();

  /**
   * pushes to change filter
   */
   const changeFilter = () => {

    let attended = [];
    if(watchFields.attended) attended.push('attended');
    if(watchFields.notAttended) attended.push('notAttended'); 

    const startDatetime = new Date(Date.parse(watchFields.startDate));
    startDatetime.setHours(0, 0, 0, 0);

    const endDatetime = new Date(Date.parse(watchFields.endDate));
    endDatetime.setHours(0, 0, 0, 0);

    let filter = {
      startDate: watchFields.startDate ? new Date(startDatetime).toJSON() : null,
      endDate: watchFields.endDate ? new Date(endDatetime).toJSON() : null,
      startPrice: watchFields.startPrice || null,
      endPrice: watchFields.endPrice || null,
      attended: attended || [],
    };

    dispatch(adminUsersSetEventFilters(filter));
  };

  /**
   * Set events on change
   */
  useEffect(() => {
    changeFilter();
  }, [watchFields]);

  return(
    <>
      <IconButton style={{color: '#104E75'}} onClick={() => setModalOpen(true)}>
        <FilterListIcon />
      </IconButton>
      <SubpageModal 
        title={'Filter'}
        isOpen={modalOpen} 
        reset={() => reset({
          startDate: null,
          endDate: null,
          startPrice: null,
          endPrice: null,
          attended: false,
          notAttended: false,
        })}
        stretchHeight={736}
        close={() => setModalOpen(false)}
      >
        <Typography variant='h6'>Date</Typography>
        <DateInterval control={control} />
        <Typography variant='h6'>Price</Typography>
        <PriceInterval control={control} />
        <Typography variant='h6'>Attendance</Typography>
        <Attendance control={control} />
        <Button
          color='secondary'
          variant='contained'
          fullWidth
          style={{ marginTop: '40px', marginBottom: '40px' }}
          onClick={() => setModalOpen(false)}
        >
          Show Results
        </Button>
      </SubpageModal>
    </>
  );
}

export default BookingsFilterContainer;