const useAuth = () => {
  const token = sessionStorage.getItem('token');
  if (token) {
    return JSON.parse(atob(token.split('.')[1]));
  } else {
    return null;
  }
};

export default useAuth;
