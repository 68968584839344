import React from 'react';
import CheckList from '/components/adminUsers/CheckList.component';
import { useSelector } from 'react-redux';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const ChecklistContainer = () => {

  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));
  const bigState = useSelector(state => state.adminUsers.addBooking);

  const buildResponse = () => {
    const responseData = bigState.responseData;
    
    if(responseData) {
      return [
        {
          success: responseData.isFull.isOk,
          title: 'Capacity',
          description: responseData.isFull.isOk ? 'Event has tickets left' : 'Event reached its booking limit',
        },
        {
          success: responseData.isOverlapping.isOk,
          title: 'Overlap',
          description: responseData.isOverlapping.isOk 
            ? 'Event does not overlap with other booked events' : 
            `Event overlaps with booked ${responseData.isOverlapping.details[0]} by the user`,
        },
        {
          success: responseData.isFiltered.isOk,
          title: 'Event Filter',
          description: responseData.isFiltered.isOk 
            ? 'Event visible on booking page' : 
            `${responseData.isFiltered.details[0]} prevents visibility for user`,
        },
        {
          success: responseData.isMaxGroups.isOk,
          title: 'Event Groups',
          description: responseData.isMaxGroups.isOk 
            ? 'No event group ticket limit for this event reached' : 
            `User has reached ticket limit for ${responseData.isMaxGroups.details[0]}`,
        },
      ];
    }

    return [];
  }

  return(
    <CheckList
      isMobile={isMobile}
      items={buildResponse()}
    />
  );
}

export default ChecklistContainer;