import React, { useEffect } from 'react';
import Content from '/components/content/Content.jsx';
import Footer from '/components/footer/Footer.jsx';
import { getEventIdList, classifyEventList, filterEventList } from '/utils/utils.jsx';
import { connect } from 'react-redux';
import { eventActions } from '/redux/actions/EventActions.jsx';
import EventSearch from '/components/event/EventSearch';
import EventFilterController from '/components/eventFilter/EventFilterController';
import FabComponent from '/components/fab/Fab';
import Emojify from '/components/emojify/Emojify';
import { TYPE, UserEventCard } from '/components/eventCard/UserEventCard';
import { StartEventDivider } from '/components/eventCard';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/**
 * Page to display events
 */
const Events = ({
  eventState,
  getEventList,
  statsList,
  getTicketsList,
  bookEvent,
  projectId,
  filter,
  eventGroups,
  getEventGroups,
}) => {
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));

  /**
   * Get all events and booked tickets
   */
  useEffect(() => {
    getEventList(projectId);
    getTicketsList(projectId);
    getEventGroups(projectId);
  }, []);

  /**
   * Returns a list of events that has been filtered
   */
  const classified = classifyEventList(
    filterEventList(
      eventState.data.data,
      filter,
      getEventIdList(eventState.tickets.data),
      statsList,
      eventGroups || [],
      eventState.filterSearch || ''
    )
  );

  /**
   * Book a ticket
   */
  const handleBooking = (item, e) => {
    e.stopPropagation();
    if (getEventIdList(eventState.tickets.data).includes(item.eventId)) {
      // redirect user if it's a ticket
      window.location.href = `/${projectId}/tickets`;
    } else {
      bookEvent(projectId, item.eventId, item.displayTitle); // book an event
    }
  };

  // returns a list of categories that can go into the event
  const listOfCategories = (eventId) => {
    if (!eventGroups) return [];

    let categoryNames = [];
    eventGroups.forEach((eventGroup) => {
      if (eventGroup.eventIDs.includes(eventId) && eventGroup.isBroadcasted) categoryNames.push(eventGroup.name);
    });

    return categoryNames;
  };

  const getStatus = (eventId, item) => {
    if(getEventIdList(eventState.tickets.data).includes(eventId)) {
      return TYPE.BOOKED;
    } else if (new Date() < Date.parse(item.bookingStartDatetime) || Date.parse(item.bookingEndDatetime) < new Date()) {
      return TYPE.CLOSED;
    } else if(statsList?.find((d) => d.eventId === eventId)?.isFull) {
      return TYPE.FULL;
    }
    return TYPE.BOOKABLE;
  };

  return (
    <>
      <Content
        header={
          <Emojify>
            <div>
              <p>
              Here, you will find all the events you can book for the Fall 2022 Reception. Remember to read carefully the Reception Rules and to collect your wristband at one of our Check-In Desks to attend our events. You will also be able to pay for your tickets that require payment. Book your events according to your arrival so that you can collect your wristband before your first booked event!&nbsp;
              </p>
              <p>
                <strong>Unlimited:</strong> Choose as many events as you wish from this category.
              </p>
              <p>
                <strong>Free:</strong> Events in this category are free of charge. You can choose a maximum of{' '}
                <strong>two</strong> events from this category.
              </p>
              <p>
                <strong>Paid:</strong> Events in this category require a payment. You can choose a maximum of{' '}
                <strong>two</strong> events from this category.
                </p>
                <p>
                <em>Note: These limits might change. Updates will be posted in our Facebook group and Telegram channel, so keep an eye on them if you’d like the chance to register for more events soon!</em>
              </p>
              <p>
                <strong>Events serving alcoholic and non-alcoholic drinks:</strong> Only prices for &ldquo;Non-Alcohol tickets&rdquo;
                are shown in the system. &ldquo;Alcohol tickets&rdquo; entail an average extra cost of 20-30 SEK.
              </p>
              <p>
                🚄 Public transport
              </p>
              <p>
                👟 Walking (&gt;2km)
              </p>
              <p>
                ♿ Universally accessible
              </p>
              <p>
                🍜 Food available
              </p>
              <p>
                Please remember that we might have to make last minute changes and adapt to future restrictions. Check our  <a href="https://www.thsint.se/contact">FAQ</a> for answers to the most common questions
              </p>
            </div>
          </Emojify>
        }
        sidebarComponent={
          <EventFilterController events={eventState.data.data} tickets={eventState.tickets} eventGroups={eventGroups} />
        }
      >
        <EventSearch />
        <div>
          {classified.map((classifiedItem, i) => {
            const item = classifiedItem.data;
            return (
              <div key={i}>
              {classifiedItem.first && <StartEventDivider date={item.startDatetime} />}
                <UserEventCard
                  title={item.displayTitle}
                  isMobile={isMobile}
                  state={getStatus(item.eventId, item)}
                  location={item.location}
                  attendance={item.attendanceLimit}
                  price={item.basePriceSek}
                  startTime={item.startDatetime}
                  endTime={item.endDatetime}
                  description={item.description}
                  categories={listOfCategories(item.eventId)}
                  booking={item.booking}
                  handleButton={(e) => handleBooking(item, e)}
                />
              </div>
            );
          })}
        </div>
      </Content>
      <FabComponent />
      <Footer name='IR' />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    eventState: state.eventRetrival,
    statsList: state.eventRetrival.stats,
    projectId: state.projectInfo.projectId,
    filter: state.eventRetrival.filter,
    eventGroups: state.eventRetrival.eventGroups,
  };
};

const actionCreators = {
  getEventList: eventActions.getEventList,
  getTicketsList: eventActions.getTicketsList,
  getEventGroups: eventActions.getEventGroups,
  bookEvent: eventActions.bookTicket,
};

const connectedEventsPage = connect(mapStateToProps, actionCreators)(Events);
export { connectedEventsPage as Events };