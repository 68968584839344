import React from 'react';

import { EventCard } from './EventCardBase';
import { EventStatistics } from './EventStatistics';
import { EventCategory } from './EventCategory';
import { EventDescription } from './EventDescription';
import { Divider, Button } from '@material-ui/core';
import { EventButton } from './EventButton';

export const TYPE = {
  TICKET: 'unbook',
  BOOKED: 'my tickets',
  FULL: 'full',
  CLOSED: 'closed',
  BOOKABLE: 'book',
}

/**
 * Displays an event card with customizable content
 * 
 * @param {string} title the title of the event
 * @param {boolean} isMobile if the card should be rendered in mobile mode
 * @param {TYPE} state the state of the event
 * @param {string} description the description of the event
 * @param {string} location the location of the event
 * @param {Date} startTime the start time of the event
 * @param {Date} endTime the end time of the event
 * @param {number} attendance max amount of people attending the event
 * @param {number} price the price of the event
 * @param {Array<string>} categories the categories of the event
 * @param {Function} handleButton onClick function for the button
 * @return {JSX} an event card
 */
export const UserEventCard = ({
  title = '',
  isMobile = false,
  state = TYPE.BOOKABLE,
  location = 'KTH Entré',
  attendance = 0,
  price = 0,
  startTime = new Date(),
  endTime = new Date(),
  description = 'A test 🏢 ❄ 👟 ♿ 🍜',
  categories = [
    'Hello',
    'World',
  ],
  transferEndTime = new Date(),
  booking = false,
  handleButton = () => null,
  handleTransfer = () => null,
  buttonDisabled = false,
}) => {

  const isDisabled = state === TYPE.CLOSED || state === TYPE.FULL;
  const isActive = state === TYPE.BOOKED || state === TYPE.TICKET;
  const transferDisabled = new Date() > transferEndTime;

  return (
    <EventCard
      title={title}
      isMobile={isMobile}
      disabled={isDisabled}
      active={isActive}
      statistics={
        <EventStatistics
          isMobile={isMobile}
          location={location}
          attendanceLimit={attendance}
          startTime={startTime}
          endTime={endTime}
          price={price}
        />
      }
    >
      <EventDescription
        description={description}
      />
      <Divider style={{marginTop: '16px'}} />
      <EventCategory
        isMobile={isMobile}
        categories={categories}
        disabled={isDisabled}
        active={isActive}
      />
      <Divider style={{marginTop: '8px'}} />
      <div 
        style={{
          marginTop: '16px',
          marginBottom: !isMobile ? '4px' : '0',
        }}
      >
        <EventButton 
          disabled={isDisabled || booking || buttonDisabled}
          booked={state === TYPE.TICKET}
          style={{minWidth: '124px', marginRight: '14px'}}
          onClick={handleButton}
          label={booking ? 'booking' : state}
        />
        {
          state === TYPE.TICKET && (
            <Button 
              variant='contained'
              style={{minWidth: '124px'}}
              onClick={handleTransfer}
              color='secondary'
              disabled={transferDisabled}
            >
              Transfer
            </Button>
          )
        }
      </div>
    </EventCard>
  );
}