import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation } from 'react-router-dom';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/* 
  Apparently, the tabs documentation on colors messed up.
  maybe a universal text system later?
*/
const useStyles = makeStyles((t) => ({
  root: {
    backgroundColor: t.palette.primary.main,
    color: `${t.palette.surface.main}${t.palette.other.opacityHigh}`,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2), 0px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  indicator: {
    backgroundColor: `${t.palette.surface.main}${t.palette.other.opacityHigh}`,
  },
  textColor: {
    color: `${t.palette.surface.main}${t.palette.other.opacityHigh}`,
  },
  textColorDisabled: {
    color: `${t.palette.surface.main}${t.palette.other.opacityDisabled}`,
  },
  manyTabs: {
    minWidth: '90px',
    maxWidth: '360px',
  },
}));

/**
 * Checks if it meets up to the breakpoint and "centers" the tabbar until it is fullfilled
 *
 * @param centeredUntil until which breakpoint it should be centered.
 * @param tablistLength length of the tab size
 * @returns true if it's met, false if it's not met
 */
const centeredBreakpoint = (centeredUntil, tablistLength) => {
  if (centeredUntil) {
    return useMediaQuery(Theme.breakpoints.down(centeredUntil)) && tablistLength > 3;
  } else {
    return tablistLength > 3;
  }
};

const TabBar = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const centeredUntil = props.centeredUntil ? props.centeredUntil : null;

  //Sets active tab based on the url.
  let location = useLocation();

  useEffect(() => {
    // tablist to hash values
    const availHashes = props.tablist.map((tab) => {
      return '#' + tab.link.split('#')[1];
    });

    if (location.hash === '') setValue(0);
    else setValue(availHashes.findIndex((e) => e === location.hash));
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const listItems = props.tablist.map((item, index) => (
    <Tab
      className={
        props.tablist.length > 3
          ? value === index
            ? classes.textColor + ' ' + classes.manyTabs
            : classes.textColorDisabled + ' ' + classes.manyTabs
          : value === index
          ? classes.textColor
          : classes.textColorDisabled
      }
      label={item.label}
      {...a11yProps(index)}
      href={item.link}
      key={index}
    />
  ));

  /*
    this was the most effective way I found out to change the text color depending
    on if it is active or not.
  */
  return (
    <Hidden mdUp>
      <AppBar position='sticky' elevation={0}>
        <Tabs
          centered={centeredBreakpoint(centeredUntil, props.tablist.length) ? false : true}
          classes={{ indicator: classes.indicator }}
          className={classes.root}
          variant={centeredBreakpoint(centeredUntil, props.tablist.length) ? 'scrollable' : 'fullWidth'}
          value={value}
          scrollButtons={'on'}
          onChange={handleChange}
          aria-label='simple tabs example'
        >
          {listItems}
        </Tabs>
      </AppBar>
    </Hidden>
  );
};

export default TabBar;
