import React from 'react';

import { Edit, DeleteOutline, Close } from '@material-ui/icons';
import { TableRow } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Theme from '/MuiTheme';
import CustomTableCell from '/components/adminAccess/customTableCell/CustomTableCell';
import UserRoleMenu from '/components/adminAccess/userRoleMenu/UserRoleMenu';

const BodyMobile = ({user, index, roles, setUserRole, setUserEditing, setIdToDelete, setDeleteDialogOpen}) => (
  <TableRow>
    <CustomTableCell style={{maxWidth: '212px'}}>
      <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
        {
          user.editing ? (
            <DeleteOutline 
              // FIXME: Reverse this when delete is implemented
              // onClick={() => {
              //   setIdToDelete(user.accountId);
              //   setDeleteDialogOpen(true);
              // }}
              // FIXME: Change this to Theme.palette.secondary.main when delete is implemented
              style={{width: 20, height: 20, color: `${Theme.palette.surface.text}${Theme.palette.other.opacityDisabled}`}}
            />
          ) : null
        }
        <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
          <Typography noWrap variant='body2' style={{marginBottom: '8px'}}>{user.firstName + ' ' + user.lastName}</Typography>
          <Typography noWrap variant='caption'>{user.email}</Typography>
        </div>
      </div>
    </CustomTableCell>
    <CustomTableCell>
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <div style={{marginBottom: '8px', display: 'flex', alignItems: 'center'}}>
          <Typography variant='body2'>{roles.find((role) => role.roleId === user.roleId).title}</Typography>
          {user.editing ? (
            <UserRoleMenu options={roles.map(role => ({
              label: role.title,
              onclick: () => {
                setUserRole(user.accountId, role.roleId)
              },
            }))} />
          ) : null}
        </div>
        <div>
          {
            user.editing ? (
              <Close 
                onClick={() => setUserEditing(user.accountId, false)}
                style={{width: 20, height: 20, color: Theme.palette.primary.main, cursor: 'pointer'}}
              />
            ) : (
              <Edit 
                onClick={() => setUserEditing(user.accountId, true)}
                style={{width: 20, height: 20, color: Theme.palette.secondary.main, cursor: 'pointer'}}
              />
            )
          }
        </div>
      </div>
    </CustomTableCell>
  </TableRow>
);

export default BodyMobile;