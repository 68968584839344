import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function getEventIdList(arr) {
  if (!arr) return [];
  const out = [];
  arr.map((ev) => out.push(ev.eventId));
  return out;
}

/**
 * Checks if entry is within date of the filter
 * @param {*} entry event entry
 * @param {*} filters filter from redux state
 * @return true/false depending on if the date is within filter
 */
function checkDate(entry, filters) {
  const startDatetime = new Date(Date.parse(entry.startDatetime));
  startDatetime.setHours(0, 0, 0, 0);

  const endDatetime = new Date(Date.parse(entry.endDatetime));
  endDatetime.setHours(0, 0, 0, 0);

  return (
    startDatetime >= (Date.parse(filters.startDate) || new Date('0000-01-01')) &&
    endDatetime <= (Date.parse(filters.endDate) || new Date('9999-12-31'))
  );
}

/**
 * Checks if entry is within price of the filter
 * @param {*} entry event entry
 * @param {*} filters filter from redux state
 * @return true/false depending on if the price is within filter
 */
function checkPrice(entry, filters) {
  return (entry.basePriceSek || 0) >= (filters.startPrice || 0) && (entry.basePriceSek || 9999) <= (filters.endPrice || 9999);
}

/**
 * 
 * @param {*} data event list
 * @param {*} filters filters without search
 * @param {*} search search string
 * @returns list of events that match the filters
 */
export function filterEventListAdmin(data, paraFilter, search) {

  let filters = {...paraFilter};

  filters = {
    startDate: filters.startDate ? new Date(filters.startDate) : null,
    endDate: filters.endDate ? new Date(filters.endDate) : null,
    startPrice: filters.startPrice || null,
    endPrice: filters.endPrice || null,
    attended: filters.attended,
  };

  // check data is existing or filters are
  if (!data || data.length == 0) {
    return [];
  }

  // for every data
  let filteredData = data;

  // Search
  filteredData = filteredData.filter((entry) => {
    if (search.length < 3) return true;
    return entry.displayTitle.toLowerCase().includes(search.toLowerCase());
  });

  // Date
  filteredData = filteredData.filter((entry) => {
    if (checkDate(entry, filters)) return entry;
  });

  // Price
  filteredData = filteredData.filter((entry) => {
    if (checkPrice(entry, filters)) return entry;
  });

  // Attendance
  if(filters.attended.length !== 0 && filters.attended.length !== 2) {
    filteredData = filteredData.filter((entry) => {

      let shouldReturn = false;

      if (filters.attended.includes('attended') && entry.isAttending) shouldReturn = true;
      else if (filters.attended.includes('notAttended') && !entry.isAttending) shouldReturn = true;

      return shouldReturn;
    });
  }

  return filteredData;
}

/**
 * Filters data
 */
export function filterEventList(data, filters, eventIdList, statsList, eventGroups, search) {
  // check data is existing or filters are
  if (!data || data.length == 0) {
    return [];
  }

  // for every data
  let filteredData = data;

  // Search
  filteredData = filteredData.filter((entry) => {
    if (search.length < 3) return true;
    return entry.displayTitle.toLowerCase().includes(search.toLowerCase());
  });

  // Date
  filteredData = filteredData.filter((entry) => {
    if (checkDate(entry, filters)) return entry;
  });

  // Price
  filteredData = filteredData.filter((entry) => {
    if (checkPrice(entry, filters)) return entry;
  });

  //Status
  if (filters.booked || filters.bookable || filters.closed || filters.full) {
    filteredData = filteredData.filter((entry) => {
      const closed =
        new Date() < Date.parse(entry.bookingStartDatetime) || Date.parse(entry.bookingEndDatetime) < new Date();
      const booked = eventIdList.includes(entry.eventId);
      const full = statsList?.find((d) => d.eventId === entry.eventId)?.isFull;

      let shouldReturn = false;

      if (booked && filters.booked) shouldReturn = true;
      else if (!closed && !full && filters.bookable) shouldReturn = true;
      else if (filters.full && full && !(filters.closed && closed) && !booked) shouldReturn = true;
      else if (filters.closed && closed && !(filters.full || full) && !booked) shouldReturn = true;
      else if (((filters.closed && closed) || (filters.full && full)) && !booked) shouldReturn = true;

      return shouldReturn;
    });
  }

  // Category

  // 1. union all event id's matching the categories
  let eventIdCategories = new Set([]);
  eventGroups.forEach((eventGroup) => {
    if (filters.categories.includes(eventGroup.id.toString()))
      eventGroup.eventIDs.forEach((value) => eventIdCategories.add(value));
  });

  // 2. filter with the id
  if (filters.categories.length > 0) {
    filteredData = filteredData.filter((entry) => eventIdCategories.has(entry.eventId));
  }

  return filteredData;
}

// classifyEventList sorts the events in the data list by starting time (asc).
// it also flags the "first event of the day".
export function classifyEventList(data) {
  if (data === undefined || data === null || data.length == 0) {
    return [];
  }
  const classified = [];
  var unique = format(utcToZonedTime(data[0].startDatetime, 'Europe/Stockholm'), 'LL do');
  data.map((ev, i) => {
    classified.push({
      first: unique !== format(utcToZonedTime(ev.startDatetime, 'Europe/Stockholm'), 'LL do'),
      data: ev,
    });
    unique = format(utcToZonedTime(ev.startDatetime, 'Europe/Stockholm'), 'LL do');
  });
  classified[0].first = true;
  return classified;
}

/**
 * Checks if personal number and birthday match.
 */
export const personNumberBirthdayMatch = (personNumber, birthdayDate) => {
  if (personNumber === null || personNumber === undefined || personNumber === '') return true;

  birthdayDate = new Date(birthdayDate.getTime() + Math.abs(birthdayDate.getTimezoneOffset() * 60000)).toISOString();

  if (personNumber.length > 0) {
    const YYMMDDFromDate =
      birthdayDate !== '' ? birthdayDate.slice(2, 4) + birthdayDate.slice(5, 7) + birthdayDate.slice(8, 10) : '-1-1-1'; // always invalid if birthday date is empty
    const YYMMDDFromPersonNumber = personNumber.slice(0, 6);

    // check if length is 11, the last 4 character are digits and if it matches birthday date
    if (YYMMDDFromDate !== YYMMDDFromPersonNumber) {
      return false;
    }
  }

  return true;
};

/**
 * checks person number is correct format
 */
export const personNumberCorrectFormat = (personNumber) => {
  if (personNumber === null || personNumber === undefined || personNumber === '') return true;

  if (personNumber.length > 0) {
    // check if length is 11, the last 4 character are digits and if it matches birthday date
    if (personNumber.length !== 11 || !/^[0-9]+$/.test(personNumber.slice(7, 11))) {
      return false;
    }
  }
  return true;
};

export function intersection(a, b) {
  if (!a || !b) {
    return [];
  }
  return a.filter((value) => b.indexOf(value) !== -1);
}
