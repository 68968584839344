import React from 'react';
import { Button } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  button: {
    marginBottom: '16px',
    marginTop: '16px',
  },
  fixedWidth: {
    width: '97px',
  },
}));

const ButtonComponent = ({ click, label, fixedWidth = false, disabled, fullWidth }) => {
  const classes = useStyles();

  return (
    <Button
      type='submit'
      variant='contained'
      color='secondary'
      onClick={click}
      className={fixedWidth ? `${classes.button} ${classes.fixedWidth}` : classes.button}
      disabled={disabled ? disabled : false}
      fullWidth={fullWidth || false}
    >
      {label}
    </Button>
  );
};

export default ButtonComponent;
