import React from 'react';
import { connect } from 'react-redux';
import pic from '/static/start1.png';
import Button from '@material-ui/core/Button';
import useAuth from '/hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  imageContainer: {
    position: 'relative',
    width: '100%',
    backgroundImage: `url(${pic})`,
    height: '530px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    [t.breakpoints.down('sm')]: {
      height: '480px',
    },
    [t.breakpoints.down('xs')]: {
      height: '430px',
    },
  },
  buttonContainer: {
    margin: '0 auto',
    width: '297px',
    marginTop: '456px',
    [t.breakpoints.down('sm')]: {
      marginTop: '383px',
    },
    [t.breakpoints.down('xs')]: {
      width: '248px',
      marginTop: '295px',
    },
  },
  buttonRegister: {
    width: '97px',
    marginRight: '51px',
    [t.breakpoints.down('xs')]: {
      marginRight: '27px',
    },
  },
  buttonLogin: {
    width: '97px',
    marginLeft: '51px',
    [t.breakpoints.down('xs')]: {
      marginLeft: '27px',
    },
  },
}));

const Jumbotron = (props) => {
  const classes = useStyles();
  const token = useAuth();

  return (
    <div className={classes.imageContainer}>
      <div className={classes.buttonContainer}>
        {token === null ? (
          <>
            <Button
              color='secondary'
              variant='contained'
              href={`/${props.projectId}/CreateAccount`}
              className={classes.buttonRegister}
            >
              Register
            </Button>
            <Button
              color='secondary'
              variant='contained'
              href={`/${props.projectId}/Login`}
              className={classes.buttonLogin}
            >
              Login
            </Button>
          </>
        ) : null}
      </div>
    </div>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(Jumbotron);
