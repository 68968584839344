import React from 'react';

import { makeStyles } from '@material-ui/core';
import CheckComponent from '/components/forms-v2/Check.component';
import ButtonComponent from '/components/forms-v2/Button.component';
import FormLogo from '/components/formLogo/FormLogo.component';

const useStyles = makeStyles((t) => ({
  logoCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '14px',
    width: '95px',
  },
  textCenter: {
    textAlign: 'center',
    lineHeight: '28px',
  },
  generalInfo: {
    marginTop: '16px !important',
    marginBottom: '16px !important',
  },
}));

const PageOne = ({ control, onSubmit }) => {
  const classes = useStyles();

  return (
    <div style={{ margin: '16px 8px' }}>
      <FormLogo dividerStyle={{ maxWidth: '900px', margin: '0 auto', color: 'green' }} />
      <p>
        Welcome to the THS International Reception Fall 2022! We are very happy that you want to take part in our
        activities! Before you can start checking out the events we have planned for you, we kindly ask you to read
        through our project rules.
      </p>
      <h5>THS International Reception Fall 2022 Rules</h5>
      <p>
      By using our signup page and participating in the THS International Reception Fall 2022, you agree and acknowledge the following rules and information based on the following three sections: who can participate, signing up for and participating in events, and data handling.&nbsp;
      </p>
      <h6>Who can participate</h6>
      <p>
      The THS International Reception is for incoming international students, newly admitted master students at KTH and newly admitted students to joint programs of KTH, that arrive for the start of the Fall Semester 2022 only. Other students cannot participate. The final decision of eligibility to participate is made by the Project Managers and cannot be challenged.
      </p>
      <h6>Booking and participating in events</h6>
      <p>
      Do not book events that collide with each other or with other school activities or information sessions timewise. Participating in events of the Reception is completely voluntary. You have to choose which event you want to attend. Furthermore, events can be booked up to 48 hours before an event starts. If you cannot attend an event it is important and necessary to cancel your booked ticket in the Signup System. This is possible up to 24 hours before the event starts.
      </p>
      <p>
      During the Fall 2022 Reception, check-in desks will be held regularly where you will be able to pick up your THS International Reception wristband, which you will need to wear for all the events that you're attending. You must pick it up even if you don't need to pay for events; <strong>without the wristband, you won&rsquo;t be able to attend any event</strong>. Make sure to not lose or cut it! It must remain on your wrist for the entire THS International Reception period. You will also be able to pay for your events at the check-in desks. You can also delegate someone else to pay for you. Failing to collect your wristband or not paying before your events may result in unbooking of all your consecutive events. Information on when check-in desks will be held will be posted in the KTH International Students Fall 2022 <a href="https://www.facebook.com/groups/irht22">Facebook Group</a> and <a href="https://t.me/+uSL97FTlTbBlMzU0">Telegram Group</a>, in addition to the check-in desk happening on the Arrival Day.&nbsp;
        </p>
      <p>
      You must follow the rules of the event/activity. This is for your own safety and other students’ safety. Failing to follow these rules or if your behaviour at an event is considered rude, discriminatory, problematic or making others uncomfortable, we will ask you to leave. We ask you to respect other guests, the culture and the event organisers. We can ban you permanently or remove you from one or all events of the Reception for any given reason. This decision is made by the Project Managers and cannot be challenged. If you witness anything that can be considered rude, discriminatory, problematic, or making others uncomfortable, please report this to a member of our staff or send us a message to <a href="mailto:info@thsint.se">info@thsint.se</a> about it. If the Project Managers decide to ban you from the Reception, you will <strong>NOT </strong>get a refund on paid tickets.
      </p>
      <p>
      Unless decided otherwise by the Project Managers of the Reception, refunds for paid events are <strong>NOT</strong> possible. 
      </p>
      <p>
      Instead, <strong>ticket transfers</strong> are allowed and encouraged. More details on how the ticket transfer can happen can be found in our Signup System. Please note that, if you paid already for this event, you will handle the payment independently and that the buyer has to have collected their wristband. The Reception is <b>not</b> to be held accountable for payments resulting from or related to ticket transfers.
      </p>
      <p>
      In case of confirmed or suspected Covid-19 infection, please inform us by sending an email to <a href="mailto:info@thsint.se">info@thsint.se</a>; while this may allow you to receive a refund for a paid ticket, it also means that you will be automatically excluded from all reception events for the following 7 days to ensure the health of everyone involved in the Reception.
      </p>
      <h6>Data handling</h6>
      <p>
      The Signup System used by us has its own dedicated privacy policy. In addition to that, we automatically export data from the Signup System to Google Workspace for our work. Google Workspace is a Cloud Service by Google Inc., where we store data according to their terms &amp; conditions. Therefore, it may happen that some of your data is stored on servers outside of the European Union.
      </p>
      <p>
      If we co-host an event with other organisations, relevant data will be given to them, so we can organise the event properly with them. Your email will not be given out to any third parties unless otherwise stated. We will forward you relevant emails from them if needed. We keep the data after the Reception to evaluate the signup, so we can improve it for the future. This is for statistical purposes only; we do not look into individuals. If you would like to opt out, please write an email to info@thsint.se. We will erase you from the database, and you will not be allowed to participate in any Reception activities anymore.
      </p>
      <h6>Other topics</h6>
      <p>
      By booking our events, you allow THS International to use photos/videos taken of you from the event for marketing purposes.
      </p>
      <p>
      In case of force majeure, the Project Team can cancel any event in order to guarantee the safety of everyone.
      </p>
      <p>Please note that the rules above can be changed at any time without prior notification.</p>
      <CheckComponent
        name='isTosAgreed'
        control={control}
        rules={{
          validate: {
            isTrue: (v) => (v === true ? true : 'You must accept'),
          },
        }}
        noMargin
        label='I agree with the project rules'
      />
      <div style={{ float: 'right', marginBottom: 40 }}>
        <ButtonComponent click={onSubmit} fixedWidth label='Next' />
      </div>
    </div>
  );
};

export default PageOne;
