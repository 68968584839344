export const SNACK_SUCCESS = 'SNACK_SUCCESS';
export const SNACK_ERROR = 'SNACK_ERROR';
export const SNACK_CLOSE = 'SNACK_CLOSE';

export const snackActions = {
  successFeedback,
  errorFeedback,
  closeSnackbar,
};
function successFeedback(context) {
  return (dispatch) => {
    dispatch(successSnackbar(context));
  };
}

function errorFeedback(context) {
  return (dispatch) => {
    dispatch(errorSnackbar(context));
  };
}

function closeSnackbar() {
  return (dispatch) => {
    dispatch(closeSnackbar());
  };
}

function enableButton(status, text) {
  return (dispatch) => {
    dispatch(enableButton());
  };
}

function disableButton(text) {
  return (dispatch) => {
    dispatch(disableButton(text));
  };
}

function successSnackbar(context) {
  return { type: SNACK_SUCCESS, data: context };
}

function errorSnackbar(context) {
  return { type: SNACK_ERROR, data: context };
}

function closeSnackbar() {
  return { type: SNACK_CLOSE };
}
