import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

/**
 * Root style for stepper and buttons
 */
const useStyles = makeStyles((t) => ({
  root: {
    width: '100%',
    marginBottom: '16px',
    marginTop: '20px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  stepper: {
    padding: '0 0',
    paddingBottom: '5px',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    [t.breakpoints.down('sm')]: {
      paddingBottom: '10px',
    },
  },
  button: {
    '& .MuiButton-label': {
      display: 'block',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
}));

/**
 * Style for individual icons
 */
const CustomIconStyles = makeStyles((t) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    color: t.palette.secondary.main,
  },
  circle: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    [t.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
    [t.breakpoints.down('xs')]: {
      width: 15,
      height: 15,
    },
  },
  completed: {
    backgroundColor: t.palette.secondary.main,
  },
}));

/**
 * returns a custom step icon
 */
const CustomStepIcon = (props) => {
  const classes = CustomIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <div className={`${classes.circle} ${classes.completed}`} /> : <div className={classes.circle} />}
    </div>
  );
};

/**
 * Renders stepper dots with back and next button.
 *
 * @param active current active state (begins from 0)
 * @param stepAmount how many dots
 * @param handleNext button onClick to next
 * @param handleBack button onClick to go back
 */
const StepperComponent = ({ active = 1, stepAmount = 1, handleNext, handleBack }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        onClick={handleBack}
        variant='outlined'
        color='secondary'
        className={active > 0 ? classes.button : `${classes.button} ${classes.hidden}`}
        style={{ justifyContent: 'flex-start', width: '97px' }}
      >
        Back
      </Button>

      <Stepper className={classes.stepper} activeStep={active} connector={null}>
        {Array.from(Array(stepAmount), (e, i) => (
          <Step key={i}>
            <StepLabel StepIconComponent={CustomStepIcon}>{null}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Button
        variant='contained'
        color='secondary'
        onClick={handleNext}
        className={classes.button}
        style={{ display: 'flex', justifyContent: 'flex-end', width: '97px' }}
      >
        Next
      </Button>
    </div>
  );
};

export default StepperComponent;
