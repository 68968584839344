import React from 'react';
import TextComponent from '/components/forms-v2/Text.component';

const PhoneNumberInput = ({ control }) => {
  return (
    <TextComponent
      prefix='+'
      name='phoneNumber'
      control={control}
      label='Phone Number (optional)'
      rules={{
        pattern: { value: /^[0-9-\[\]() ]+$/, message: 'Wrong format, valid characters: 0-9, -, [], (), space' },
        maxLength: { value: 20, message: 'Too long input' },
      }}
    />
  );
};

export default PhoneNumberInput;
