import { PROJECT_UNSIGNED, PROJECT_SIGNED, PROJECT_FAIL, PROJECT_SWITCH } from '/redux/actions/ProjectActions.jsx';
const initialState = {
  signed: false,
  data: {},
  error: {},
  projectId: 0,
};

export function projectInfo(state = initialState, action) {
  switch (action.type) {
    case PROJECT_SIGNED:
      return {
        ...state,
        signed: true,
        data: action.data,
      };
    case PROJECT_UNSIGNED:
      return {
        ...state,
        signed: false,
        data: action.data,
      };
    case PROJECT_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case PROJECT_SWITCH:
      return {
        ...state,
        projectId: action.projectId,
      };
    default:
      return state;
  }
}
