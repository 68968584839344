import React from 'react';
import { Divider } from '@material-ui/core';

const ContentHeader = (props) => {
  return (
    <>
      <div style={{ marginTop: '16px' }}>
        {props.children}
        <Divider style={{ marginTop: '16px' }} />
      </div>
    </>
  );
};

export default ContentHeader;
