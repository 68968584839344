/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as Backend from '/data/api/BackendRequest.jsx';
import ClearIcon from '@material-ui/icons/Clear';

import './displayBookings.scss';
import { Dialog, DialogTitle } from '@material-ui/core';
import useKeyPress from '/utils/useKeyPress.js';

const DisplayUsers = ({ bookings, getBookings, event, editBookings, projectId }) => {
  const key = useKeyPress('§');
  const attending = useKeyPress('a');
  const paid = useKeyPress('p');

  useEffect(() => {
    if (key && attending) {
      if (bookings[0]) updateBooking(bookings[0].bookingId, !bookings[0].isAttending, 'prev');
    }
    if (key && paid) {
      if (bookings[0]) {
        if (event.basePriceSek != 0) {
          updateBooking(bookings[0].bookingId, 'prev', !bookings[0].isPaid);
        }
      }
    }
  }, [key, attending, paid]);

  const updateBooking = (id, attending, paid) => {
    const change = { isPaid: paid, isAttending: attending };
    if (attending === 'prev') delete change.isAttending;
    if (paid === 'prev') delete change.isPaid;

    Backend.request('PATCH', `admin/${projectId}/booking/${id}`, {}, { ...change }).then(() => getBookings());
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const deleteBooking = (id) => {
    Backend.request('DELETE', `admin/${projectId}/ticket/${id}`).then(() => getBookings());
  };

  return bookings.map((booking) => {
    return (
      <div className='userCard' key={booking.accountId}>
        <div className='cardName'>{booking.firstName + ' ' + booking.lastName}</div>
        <div className='cardButtonGroup'>
          {!editBookings && (
            <>
              <Button
                id='attending'
                color={booking.isAttending ? 'primary' : 'default'}
                variant='contained'
                onClick={() => updateBooking(booking.bookingId, !booking.isAttending, booking.isPaid)}
              >
                Attending
              </Button>
              <Button
                disabled={event.basePriceSek == 0}
                id='paid'
                color={booking.isPaid ? 'primary' : 'default'}
                variant='contained'
                onClick={() => updateBooking(booking.bookingId, booking.isAttending, !booking.isPaid)}
              >
                {event.basePriceSek == 0 ? 'Free' : booking.isPaid ? 'Paid' : 'Pay'}
              </Button>{' '}
            </>
          )}

          {editBookings && <ClearIcon onClick={() => setDeleteConfirmation(booking)} id='cross' />}
          <Button onClick={() => setDeleteConfirmation(booking)} id='delete'>
            Delete
          </Button>

          <Dialog open={!!deleteConfirmation} onClose={() => setDeleteConfirmation(null)}>
            <div className='dialogtitle'>Confirm delete booking</div>
            <div className='deleteConfirmation'>
              Are you sure you want to delete {`${deleteConfirmation && deleteConfirmation.name}'s`} booking
            </div>
            <Button onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
            <Button
              onClick={() => {
                deleteBooking(deleteConfirmation.bookingId);
                setDeleteConfirmation(null);
              }}
              color='primary'
            >
              Confirm
            </Button>
          </Dialog>
        </div>
      </div>
    );
  });
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(DisplayUsers);
