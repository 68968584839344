/* eslint-disable react/prop-types */
import React from 'react';

import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';

const AdminEventCard = ({ event, setManageData, setCreateSettings, setCreateData, inStatus, bookingStats }) => {
  const history = useHistory();

  const catswtich = () => {
    if (event.categoryId === 4) {
      return <span>A</span>;
    } else if (event.categoryId === 0) {
      return <span>B</span>;
    } else {
      return <span>Undefined</span>;
    }
  };

  return (
    <div>
      <ExpansionPanel style={{ margin: '20px' }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className='summaryContainer'>
            <div className='leftSide'>
              <div className='columnItem'>
                <h5>{event.displayTitle}</h5>
                <div className='time'>
                  Start:{' '}
                  {new Date(event.startDatetime).toLocaleString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                  })}
                </div>
              </div>
            </div>

            <div className='rightSide'>
              <div className='statsGroup'>
                <div className='columnItem textCenter marginStats marginMobile'>
                  <div className='time marginMobile'>Booked</div>
                  <div className='time'>
                    {bookingStats != null ? bookingStats.countBooked : '0'} /{' '}
                    {event.attendanceLimit == 0 ? '∞' : event.attendanceLimit}
                  </div>
                </div>
                <div className='columnItem marginStats textCenter'>
                  <div className='time'>Attending</div>
                  <div className='time'>
                    {bookingStats != null ? bookingStats.countAttending : '0'} /{' '}
                    {bookingStats != null ? bookingStats.countBooked : '0'}
                  </div>
                </div>
                <div className='columnItem marginStats textCenter'>
                  <div className='time'>{event.basePriceSek == 0 || 'Paid'}</div>
                  <div className='time'>
                    {event.basePriceSek == 0
                      ? 'Free event'
                      : (bookingStats != null ? bookingStats.countPaid : '0') +
                        ' / ' +
                        (bookingStats != null ? bookingStats.countBooked : '0')}
                  </div>
                </div>
              </div>

              <div className='buttonGroup'>
                {!inStatus && (
                  <Button
                    onClick={() => {
                      history.push('#manage');
                      setManageData(event);
                    }}
                  >
                    Status
                  </Button>
                )}
                <Button
                  onClick={() => {
                    history.push('#createEvent');
                    setCreateSettings((prevState) => ({
                      ...prevState,
                      editing: true,
                      basePriceSek: event.basePriceSek == 0 ? true : false,
                      attendanceLimit: event.attendanceLimit === 0 ? true : false,
                    }));
                    setCreateData(event);
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className='detailBox'>
            <div className='detailRow'>
              <p>
                <strong>Attendance limit:</strong> {event.attendanceLimit || 'None'}
              </p>
              <p>
                <strong>Price:</strong> {event.basePriceSek || 'Free'}
              </p>
              <p>
                <strong>Location:</strong> {event.location}
              </p>
              <p>
                <strong>Category:</strong> {catswtich()}
              </p>
            </div>
            <div className='detailRow'>
              <p>
                <strong>Event time:</strong>{' '}
                {new Date(event.startDatetime).toLocaleString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}{' '}
                -{' '}
                {new Date(event.endDatetime).toLocaleString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </p>
            </div>
            <div className='detailRow'>
              <p>
                <strong>Booking between:</strong>{' '}
                {new Date(event.bookingStartDatetime).toLocaleString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}{' '}
                -{' '}
                {new Date(event.bookingEndDatetime).toLocaleString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
              </p>
            </div>
            <div className='detailRow'>
              <p>
                <strong>Unbooking end time:</strong>{' '}
                {new Date(event.unbookingEndDatetime).toLocaleString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
              </p>
              <p>
                <strong>Swap end time:</strong>{' '}
                {new Date(event.swapEndDatetime).toLocaleString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
              </p>
            </div>
            <p>
              <strong>Description:</strong> {event.description}
            </p>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default AdminEventCard;
