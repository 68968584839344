import React from 'react';
import TextComponent from '/components/forms-v2/Text.component';
import { personNumberBirthdayMatch, personNumberCorrectFormat } from '/utils/utils';

const PersonalNumberInput = ({ control, getValues }) => {
  return (
    <TextComponent
      name='personNumber'
      control={control}
      label='Personal Number (optional)'
      helperText='YYMMDD-XXXX'
      rules={{
        validate: {
          birthdayMatch: (v) =>
            personNumberBirthdayMatch(v, getValues('birthdayDate'))
              ? true
              : 'Birthday and Personal Number do not match',
          validLength: (v) => (personNumberCorrectFormat(v) ? true : 'Wrong format YYMMDD-XXXX'),
        },
        maxLength: { value: 11, message: 'Too long input' },
      }}
      style={{
        marginTop: '16px',
      }}
    />
  );
};

export default PersonalNumberInput;
