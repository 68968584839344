import React from 'react';

const Privacy = () => (
  <>
    <h5>PRIVACY POLICY</h5>
    <p>
      <strong>Last updated January 1, 2022</strong>
    </p>
    <p>
      Thank you for choosing to use our system and services at THS International ("
      <strong>Company</strong>
      ", "<strong>we</strong>
      ", "<strong>us</strong>
      ", "<strong>our</strong>
      "). We are a student organization and we aim to use our energy and commitment to work in both fun and professional
      manners. As such, we are committed to protecting your personal information and your right to privacy. Consequently
      we also have a long privacy policy for you to read through :D As we are students at a technical university and
      hold no law degree, this privacy policy might not be 100% perfect. So, if you have any questions or concerns about
      this privacy notice, or our practices with regards to your personal information, please contact us at{' '}
      <a href='mailto:info@thsint.se'>info@thsint.se</a>
      .&nbsp;
    </p>
    <p>
      When you visit our website <a href='http://www.event.thsint.se'>http://www.event.thsint.se</a> (the "
      <strong>Website</strong>"), and more generally, use any of our services (the "<strong>Services</strong>
      ", which include the Website), we appreciate that you are trusting us with your personal information. We take your
      privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what
      information we collect, how we use it and what rights you have in relation to it. We hope you take some time to
      read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree
      with, please discontinue use of our Services immediately.
    </p>
    <p>
      This privacy notice applies to all information collected through our Services (which, as described above, includes
      our Website), as well as, any related services, sales, marketing or events.
    </p>
    <p>
      <strong>
        Please read this privacy notice carefully as it will help you understand what we do with the information that we
        collect.
      </strong>
    </p>
    <h6>TABLE OF CONTENTS</h6>
    <ol>
      <li>WHAT INFORMATION DO WE COLLECT?</li>
      <li>HOW DO WE USE YOUR INFORMATION?</li>
      <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
      <li>WHO WILL YOUR INFORMATION BE SHARED WITH?</li>
      <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
      <li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
      <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
      <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
      <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
      <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
      <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
      <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
      <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
      <li>HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</li>
    </ol>
    <h6>1. WHAT INFORMATION DO WE COLLECT?</h6>
    <p>
      <strong>Personal information you disclose to us</strong>
    </p>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>We collect personal information that you provide to us.</em>
    </p>
    <p>
      We collect personal information that you voluntarily provide to us when you register on the Website, express an
      interest in obtaining information about us or our products and Services, when you participate in activities on the
      Website or otherwise when you contact us.
    </p>
    <p>
      The personal information that we collect depends on the context of your interactions with us and the Website, the
      choices you make and the products and features you use. The personal information we collect may include the
      following:
    </p>
    <p>
      <strong>Personal Information Provided by You. </strong>
      We collect names; phone numbers; email addresses; passwords; contact preferences; contact or authentication data;
      nationality; study program/type; and other similar information.
    </p>
    <p>
      All personal information that you provide to us must be true, complete and accurate, and you must notify us of any
      changes to such personal information.
    </p>
    <p>
      <strong>Information automatically collected</strong>
    </p>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>
        Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics
        &mdash; is collected automatically when you visit our Website.
      </em>
    </p>
    <p>
      We automatically collect certain information when you visit, use or navigate the Website. This information does
      not reveal your specific identity (like your name or contact information) but may include device and usage
      information, such as your IP address, browser and device characteristics, operating system, language preferences,
      referring URLs, device name, country, location, information about how and when you use our Website and other
      technical information. This information is primarily needed to maintain the security and operation of our Website,
      and for our internal analytics and reporting purposes.
    </p>
    <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
    <p>The information we collect includes:</p>
    <ul>
      <li>
        <em>Log and Usage Data. </em>
        Log and usage data is service-related, diagnostic, usage and performance information our servers automatically
        collect when you access or use our Website and which we record in log files. Depending on how you interact with
        us, this log data may include your IP address, device information, browser type and settings and information
        about your activity in the Website (such as the date/time stamps associated with your usage, pages and files
        viewed, searches and other actions you take such as which features you use), device event information (such as
        system activity, error reports (sometimes called 'crash dumps') and hardware settings).
      </li>
    </ul>
    <ul>
      <li>
        <em>Device Data. </em>
        We collect device data such as information about your computer, phone, tablet or other device you use to access
        the Website. Depending on the device used, this device data may include information such as your IP address (or
        proxy server), device and application identification numbers, location, browser type, hardware model Internet
        service provider and/or mobile carrier, operating system and system configuration information.
      </li>
    </ul>
    <h6>2. HOW DO WE USE YOUR INFORMATION?</h6>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>
        We process your information for purposes based on legitimate business interests, the fulfillment of our contract
        with you, compliance with our legal obligations, and/or your consent.
      </em>
    </p>
    <p>
      We use personal information collected via our Website for a variety of business purposes described below. We
      process your personal information for these purposes in reliance on our legitimate business interests, in order to
      enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We
      indicate the specific processing grounds we rely on next to each purpose listed below.
    </p>
    <p>We use the information we collect or receive:</p>
    <ul>
      <li>
        <strong>To facilitate account creation and logon process. </strong>
        If you choose to link your account with us to a third-party account (such as your Google or Facebook account),
        we use the information you allowed us to collect from those third parties to facilitate account creation and
        logon process for the performance of the contract.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To post testimonials. </strong>
        We post testimonials on our Website that may contain personal information. Prior to posting a testimonial, we
        will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete
        your testimonial, please contact us at info@thsint.se and be sure to include your name, testimonial location,
        and contact information.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Request feedback. </strong>
        We may use your information to request feedback and to contact you about your use of our Website.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To enable user-to-user communications. </strong>
        We may use your information in order to enable user-to-user communications with each user's consent.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To manage user accounts. </strong>
        We may use your information for the purposes of managing our account and keeping it in working order.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To send administrative information to you. </strong>
        We may use your personal information to send you product, service and new feature information and/or information
        about changes to our terms, conditions, and policies.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To protect our Services. </strong>
        We may use your information as part of our efforts to keep our Website safe and secure (for example, for fraud
        monitoring and prevention).
      </li>
    </ul>
    <ul>
      <li>
        <strong>
          To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory
          requirements or in connection with our contract.
        </strong>
      </li>
    </ul>
    <ul>
      <li>
        <strong>To respond to legal requests and prevent harm. </strong>
        If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to
        respond.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Fulfill and manage your orders. </strong>
        We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the
        Website.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Administer prize draws and competitions. </strong>
        We may use your information to administer prize draws and competitions when you elect to participate in our
        competitions.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To deliver and facilitate delivery of services to the user. </strong>
        We may use your information to provide you with the requested service.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To respond to user inquiries/offer support to users. </strong>
        We may use your information to respond to your inquiries and solve any potential issues you might have with the
        use of our Services.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To send you marketing and promotional communications. </strong>
        We and/or our third-party marketing partners may use the personal information you send to us for our marketing
        purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in
        obtaining information about us or our Website, subscribing to marketing or otherwise contacting us, we will
        collect personal information from you. You can opt-out of our marketing emails at any time (see the "WHAT ARE
        YOUR PRIVACY RIGHTS?" below).
      </li>
    </ul>
    <ul>
      <li>
        <strong>Deliver targeted advertising to you. </strong>
        We may use your information to develop and display personalized content and advertising (and work with third
        parties who do so) tailored to your interests and/or location and to measure its effectiveness.
      </li>
    </ul>
    <ul>
      <li>
        <strong>For other business purposes. </strong>
        We may use your information for other business purposes, such as data analysis, identifying usage trends,
        determining the effectiveness of our promotional campaigns and to evaluate and improve our Website, products,
        marketing and your experience. We may use and store this information in aggregated and anonymized form so that
        it is not associated with individual end users and does not include personal information. We will not use
        identifiable personal information without your consent.
      </li>
    </ul>
    <h6>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h6>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>
        We only share information with your consent, to comply with laws, to provide you with services, to protect your
        rights, or to fulfill business obligations.
      </em>
    </p>
    <p>We may process or share your data that we hold based on the following legal basis:</p>
    <ul>
      <li>
        <strong>Consent: </strong>
        We may process your data if you have given us specific consent to use your personal information for a specific
        purpose.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Legitimate Interests: </strong>
        We may process your data when it is reasonably necessary to achieve our legitimate business interests.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Performance of a Contract: </strong>
        Where we have entered into a contract with you, we may process your personal information to fulfill the terms of
        our contract.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Legal Obligations: </strong>
        We may disclose your information where we are legally required to do so in order to comply with applicable law,
        governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court
        order or a subpoena (including in response to public authorities to meet national security or law enforcement
        requirements).
      </li>
    </ul>
    <ul>
      <li>
        <strong>Vital Interests: </strong>
        We may disclose your information where we believe it is necessary to investigate, prevent, or take action
        regarding potential violations of our policies, suspected fraud, situations involving potential threats to the
        safety of any person and illegal activities, or as evidence in litigation in which we are involved.
      </li>
    </ul>
    <p>
      More specifically, we may need to process your data or share your personal information in the following
      situations:
    </p>
    <ul>
      <li>
        <strong>Business Transfers. </strong>
        We may share or transfer your information in connection with, or during negotiations of, any merger, sale of
        company assets, financing, or acquisition of all or a portion of our business to another company.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Vendors, Consultants and Other Third-Party Service Providers. </strong>
        We may share your data with third-party vendors, service providers, contractors or agents who perform services
        for us or on our behalf and require access to such information to do that work. Examples include: payment
        processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may
        allow selected third parties to use tracking technology on the Website, which will enable them to collect data
        on our behalf about how you interact with our Website over time. This information may be used to, among other
        things, analyze and track data, determine the popularity of certain content, pages or features, and better
        understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your
        information with third parties for their promotional purposes. We have contracts in place with our data
        processors, which are designed to help safeguard your personal information. This means that they cannot do
        anything with your personal information unless we have instructed them to do it. They will also not share your
        personal information with any organization apart from us. They also commit to protect the data they hold on our
        behalf and to retain it for the period we instruct.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Affiliates. </strong>
        We may share your information with our affiliates, in which case we will require those affiliates to honor this
        privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners or other
        companies that we control or that are under common control with us.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Business Partners. </strong>
        We may share your information with our business partners to offer you certain products, services or promotions.
      </li>
    </ul>
    <h6>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h6>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>We only share information with the following categories of third parties.</em>
    </p>
    <p>
      We only share and disclose your information with the following categories of third parties. If we have processed
      your data based on your consent and you wish to revoke your consent, please contact us using the contact details
      provided in the section below titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".
    </p>
    <ul>
      <li>Cloud Computing Services</li>
    </ul>
    <ul>
      <li>Communication &amp; Collaboration Tools</li>
    </ul>
    <ul>
      <li>Website Hosting Service Providers</li>
    </ul>
    <ul>
      <li>Data Analytics Services</li>
    </ul>
    <h6>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h6>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>We may use cookies and other tracking technologies to collect and store your information.</em>
    </p>
    <p>
      We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.
      Specific information about how we use such technologies and how you can refuse certain cookies is set out in our
      Cookie Notice.
    </p>
    <h6>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h6>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>We may transfer, store, and process your information in countries other than your own.</em>
    </p>
    <p>
      Our database servers are located in Western Europe, hosted by Microsoft Azure. If you are accessing our Website
      from outside Europe, please be aware that your information may be transferred to, stored, and processed by us in
      our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR
      INFORMATION BE SHARED WITH ANYONE?" above), in the United States, and other countries.
    </p>
    <p>
      If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not
      necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will
      however take all necessary measures to protect your personal information in accordance with this privacy notice
      and applicable law.
    </p>
    <p>European Commission's Standard Contractual Clauses:</p>
    <p>
      We have implemented measures to protect your personal information, including by using the European Commission's
      Standard Contractual Clauses for transfers of personal information between our group companies and between us and
      our third-party providers. These clauses require all recipients to protect all personal information that they
      process originating from the EEA or UK in accordance with European data protection laws and regulations. Our
      Standard Contractual Clauses can be provided upon request. We have implemented similar appropriate safeguards with
      our third-party service providers and partners and further details can be provided upon request.
    </p>
    <h6>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h6>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>
        We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless
        otherwise required by law.
      </em>
    </p>
    <p>
      We will only keep your personal information for as long as it is necessary for the purposes set out in this
      privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other
      legal requirements). No purpose in this notice will require us keeping your personal information for longer than
      the period of time in which users have an account with us.
    </p>
    <p>
      When we have no ongoing legitimate business need to process your personal information, we will either delete or
      anonymize such information, or, if this is not possible (for example, because your personal information has been
      stored in backup archives), then we will securely store your personal information and isolate it from any further
      processing until deletion is possible.
    </p>
    <h6>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h6>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>
        We aim to protect your personal information through a system of organizational and technical security measures.
      </em>
    </p>
    <p>
      We have implemented appropriate technical and organizational security measures designed to protect the security of
      any personal information we process. However, despite our safeguards and efforts to secure your information, no
      electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure,
      so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be
      able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will
      do our best to protect your personal information, transmission of personal information to and from our Website is
      at your own risk. You should only access the Website within a secure environment.
    </p>
    <h6>9. DO WE COLLECT INFORMATION FROM MINORS?</h6>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>We do not knowingly collect data from or market to children under 18 years of age.</em>
    </p>
    <p>
      We do not knowingly solicit data from or market to children under 18 years of age. By using the Website, you
      represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
      minor dependent&rsquo;s use of the Website. If we learn that personal information from users less than 18 years of
      age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data
      from our records. If you become aware of any data we may have collected from children under age 18, please contact
      us at info@thsint.se.
    </p>
    <h6>10. WHAT ARE YOUR PRIVACY RIGHTS?</h6>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>
        In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow
        you greater access to and control over your personal information. You may review, change, or terminate your
        account at any time.
      </em>
    </p>
    <p>
      In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may
      include the right (i) to request access and obtain a copy of your personal information, (ii) to request
      rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable,
      to data portability. In certain circumstances, you may also have the right to object to the processing of your
      personal information. To make such a request, please use the contact details provided below. We will consider and
      act upon any request in accordance with applicable data protection laws.
    </p>
    <p>
      If we are relying on your consent to process your personal information, you have the right to withdraw your
      consent at any time. Please note however that this will not affect the lawfulness of the processing before its
      withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful
      processing grounds other than consent.
    </p>
    <p>
      If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you
      also have the right to complain to your local data protection supervisory authority. You can find their contact
      details here:{' '}
      <a href='http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
      </a>
      .
    </p>
    <p>
      If you are a resident in Switzerland, the contact details for the data protection authorities are available here:{' '}
      <a href='https://www.edoeb.admin.ch/edoeb/en/home.html'>https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
    </p>
    <p>If you have questions or comments about your privacy rights, you may email us at info@thsint.se.</p>
    <p>
      <strong>Account Information</strong>
    </p>
    <p>
      If you would at any time like to review or change the information in your account or terminate your account, you
      can:
    </p>
    <ul>
      <li>Log in to your account settings and update your user account.</li>
    </ul>
    <ul>
      <li>Contact us using the contact information provided.</li>
    </ul>
    <p>
      Upon your request to terminate your account, we will deactivate or delete your account and information from our
      active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems,
      assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
    </p>
    <p>
      <strong>Cookies and similar technologies: </strong>
      Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser
      to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect
      certain features or services of our Website. To opt-out of interest-based advertising by advertisers on our
      Website visit <a href='http://www.aboutads.info/choices/'>http://www.aboutads.info/choices/</a>.
    </p>
    <p>
      <strong>Opting out of email marketing: </strong>
      You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails
      that we send or by contacting us using the details provided below. You will then be removed from the marketing
      email list &mdash; however, we may still communicate with you, for example to send you service-related emails that
      are necessary for the administration and use of your account, to respond to service requests, or for other
      non-marketing purposes. To otherwise opt-out, you may:
    </p>
    <ul>
      <li>Access your account settings and update your preferences.</li>
    </ul>
    <h6>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h6>
    <p>
      Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature
      or setting you can activate to signal your privacy preference not to have data about your online browsing
      activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing
      DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism
      that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted
      that we must follow in the future, we will inform you about that practice in a revised version of this privacy
      notice.
    </p>
    <h6>12. DO WE MAKE UPDATES TO THIS NOTICE?</h6>
    <p>
      <strong>
        <em>In Short: </em>
      </strong>
      <em>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em>
    </p>
    <p>
      We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised"
      date and the updated version will be effective as soon as it is accessible. If we make material changes to this
      privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending
      you a notification. We encourage you to review this privacy notice frequently to be informed of how we are
      protecting your information.
    </p>
    <h6>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h6>
    <p>If you have questions or comments about this notice, you may email us at info@thsint.se or by post to:</p>
    <p>THS International</p>
    <p>Drottning Kristinas V&auml;g 15</p>
    <p>Stockholm, Sweden 10044</p>
    <p>Sweden</p>
    <h6>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h6>
    <p>
      Based on the applicable laws of your country, you may have the right to request access to the personal information
      we collect from you, change that information, or delete it in some circumstances. To request to review, update, or
      delete your personal information, please visit: info@thsint.se.
    </p>
  </>
);

export default Privacy;
