import React from 'react';
import { Typography } from '@material-ui/core';
import CheckComponent from '/components/forms-v2/Check.component';

const status = [
  {label: 'Attended', value: 'attended'},
  {label: 'Not attended', value: 'notAttended'},
]

const Attendance = ({control}) => {
  return(
    <>
      {status.map((stat, index) => (
        <CheckComponent
          key={index}
          name={stat.value}
          control={control}
          label={
            <Typography variant='body2'>{stat.label.charAt(0).toUpperCase() + stat.label.slice(1)}</Typography>
          }
          noMargin
        />
      ))}
    </>
  );
}

export default Attendance;