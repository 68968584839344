import React, { useState, useEffect } from 'react';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  root: {
    position: 'fixed !important',
    bottom: '32px',
    right: '128px',
    zIndex: '100',
    [t.breakpoints.down('sm')]: {
      right: '32px',
      color: 'white',
      backgroundColor: `${t.palette.secondary.main} !important`,
    },
  },
  hidden: {
    transition: 'height 0.5s ease !important',
    opacity: 0,
    overflow: 'hidden',
  },
  show: {
    transition: 'height 1s ease !important',
    opacity: 1,
  },
}));

const FabComponent = () => {
  const classes = useStyles();
  const [showUpButton, setShowUpButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    window.scrollY > window.screen.availHeight ? setShowUpButton(true) : setShowUpButton(false);
  };

  return (
    <Fab
      className={showUpButton ? `${classes.root} ${classes.show}` : `${classes.root} ${classes.hidden}`}
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <ArrowUpwardIcon />
    </Fab>
  );
};

export default FabComponent;
