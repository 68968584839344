import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_OK,
  LOGIN_REDIRECT,
  LOGOUT,
  SENDING_FORGOT,
  FORGOT_OK,
  FORGOT_FAIL,
  SENDING_RESET,
  RESET_OK,
  RESET_FAIL,
} from '/redux/actions/AuthActions.jsx';

const createState = {
  studyInfo: {
    programmes: [],
    types: [],
  },
  created: null,
  isLoading: true,
  error: null,
};

let token = sessionStorage.getItem('token');
const initialState = token ? { loggedIn: false, token: token, error: null, landingPage: '' } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        loggedIn: false,
      };
    case 'LOGIN_OK':
      return {
        loggedIn: true,
        token: action.token,
      };
    case 'LOGIN_REDIRECT':
      return {
        ...state,
        landingPage: action.data,
      };
    case 'LOGIN_FAIL':
      return {
        loggedIn: false,
        error: action.error,
      };
    case 'LOGOUT':
      return {
        loggedIn: false,
        error: null,
      };
    default:
      return state;
  }
}

export function amnesia(state = { sending: false, sent: false, error: null, forgottenEmail: null }, action) {
  switch (action.type) {
    case 'SENDING_FORGOT':
      return {
        sending: true,
        sent: false,
      };
    case 'FORGOT_OK':
      return {
        sending: false,
        sent: true,
        forgottenEmail: action.email,
      };
    case 'FORGOT_FAIL':
      return {
        sending: true,
        sent: false,
        error: action.error,
      };
    case 'SENDING_RESET':
      return {
        sending: true,
        sent: false,
      };
    case 'RESET_OK':
      return {
        sending: true,
        sent: true,
      };
    case 'RESET_FAIL':
      return {
        sending: true,
        sent: false,
        error: action.error,
      };
    default:
      return state;
  }
}
