import { configureStore } from '@reduxjs/toolkit'
import { authentication, amnesia } from './reducers/AuthReducers';
import { eventRetrival, eventTicketTransfer } from './reducers/EventReducers';
import { feedback } from './reducers/FeedbackReducers';
import { projectInfo } from './reducers/ProjectReducers';
import emailRetrival from './reducers/EmailReducer';
import { roles } from './reducers/AccessReducers';
import { adminAccess, adminUsers } from './reducers/AdminReducers';
import options from './reducers/OptionsReducers';

export const store = configureStore({
  reducer: {
    authentication,
    emailRetrival,
    amnesia,
    eventRetrival,
    feedback,
    projectInfo,
    roles,
    adminAccess,
    adminUsers,
    options,
    eventTicketTransfer,
  },
});