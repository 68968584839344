import React from 'react';
import TextComponent from '/components/forms-v2/Text.component';

const FirstNameInput = ({ control }) => {
  return (
    <TextComponent
      name='firstName'
      control={control}
      label='First Name'
      rules={{
        required: { value: true, message: 'Enter first name' },
        maxLength: { value: 128, message: 'Too long input' },
      }}
    />
  );
};

export default FirstNameInput;
