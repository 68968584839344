import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Content from '/components/content/Content';
import Access from './access/Access';
import CreateEvent from './createEvent/CreateEvent';
import EventFilter from './eventFilter/EventFilter';
import EventGroup from './eventGroup/EventGroup';
import EventOverview from './eventOverview/EventOverview';
import EventManagement from './eventManagement/EventManagement';
import Project from './project/Project';
import Users from './users/Users';
import * as Backend from '/data/api/BackendRequest.jsx';
import Footer from '/components/footer/Footer';
import { useLocation } from 'react-router-dom';
import TabBar from '/components/tabs/Tabs';

const Admin = (props) => {
  const location = new useLocation();
  const [category, setCategory] = useState('#users');

  const [bookingStats, setBookingStats] = useState({});
  const [manageData, setManageData] = useState({});
  const [createData, setCreateData] = useState({});
  const [createSettings, setCreateSettings] = useState({
    attendanceLimit: false,
    basePriceSek: false,
    editing: false,
    eventGroup: false,
  });

  useEffect(() => {
    getEvents();
    getBookingStats();
  }, []);

  const getEvents = () => {
    Backend.request('get', `admin/${props.projectId}/events`, {}).then((response) => setEventsData(response.data.data));
  };

  const getBookingStats = () => {
    Backend.request('get', `admin/${props.projectId}/event/bookingstats`).then((response) => {
      if (response.data.data != null) {
        setBookingStats(
          response.data.data.reduce((acc, cur) => {
            const { eventId, stats } = cur;
            acc[eventId] = stats;
            return acc;
          }, {})
        );
      }
    });
  };

  const [eventsData, setEventsData] = useState([]); //All loaded events

  useEffect(() => {
    setCategory(location.hash);
  }, [location.hash]);

  function categorySwitch(key) {
    switch (key) {
      case '#overviewEvent':
        return (
          <EventOverview
            settings={createSettings}
            events={eventsData}
            setEvents={setEventsData}
            setCreateData={setCreateData}
            setCreateSettings={setCreateSettings}
            setManageData={setManageData}
            bookingStats={bookingStats}
          />
        );
      case '#createEvent':
        return (
          <CreateEvent
            data={createData}
            settings={createSettings}
            setData={setCreateData}
            setSettings={setCreateSettings}
            getEvents={getEvents}
          />
        );
      case '#manage':
        return (
          <EventManagement
            event={manageData}
            setCreateData={setCreateData}
            setCreateSettings={setCreateSettings}
            bookingStats={bookingStats}
            getBookingStats={getBookingStats}
          />
        );
      case '#users':
        return <Users />;
      case '#project':
        return <Project />;
      case '#accessManagement':
        return <Access />;
      case '#eventFilters':
        return <EventFilter events={eventsData} />;
      case '#eventGroups':
        return <EventGroup events={eventsData} />;
      default:
        return <Users />;
    }
  }

  /*
    using media query here to hide the sidebar whenever
    breakpoint is lower or equal than md.

    TODO:
     - refactor the code so there is no repetition.
  */
  return (
    <>
      <TabBar
        tablist={[
          {
            label: 'Users Overview',
            link: `/${props.projectId}/admin#users`,
          },
          {
            label: 'Events Overview',
            link: `/${props.projectId}/admin#overviewEvent`,
          },
          {
            label: 'Event Filters',
            link: `/${props.projectId}/admin#eventFilters`,
          },
          {
            label: 'Event Groups',
            link: `/${props.projectId}/admin#eventGroups`,
          },
          {
            label: 'Project Settings',
            link: `/${props.projectId}/admin#project`,
          },
          {
            label: 'Access Management',
            link: `/${props.projectId}/admin#accessManagement`,
          },
        ]}
      />
      <Content
        sidebar={{
          'Users Overview': `/${props.projectId}/admin#users`,
          'Events Overview': `/${props.projectId}/admin#overviewEvent`,
          'Event Filters': `/${props.projectId}/admin#eventFilters`,
          'Event Groups': `/${props.projectId}/admin#eventGroups`,
          'Project Settings': `/${props.projectId}/admin#project`,
          'Access Management': `/${props.projectId}/admin#accessManagement`,
        }}
      >
        {categorySwitch(category)}
      </Content>
      <Footer name='IR' />
    </>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(Admin);
