import React from 'react';
import { Typography } from '@material-ui/core';

/**
 * Displays the general settings in the users overview page
 * 
 * @param {array<objects>} entries entries from props
 * object format:
 * {
 *  label:string
 *  size:number
 * }
 * @param {number} rowSize how many columns before new row
 * @param {boolean} isMobile if the display should be mobile version or desktop version
 */
const InfoGeneral = ({entries, rowSize, isMobile}) => {

  /**
   * Formats the data that is put in and returns an array of JSX elements to be displayed
   * @param {*} entries entries from props
   * @param {*} rowSize how many columns before new row
   */
  const renderRows = (entries, rowSize) => {
    let returnArray = [];
    let row = [];
    let currentIndex = 0;

    entries.forEach((entry, i) => {

      row.push(
        <td key={i} colSpan={entry.size}>
          <Typography variant='body2' color='textSecondary' component='span' style={{marginBottom: '9px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
            {entry.label}
          </Typography>
        </td>
      );

      currentIndex += entry.size;
      if(currentIndex >= rowSize) {
        returnArray.push(
          <tr key={`row-${returnArray.length}`}>
            {row}
          </tr>
        );

        row = [];
        currentIndex = 0;
      }
    });

    return returnArray;
  }

  return(
    <>
      <table style={{width: '100%', tableLayout: 'fixed'}}>
        <tbody>
          {renderRows(entries, rowSize)}
        </tbody>
      </table>
    </>
  );
};

export default InfoGeneral;