import React from 'react';
import IconText from '/components/iconText/IconText.component';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import PaymentIcon from '@material-ui/icons/Payment';
import Theme from '/MuiTheme';

/**
 * Content part for Events Overview
 * data : array<object> index: [booked events, events attended, payment left to pay]
 *  {
 *    label: any
 *    state: string:<default, warning, error, success>
 *  }
 * isMobile : boolean
 */
const EventsContent = ({data, isMobile}) => (
  <table style={{tableLayout: 'fixed', width: '100%'}}>
    <tbody>
      <tr>
        <td>
          <IconText
            icon={<ConfirmationNumberIcon/>}
            label={isMobile ? data[0].label : `${data[0].label} Events booked`}
            spacing={8}
            style={{color: stringToColor(data[0].state)}}
            isMobile={isMobile}
          />
        </td>
        <td>
          <IconText
            icon={<EmojiPeopleIcon/>}
            label={isMobile ? data[1].label : `${data[1].label} Events attended`}
            spacing={8}
            style={{color: stringToColor(data[1].state)}}
            isMobile={isMobile}
          />
        </td>
        <td>
          <IconText
            icon={<PaymentIcon/>}
            label={isMobile ? `${data[2].label} SEK` : `${data[2].label} SEK left to pay`}
            spacing={8}
            style={{color: stringToColor(data[2].state)}}
            isMobile={isMobile}
          />
        </td>
      </tr>
    </tbody>
  </table>
);

/**
 * transforms string state to a color hex
 * @param string <default, warning, error, success>
 * @returns color in hex or default colors
 */
const stringToColor = (string) => {
  switch(string) {
    case 'warning':
      return Theme.palette.warning.main;
    case 'error':
      return Theme.palette.error.main;
    case 'success':
      return Theme.palette.success.main;
    default:
      return `${Theme.palette.surface.text}${Theme.palette.other.opacityMedium}`; 
  }
}

export default EventsContent;