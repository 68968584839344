import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Theme from '/MuiTheme';

const useStyles = makeStyles((t) => ({
  root: {
    margin: '0 auto',
    maxWidth: '500px',
    padding: '16px 30px',
    [t.breakpoints.up('md')]: {
      border: '1px solid #104E75',
      borderRadius: '5px',
    },
    [t.breakpoints.down('sm')]: {
      padding: '16px 0px',
    },
  },
}));

const FormContainer = (props) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.root}
      elevation={props.elevation && useMediaQuery(Theme.breakpoints.up('md')) ? props.elevation : 0}
    >
      {props.children}
    </Paper>
  );
};

export default FormContainer;
