/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import AdminEventCard from '/components/adminEventCard/AdminEventCard';
import FilterComponent from '/components/forms/FilterComponent';

import './eventOverview.scss';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const attr = ['displayTitle'];
const EventOverview = ({ events, setCreateData, setCreateSettings, setManageData, bookingStats, settings }) => {
  const [displayEvents, setDisplayEvents] = useState([]);
  const history = useHistory();

  return (
    <div>
      <h3>Overview of events</h3>
      <div className='eventToolbar'>
        <div className='searchField'>
          <FilterComponent setList={setDisplayEvents} list={events} attributes={attr}>
            Search event
          </FilterComponent>
        </div>
        <div className='createEvent'>
          <Button onClick={() => history.push('#createEvent')} variant='contained' color='primary'>
            {settings.editing ? 'Keep edit event' : 'Create event'}
          </Button>
        </div>
      </div>
      <DisplayEvents
        bookingStats={bookingStats}
        events={displayEvents}
        setCreateData={setCreateData}
        setCreateSettings={setCreateSettings}
        setManageData={setManageData}
      />
    </div>
  );
};

const DisplayEvents = ({ events, setCreateData, setCreateSettings, setManageData, bookingStats }) => {
  return events.map((event, index) => {
    return (
      <AdminEventCard
        bookingStats={bookingStats[event.eventId]}
        key={index}
        event={event}
        events={events}
        index={index}
        setCreateData={setCreateData}
        setCreateSettings={setCreateSettings}
        setManageData={setManageData}
      />
    );
  });
};

export default EventOverview;
